
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,  Link, useNavigate,useLocation, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import ProductDetails from './Pages/ProductDetails';
import Header from './MainComponents/Header';
import FooterJs from './MainComponents/FooterJs';
import Accounts from './MainComponents/Accounts';
import WriteReviewpopup from './MainComponents/WriteReviewPopup';
import Login from './MainComponents/Login';
import ProductsList from './Pages/ProductsList';
import GuestLogin, { HomePage } from './Service/ApiCalling';
import RegisterModal from './MainComponents/RegisterModal';
import AddAddressModal from './MainComponents/SubComponents/AddAddressModal';
import OrderDetails from './MainComponents/SubComponents/OrderDetails';
import SearchScreen from './MainComponents/SubComponents/SearchScreen';
import GoalsHome from './Pages/GoalsHome';
import EliteMembership from './Pages/EliteMembership';
import UniversalProductslist from './Pages/UniversalProductslist';
import AllReviews from './MainComponents/AllReviews';
import ReviewsList from './MainComponents/SubComponents/ReviewsList';
import Loginpage from './Pages/loginpage';
import Subcategorypage from './Pages/Subcategorypage';
import NutriEatsPage from './Pages/Nutrieats';
import HealthySnacks from './Pages/Healthysnacks';
import Agepage from './Pages/Agepage';
import LogoutModal from './MainComponents/SubComponents/LogoutModal';
import PeanutButterPage from './Pages/Peanutbutter';
import Productcollectionitem from './Pages/Product_collection_item';
import AboutJS from './MainComponents/SubComponents/Aboutus';
import ShippingPolicyJS from './MainComponents/SubComponents/ShippingPolicy';
import PrivacyJS from './MainComponents/SubComponents/Privacy';
import ReturnandRefundJS from './MainComponents/SubComponents/ReturnandRefund';
import TermsandConditionsJS from './MainComponents/SubComponents/TermsandConditions';
import ContactJS from './MainComponents/SubComponents/Contact';
import Afiliatepage from './MainComponents/SubComponents/Afiliatepage';
import Help from './MainComponents/SubComponents/Help';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import BottomBar from './MainComponents/SubComponents/Bottom';
import ReactGA from 'react-ga4';
import Push from 'push.js';
import { Axios } from 'axios';
import NotFound from './Pages/404page';
import Storeloactor from './MainComponents/SubComponents/Storelocater';



function App() {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const navigate = useNavigate(); 
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const RetrieveWebNotification = async () => {
    try {
      const config = {
        method: 'post',
        url: process.env.NODE_ENV === 'production'
          ? 'https://app.nutsby.com/send_web_notifications'
          : '/send_web_notifications',
        headers: {
          'Client-Id': '7559cfa692227a068928964a23f54b5e70ab503',
        },
        data: {},
      };

      console.log('Sending request to:', config.url);
      const res = await Axios(config);
      console.log('Response received:', res.data);

      // Check for valid notification response
      if (res.data && res.data.title && res.data.body) {
        return res.data;
      } else {
        console.error('Invalid notification response:', res.data);
      }
    } catch (error) {
      if (error.response) {
        console.error('Server responded with an error:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
    }

    // Return mock response for testing
    return {
      title: 'Mock Notification',
      body: 'This is a mock notification for testing.',
    };
  };
  const showNotification = (title, body) => {
    console.log('Attempting to show notification:', title, body);
    if (title && body) {
      Push.create(title, {
        body: body,
        timeout: 4000,
        onClick: function () {
          window.focus();
          this.close();
        },
      });
    } else {
      console.error('Invalid notification parameters:', title, body);
    }
  };

  const fetchNotification = async () => {
    setLoading(true);
    const response = await RetrieveWebNotification();
    setLoading(false);

    if (response && response.title && response.body) {
      showNotification(response.title, response.body);
    } else {
      const staticNotification = {
        title: 'Scheduled Notification',
        body: 'This notification is sent by a scheduled cron job.',
      };
      showNotification(staticNotification.title, staticNotification.body);
    }
  };

  useEffect(() => {
    const requestPermission = async () => {
      const permission = await Push.Permission.request();
      if (permission === 'granted') {
        console.log('Notification permission granted');
        fetchNotification(); // Fetch notifications when permission is granted
      } else {
        console.error('Notification permission denied');
      }
    };

    requestPermission(); // Request notification permission
  
    // const intervalId = setInterval(fetchNotification, 60000); // Fetch every minute
    // return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);

  useEffect(() => {
   
    ReactGA.initialize('G-422066774');

    
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]); 


  useEffect(() => {
    const expiryTimestamp = localStorage.getItem('access_expiry_timestamp');
console.log("Stored access_expiry_timestamp:", expiryTimestamp);

    if(localStorage.getItem("access_expiry_timestamp")===null){
      GuestLogin();
      
    }
    HomePage();
    RetrieveWebNotification();
    const handleResize = () => {
    const isDesktopNow = window.innerWidth > 768;
    if (isDesktopNow !== isDesktop) {
    setIsDesktop(isDesktopNow);
    window.location.reload();
    }
    };
    
    window.addEventListener('resize', handleResize);
    
    
    return () => window.removeEventListener('resize', handleResize);
    }, [isDesktop]);

   
  
 
  return (
    
    <>        
      {location.pathname !== "/mobile-login" && <Header />}
      
     

   
      <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/profile" element={<Accounts />} />
  <Route path="/orders" element={<Accounts />} />
  <Route path="/order-details/:id" element={<Accounts />} />
  <Route path="/:name/:type/:id" element={<ProductsList />} />
  <Route path="/search-result/:searchstring" element={<UniversalProductslist />} />
  <Route path="/wishlist" element={<Accounts />} />
  <Route path="/search-result-mobile" element={<SearchScreen />} />
  <Route path="/fit-Cash" element={<Accounts />} />
  <Route path="/maingoal/:name" element={<GoalsHome />} />
  {/* <Route path="/hair-care-supplements" element={<Subcategorypage />} /> */}
  {/* <Route path="/hair-care-supplements" element={<Subcategorypage />} /> */}
  {/* <Route path="/hair-care-supplements" element={<Subcategorypage />} /> */}
  {/* <Route path="/hair-care-supplements" element={<Subcategorypage />} /> */}
  {/* <Route path="/hair-care-supplements" element={<Subcategorypage />} /> */}
  {/* <Route path="/hair-care-supplements" element={<Subcategorypage />} /> */}
  {/* <Route path="/hair-care-supplements" element={<Subcategorypage />} /> */}
  {/* <Route path="/hair-care-supplements" element={<Subcategorypage />} /> */}
  <Route path="/:name" element={<Subcategorypage />} />
  <Route path="/rate-order/:id" element={<Accounts />} />
  <Route path="/address-book" element={<Accounts />} />
  <Route path="/:name/p/:id" element={<ProductDetails />} />
  <Route path="/mobile-login" element={<Loginpage />} />
  <Route path="/nutrieats" element={<NutriEatsPage />} />
  <Route path="/peanut-butter" element={<PeanutButterPage />} />
  <Route path="/about-us" element={<AboutJS />} />
  <Route path="/shipping-policy" element={<ShippingPolicyJS />} />
  <Route path="/privacy-policy" element={<PrivacyJS />} />
  <Route path="/return-and-refund-policy" element={<ReturnandRefundJS />} />
  <Route path="/terms-and-conditions" element={<TermsandConditionsJS />} />
  <Route path="/contact-us" element={<ContactJS />} />
  <Route path="/afiliate" element={<Afiliatepage />} />
  <Route path="/Help/:id" element={<Help />} />
  <Route path="/store-locator" element={<Storeloactor/>} />
  
  <Route path="*" element={<NotFound />} />
</Routes>

   
      {/* <FooterJs /> */}
      {location.pathname !== "/mobile-login" && <FooterJs />}
    </>
  );
}

export default App;
