import React, { useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../SubCss/Affiliatepage.css"; 
import { Container, Row, Col, Button, Breadcrumb,Form,Carousel } from 'react-bootstrap';
import { register_affiliate } from '../../Service/ApiCalling';
import { useLocation} from 'react-router-dom'; 
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import Lottie from "lottie-react";
import certified from "../../assets/lottie/genuine-trusted.json"
// import certified from "../assets/lottie/genuine-trusted.json";


function StoreloactorJS() {
      const [otherCategory, setOtherCategory] = useState(false);
      const [upiSelected, setUpiSelected] = useState(false);
      const [bankDetailsSelected, setBankDetailsSelected] = useState(false);
      const location = useLocation();
      const { pathname } = location;
      const [payee_name, setName] = useState('');
      const [instagram_link, setinstagram_link] = useState('');
      const [website_link, setwebsite_link] = useState('');
      const [category, setcategory] = useState('');
      const [description, setdescription] = useState('');
      const [bank_name, setbank_name] = useState('');
      const [bank_branch_name, setbank_branch_name] = useState('');
      const [bank_ifsc_code, setbank_ifsc_code] = useState('');
       const [bank_account_number, setbank_account_number] = useState('');
       const [bank_upi_id, setbank_upi_id] = useState('');
       const sliderSettings = {
        // dots: true,          
        infinite: true,     
        speed: 500,         
        slidesToShow: 4,  
        slidesToScroll: 1,  
        arrows: false, // Disable the arrows 
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4, 
              slidesToScroll: 1,
              infinite: true,
            //   dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2, // Adjust for smaller screens
              slidesToScroll: 1,
              infinite: true,
            //   dots: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      const fetchInfo=async ()=> {  
        // if(params.id==="1"){
            let register_affiliateData = await register_affiliate(payee_name,instagram_link,website_link,category,description,bank_name,bank_branch_name,bank_ifsc_code, bank_account_number, bank_upi_id);
            if (register_affiliateData.error==="0") {  
                
               
    }}
      useEffect(() => {
        fetchInfo();
        if (pathname === "/store-locator") {
          document.body.style.backgroundColor = "#F4F5FA";
         
        } else {
          document.body.style.backgroundColor = "white";
        }
      }, [pathname]);
      return (
       <>
        <div className="container" style={{paddingBottom: "0px",paddingTop: "10rem",marginBottom:"5px",fontWeight:"600"}}>Store Locator</div>
        
 <div className="mainCarousel container" style={{ paddingBottom: "0px", overflow: 'hidden' }}>
    <div style={{ width: '100%', position: 'relative', zIndex: 10 }}>
      <Col lg={12} md={12} sm={12} xs={12} style={{ }}>
        <Slider {...sliderSettings}>
          <div style={{ padding: '5px' }}>
            <img
              src={require("../../assets/images/PNG/store1.png")}
              alt="Image 1"
              style={{
                height: '100%',
                width: '100%',
                padding:"2px",
                webkitUserDrag: 'none',
              }}
            />
          </div>
          <div style={{ padding: '5px' }}>
            <img
              src={require("../../assets/images/PNG/store2.png")}
              alt="Image 2"
              style={{
                height: '100%',
                width: '100%',
                padding:"2px",
                webkitUserDrag: 'none',
              }}
            />
          </div>
          <div style={{ padding: '5px' }}>
            <img
              src={require("../../assets/images/PNG/store3.png")}
              alt="Image 3"
              style={{
                height: '100%',
                width: '100%',
                padding:"2px",
                webkitUserDrag: 'none',
              }}
            />
          </div>
          <div style={{ padding: '5px' }}>
            <img
              src={require("../../assets/images/PNG/store3.png")} // Ensure this is the correct image
              alt="Image 4"
              style={{
                height: '100%',
                width: '100%',
                padding:"2px",
                webkitUserDrag: 'none',
              }}
            />
          </div>
        </Slider>
      </Col>
    </div>
  </div>
  
  <Row className='d-block d-md-none d-lg-none d-sm-none'>
              <Col xs={12} sm={12} md={8} lg={8} className='breadcrumb' style={{marginBottom:"0px"}}>
              <Breadcrumb>
                  <Breadcrumb.Item
                    role='button'
                    style={{ fontWeight:"400",
                    fontSize:'14px'
                    }}
                    
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}
                    > Home </Breadcrumb.Item>
                    <Breadcrumb.Item
                  style={{ fontWeight:"400",
                    fontSize:'14px'
                    }}
                
                    > Store Locator </Breadcrumb.Item>
                     
                  </Breadcrumb>
                </Col>
                </Row> 

                <div className='d-none d-md-block d-lg-block d-sm-block container'>
              <Col  className='breadcrumb' style={{marginBottom:"0px"}}>
                  <Breadcrumb>
                  <Breadcrumb.Item
                    role='button'
                    style={{ fontWeight:"400",
                        fontSize:'14px'
                        }}
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}
                    > Home </Breadcrumb.Item>
                    <Breadcrumb.Item
                    style={{ fontWeight:"400",
                        fontSize:'14px'
                        }}
                
                    > Store Locator </Breadcrumb.Item>
                   
                  </Breadcrumb>
                </Col>
                </div> 

    
        <div className="container" style={{padding:"20px"}}>
            
          <div className="row">
          <div className="col-md-6" style={{paddingLeft:"1px"}}>
            
          <div className="card mb-2" style={{ borderRadius: "10px", display: "flex", alignItems: "center", flexDirection: "row" }}>
  <div style={{ flex: 1 }}>
   
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#EBFBFF", padding: "0px 10px", borderTopLeftRadius: "10px",borderTopRightRadius:"10px" }}>
      <p style={{ color: "#000000", margin: 0,fontSize:'small',padding:"8px 0px" }}><span style={{fontWeight:"600"}}>Nutsby</span><span style={{fontWeight:"400"}}>-Jubilee Hills</span></p>
      <div>
        <img 
          src={require("../../assets/images/PNG/direction.png")} 
          alt="Direction" 
          style={{ width: "24px", height: "24px", marginRight: "10px", cursor: "pointer" }} 
        />
        <img 
          src={require("../../assets/images/PNG/storecall.png")} 
          alt="Phone" 
          style={{ width: "24px", height: "24px", cursor: "pointer" }} 
        />
      </div>
    </div>
    
    {/* Address Section Below the Header */}
    <div style={{padding:"10px 0"}}>
    <p style={{color:'black',padding:"5px 10px",marginBottom:"0px",fontSize:"15px"}}>120 Reviews</p>
    <p style={{  color: "#808080", fontWeight: "300",fontSize:"14px",padding:"0 10px",marginBottom:"0px" }}>
    15,In front of Honda Showroom,Madhapur, <br />
    Hyderabad, Telangana - 50000
    </p>
    </div>
  </div>
</div>

<div className="card mb-2" style={{ borderRadius: "10px", display: "flex", flexDirection: "column" }}>

  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
    <h5 style={{ color: "#000000", margin: "10px 10px 0px 10px",fontSize:"15px",fontWeight:"600" }}>Store Timings</h5>
    <button style={{ backgroundColor: "#EBFBFF", color: "black",fontSize:"small", borderRadius: "20px", border: "none", padding: "5px 10px" ,margin: "10px 10px 0px 10px" }}>
      Closed Now
    </button>
  </div>

  <ul style={{ padding: 0, margin: 0, listStyleType: "disc" }}>
  <li style={{ margin: "6px 10px 0px 10px", color: "#808080", fontWeight: "300", fontSize: "14px" }}>
    Everyday 10 A.M. to 9 P.M
  </li>
</ul>



  <hr style={{ width: "", margin: "8px 15px" }} />

 <div style={{color:"black",fontSize:"15px",fontWeight:'600',margin: "9px 10px 5px 10px"}}>Have you been here?</div>
  <div style={{ display: "flex", alignItems: "center",margin: "2px 10px 5px 10px",fontSize:"14px",color: "#808080" }}>
  <div className="form-check" style={{ paddingLeft: "0px", paddingBottom: "5px", marginRight: "20px" }}>
    <input
      type="radio"
      className="radio"
      name="category"
      value="yes"
    />
    <label style={{ marginLeft: "8px" }} htmlFor="" className="form-check-label">Yes, I visited this store</label>
  </div>
  
  <div className="form-check" style={{ paddingLeft: "0px", paddingBottom: "5px" }}>
    <input
      type="radio"
      className="radio"
      name="category"
      value="no"
    />
    <label style={{ marginLeft: "8px" }} htmlFor="" className="form-check-label">No, I am willing to visit</label>
  </div>
</div>

 
<div
  style={{
    backgroundColor: "#E1FFEF",
    color: "#006830",
    padding: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    display: "flex",
    justifyContent: "space-between",  // Aligns items to the sides
    alignItems: "center",   
    marginTop:"40px"          // Vertically aligns items
  }}
>
  <Lottie className="cetificate" animationData={certified} style={{ width: "20%",left:"0px",top:"0px" }} />  
  <div style={{ flexGrow: 1 }}></div>
  
  <span>100% Authentic Product</span>  {/* Text on the far right */}
</div>


</div>


            </div>
            <div className="col-md-6" style={{paddingLeft:"0px"}}>
            <div className="card mb-2" style={{ borderRadius: "10px", display: "flex", flexDirection: "column" }}>

<div style={{ display: "flex", alignItems: "center"}}>
  <h5 style={{ color: "#000000", margin: "10px 10px 0px 10px",fontSize:"15px",fontWeight:"600" }}>What you’ll find in Nutsby store</h5>
  
</div>

<div
  style={{
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",  
    gap: "2px",                            
    fontSize: "14px",
    fontWeight:"300",                       
    color: "rgb(128, 128, 128)", 
    margin: "10px 10px 0px 10px"  ,
                 
  }}
>
  <div>• Nuts & Dried Fruits</div>
  <div>• Sports Nutrition</div>
  <div>• Vitamins & Minerals</div>
  <div>• Healthy Foods</div>
  <div>• Organic Foods</div>
  <div>• Ayurveda</div>
</div>




<hr style={{ width: "", margin: "8px 15px" }} />

<div style={{color:"black",fontSize:"15px",fontWeight:'600',margin: "2px 10px 5px 10px"}}>Search for product availability in store</div>
<Form className="" role="search">
  <div className="input-group" style={{ position: 'relative', width: '100%' }}>
    <input
      className="form-control me-2"
      type="search"
      placeholder='Search Product Here'
      
       
    
      aria-label="Search"
      style={{
        borderRadius: '38px',
        backgroundColor: '#F5F5F5',
        margin: "7px",
        padding:'6px',
        border: '0',
        fontFamily: 'Inter',
        paddingLeft:'10px'
      }}
    />
    <style jsx="true">
      {`
        input::placeholder {
          color: #888888; 
          font-size: 14px; 
          margin-left:10px;
        }
      `}
    </style>
    <div
      style={{
        position: 'absolute',
        right: '15px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        color: '#888',
      }}
   
    >
      <svg width="25" height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{paddingRight:'7px', marginRight: '7px'}}>
        <path d="M17.541 16.6501L13.742 12.7289C14.7665 11.442 15.3785 9.79339 15.3785 7.99945C15.3785 3.87622 12.1364 0.532959 8.13797 0.532959C4.13953 0.532959 0.897461 3.87622 0.897461 7.99945C0.897461 12.1227 4.13953 15.4659 8.13797 15.4659C9.88449 15.4659 11.4866 14.8278 12.7381 13.7642L16.5371 17.6818C16.9806 18.1001 17.3966 17.8307 17.541 17.6818C17.8298 17.3875 17.8298 16.9443 17.541 16.6501ZM2.31049 7.99945C2.31049 4.68101 4.91996 1.99009 8.13797 1.99009C11.356 1.99009 13.9689 4.68101 13.9689 7.99945C13.9689 11.3179 11.3594 14.0088 8.1414 14.0088C4.9234 14.0088 2.31049 11.3179 2.31049 7.99945Z" fill="black"/>
      </svg>
    </div>
  </div>
</Form>
</div>
<div className="card mb-2" style={{ borderRadius: "10px", display: "flex", alignItems: "center", flexDirection: "row" }}>
  <div style={{ flex: 1 }}>
   
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#EBFBFF", padding: "0px 10px", borderTopLeftRadius: "10px",borderTopRightRadius:"10px" }}>
      <p style={{ color: "#000000", margin:'4px 10px',fontSize:'small' }}><span style={{fontWeight:"400",color:"black" }}>1566 people </span><span style={{fontWeight:"400",color:"#2D99B1"}}>have been visited to store and submitted feedback</span></p>
     
    </div>
    
    {/* Address Section Below the Header */}
    <div style={{padding:"10px 10px"}}>
    <div className="form-group">
                  <label style={{ padding: "4px" ,fontSize:"14px"}}>
                 Do you want to write something about Nutsby store <span style={{ color: "#9D9D9D" }}></span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Write Here..."
                    rows="2"
                    name="description"
                    style={{backgroundColor:"#F5F5F5"}}
                  ></textarea>
                   </div>
                   
                   <div className="col-12 col-lg-3 col-md-4">
  <button
    type="submit"
    className="btn w-100"
    style={{
      backgroundColor: "#023047",
      color: "white",
      borderRadius: "25px",
      marginRight: "14px",
      fontFamily: "Inter",
      fontSize:'14px',
      padding:'3px',
    
    }}
  >
    Submit
  </button>
</div>

  </div>
  
</div>
</div>

            </div>
    
          
            <div className="container"
  style={{
    backgroundColor: "#fff",            
    borderRadius: "10px",                
   
    padding: "20px",                     
    margin: "10px",                     
    color: "#000",                       
    fontSize: "15px",                   
    fontWeight: "600",                  
                   
  }}
>
  Top Brands At Nutsby - Jubilee Hills Store
</div></div>

    
         
         
        </div></>
      );
    }
    

export default StoreloactorJS;