import React, { useEffect, useState } from "react";
import {Container, Row, Col,Modal,Image, Offcanvas,Card, NavLink, Spinner} from 'react-bootstrap'; 
import {AddToCart, GetHeaderDetailsApi} from '../../../Service/ApiCalling';
import Button from 'react-bootstrap/Button';
import "../../../SubCss/CartModal.css"; 
import  {DiscountIcon,EliteFullLogoIcon,EliteIcon, EliteNewInfoIcon, NewAddToCartIcon, NewCloseIcon,EliteFullLogoIconcart} from "../../../MiniComponents/SvgIcons";  
import { useCart } from "./CartContext";
import { height, width } from "@fortawesome/free-solid-svg-icons/fa0";
import {ReactComponent as EliteMewLogo} from '../../../assets/images/E_mem_fullLogo.svg';
import { useNavigate } from "react-router-dom";
export default function CartItemCard(params) {

    const [cart_qnty, setcart_qnty] = useState(params.cart_quantity);
    const { setCartCount } = useCart();

    const [addbuttonClicked,setAddButtonClicked] = useState(false);
    const [removebuttonClicked,setRemoveButtonClicked] = useState(false);

    useEffect(() => {
        setcart_qnty(params.cart_quantity); 
     }, [params.cart_quantity]); 

    const AddToCartCall=async (p_id,qnt,clicked)=> { 
        if(clicked===true){
            setAddButtonClicked(true);
        }
        
        let addcartDatatemp = await AddToCart(p_id,qnt);
        if (addcartDatatemp.error === "0") {
            
            // params.setChildChanged("1");
            setcart_qnty(qnt);
            console.log(p_id,qnt,":product_qnt");
            setAddButtonClicked(false);
            params.cartrefresh();
            params.uncheck();
            
            if(params.type!="normal"){
                params.removeSuggest();
            }
            
            const headerData = await GetHeaderDetailsApi();
              if (headerData.error === "0") {
                if(headerData.cart_count!=null){
                  setCartCount(headerData.cart_count);
                }else{
                  setCartCount(0);
                }
                
                console.log(headerData.cart_count,"khfaosdufhuafs") 
              }
        }
      };

      const navigate = useNavigate()
      const gotToNewPagesss = () => {
        // navigate("/membership_details");
        // window.location.href = `/membership_details`;
      }

return (
<>
    {((Number(cart_qnty)>0) && params.product_id || params.type!="normal") ?
    <>
        <Row>
            <Col sm={3} md={3} xs={3} lg={3 } >
                <Image src={params.image} layout="fill" quality={100}  style={{margin:"0px !important",padding:"0px"}} rounded />
            </Col>
            <Col sm={9} md={9} xs={9} lg={9}>
                <div class="d-flex">
                    <Col sm={11} md={11} xs={10} lg={11}>
                        <p className="cart-item-name">{params.name}</p>
                    </Col>
                    <Col sm={1} md={1} xs={2} lg={1} style={{paddingLeft:"0px"}}> 
                     {removebuttonClicked===false?  
                         // <a onClick={() =>{
                        //     params.removeCart(params.cart_id,setRemoveButtonClicked(true));
                        //     setcart_qnty(0);params.cartrefresh();
                        //     if(cart_qnty===0){
                        //         setRemoveButtonClicked(false);
                        //     }
                        //     }} style={{float:"right",cursor:"pointer"}}>
                        //     <NewCloseIcon />

                            

                        // </a>: 
                        <a onClick={async () => {
                            setRemoveButtonClicked(true);
                            await params.removeCart(params.cart_id); 
                            setcart_qnty(0);
                            params.cartrefresh();
                            setRemoveButtonClicked(false); 
                            const headerData = await GetHeaderDetailsApi();
                            if (headerData.error === "0") {
                              const count = headerData.cart_count != null ? headerData.cart_count : 0;
                              setCartCount(count); // Update cart count from header data
                              console.log(count, "cart_count updated after removal");
                            }
                        }} style={{float:"right",cursor:"pointer"}}>
                            <NewCloseIcon />
                        </a>
                         :

                        <div className="">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="xs"
                                        role="status"
                                        aria-hidden="true"
                                        style={{width:"15px",height:"15px"}}
                                        />
                                        <span class="visually-hidden">Loading...</span>                  
                                    </div>
                                    } 
                    </Col>
                </div>

                <Row className="d-flex" sm={12} md={12} xs={12} lg={12}>
                    
                    {params.is_subscribed===1?
                            <Container style={{paddingBottom:"3px"}} ><b className="premimum-tag-text1" >{params.premium_price}</b></Container>:
                                <p style={{width:"auto"}}><b style={{fontSize:"18px"}}>{params.product_total}</b>&nbsp;<span style={{fontSize:"14px"}} className="mrp-strike">{params.product_total_mrp}</span></p>}
                    
                    

                            {params.dis==="0"?<></>:
                            
                                <Container className="discount-tag" style={{width:"auto",height:"fit-content"}}>
                                
                                    <DiscountIcon></DiscountIcon>

                                    &nbsp;
                                    <span style={{fontSize:"10px"}}>{params.dis}% Off </span>
                                </Container> 
                            }
                    
                </Row>
                <Row className="d-flex justify-content-between">
                    <Col  sm={6} md={6} xs={6} lg={6}>
                        {params.is_subscribed===1?
                            <p><b style={{fontSize:"15px",textDecoration:"line-through"}}>{params.product_total}</b>&nbsp;<span style={{fontSize:"14px"}} className="mrp-strike">{params.product_total_mrp}</span></p>:
                            // <Row className="premimum-tag d-flex align-items-center" style={{width:"auto",paddingBottom:"3px",gap:"0px"}} >
                            //     <Col xs={12} sm={12} md={12} lg={12} className="d-flex justify-content-baseline align-items-center">
                            //     <EliteMewLogo style={{width:"100%"}}/>
                            //         <b className="premimum-tag-text" >{params.premium_price}</b>
                            //         &nbsp;
                                
                            //         <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 12" fill="none" style={{width:"50%",alignItems:"center"}} onClick={()=>gotToNewPagesss()}>
                            //         <path d="M5.78298 0C2.58882 0 3.13776e-08 2.58882 3.13776e-08 5.78238C-7.90826e-05 6.54183 0.149448 7.29387 0.440042 7.99553C0.730636 8.69718 1.1566 9.33473 1.69362 9.87174C2.23064 10.4088 2.86818 10.8347 3.56984 11.1253C4.2715 11.4159 5.02353 11.5654 5.78298 11.5654C8.97594 11.5654 11.5654 8.97654 11.5654 5.78238C11.5654 2.58882 8.97594 0 5.78298 0ZM6.32267 2.08768C6.88645 2.08768 7.05209 2.41475 7.05209 2.78879C7.05209 3.2556 6.67865 3.68747 6.04078 3.68747C5.50711 3.68747 5.25293 3.41943 5.26859 2.97552C5.26859 2.60147 5.5812 2.08768 6.32267 2.08768ZM4.87828 9.24578C4.49279 9.24578 4.2115 9.01148 4.48074 7.9845L4.92225 6.16245C4.99875 5.87092 5.0114 5.75407 4.92225 5.75407C4.80721 5.75407 4.30667 5.95525 4.01153 6.15402L3.81938 5.839C4.75601 5.05657 5.83298 4.59759 6.29376 4.59759C6.67925 4.59759 6.7431 5.05296 6.55095 5.75407L6.045 7.66948C5.95525 8.00799 5.9938 8.12485 6.08354 8.12485C6.19919 8.12485 6.57746 7.98511 6.9497 7.69177L7.16774 7.9845C6.25641 8.89523 5.26317 9.24578 4.87828 9.24578Z" fill="white"/>
                            //     </svg>
                                
                            //     </Col>
                                
                            
                            // </Row>
                            <Row className="elite_tag" style={{margin:"0px 0px"}}>
                          <div style={{backgroundColor: "black",borderRadius: "16px",paddingLeft:"5px",paddingBottom:"3px", paddingTop:"3px", gap: "5px",display:"inline-flex",paddingRight:"5px"  }}>
                        <Col xs={9} sm={9} md={9} lg={9} style={{display:'flex'}}>
                        <EliteFullLogoIconcart></EliteFullLogoIconcart>
                        <span style={{fontSize:"15px",background: "linear-gradient(to right, #FBE4BA, #9E7829)",webkitBackgroundClip: "text", webkitTextFillColor:"transparent",fontWeight:"600" }}>&nbsp; 
                          {params.premium_price}
                        
                          </span></Col>
                          <Col className="d-flex justify-content-end inform" xs={3} sm={3} md={3} lg={3} style={{padding:"0px 5px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" style={{width:"50%",height:"100%"}} onClick={()=>gotToNewPagesss()}>
                            <path d="M5.78298 0C2.58882 0 3.13776e-08 2.58882 3.13776e-08 5.78238C-7.90826e-05 6.54183 0.149448 7.29387 0.440042 7.99553C0.730636 8.69718 1.1566 9.33473 1.69362 9.87174C2.23064 10.4088 2.86818 10.8347 3.56984 11.1253C4.2715 11.4159 5.02353 11.5654 5.78298 11.5654C8.97594 11.5654 11.5654 8.97654 11.5654 5.78238C11.5654 2.58882 8.97594 0 5.78298 0ZM6.32267 2.08768C6.88645 2.08768 7.05209 2.41475 7.05209 2.78879C7.05209 3.2556 6.67865 3.68747 6.04078 3.68747C5.50711 3.68747 5.25293 3.41943 5.26859 2.97552C5.26859 2.60147 5.5812 2.08768 6.32267 2.08768ZM4.87828 9.24578C4.49279 9.24578 4.2115 9.01148 4.48074 7.9845L4.92225 6.16245C4.99875 5.87092 5.0114 5.75407 4.92225 5.75407C4.80721 5.75407 4.30667 5.95525 4.01153 6.15402L3.81938 5.839C4.75601 5.05657 5.83298 4.59759 6.29376 4.59759C6.67925 4.59759 6.7431 5.05296 6.55095 5.75407L6.045 7.66948C5.95525 8.00799 5.9938 8.12485 6.08354 8.12485C6.19919 8.12485 6.57746 7.98511 6.9497 7.69177L7.16774 7.9845C6.25641 8.89523 5.26317 9.24578 4.87828 9.24578Z" fill="white"/>
                          </svg>
                          </Col>

                      </div>
                        </Row>
                        }                            
                    </Col> 
                    
                    <Col sm={6} md={5} xs={6} lg={5}>
                        
                            <Container className="cart-inc-btn-radius" style={{width:"90%",float:"right"}}>
                            {addbuttonClicked===false?
                                <Row className="d-flex justify-content-center">                                                                                                                                
                                    <Col sm={4} md={4} xs={4} lg={4}>
                                    <Button ref={params.reference} className="light-greenes-blue cart-inc-btn " variant="transparent" onClick={() =>   { AddToCartCall(params.product_id,(Number(cart_qnty)-1),true)}}>-</Button>
                                    </Col>
                                    <Col sm={4} md={4} xs={4} lg={4}>
                                    <Button className="cart-inc-btn" variant="transparent">{cart_qnty}</Button>
                                    </Col>
                                    <Col sm={4} md={4} xs={4} lg={4}>
                                    <Button className="light-greenes-blue cart-inc-btn" variant="transparent" onClick={() =>   { AddToCartCall(params.product_id,(Number(cart_qnty)+1),true)}}>+</Button>
                                    </Col>                                    
                                </Row>:
                                <Row className="d-flex justify-content-center">
                                <Container className="text-center">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />
                                        <span class="visually-hidden">Loading...</span>                  
                                    </Container>
                                </Row>
                                }
                            </Container>
                        
                        
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="mt-10 mx-5">
            <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
        </Row> 
    </> 
    :<></>}
</>
)
}