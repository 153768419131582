import React, { useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../SubCss/Affiliatepage.css"; 
import { Container, Row, Col, Button, Breadcrumb,Form } from 'react-bootstrap';
import { register_affiliate } from '../../Service/ApiCalling';
import { useLocation} from 'react-router-dom'; 

function ContactJS() {
   
    
        const [otherCategory, setOtherCategory] = useState(false);
      const [upiSelected, setUpiSelected] = useState(false);
      const [bankDetailsSelected, setBankDetailsSelected] = useState(false);
      const location = useLocation();
      const { pathname } = location;
      const [payee_name, setName] = useState('');
      const [instagram_link, setinstagram_link] = useState('');
      const [website_link, setwebsite_link] = useState('');
      const [category, setcategory] = useState('');
      const [description, setdescription] = useState('');
      const [bank_name, setbank_name] = useState('');
      const [bank_branch_name, setbank_branch_name] = useState('');
      const [bank_ifsc_code, setbank_ifsc_code] = useState('');
       const [bank_account_number, setbank_account_number] = useState('');
       const [bank_upi_id, setbank_upi_id] = useState('');
      const fetchInfo=async ()=> {  
        // if(params.id==="1"){
            let register_affiliateData = await register_affiliate(payee_name,instagram_link,website_link,category,description,bank_name,bank_branch_name,bank_ifsc_code, bank_account_number, bank_upi_id);
            if (register_affiliateData.error==="0") {  
                
               
    }}
      useEffect(() => {
        fetchInfo();
        if (pathname === "/contact-us") {
          document.body.style.backgroundColor = "#F4F5FA";
         
        } else {
          document.body.style.backgroundColor = "white";
        }
      }, [pathname]);
      return (
       <>
    <div className="mainCarousel d-none d-md-block d-lg-block container" style={{paddingBottom: "0px",paddingTop: "10rem",borderRadius:'30px'}} > 
                  <Row >
                        <Col lg={12} md={12} sm={12} col={12}  >
                          
                              
                              
                                <img src={require("../../assets/images/PNG/contactus.png")} alt="" style={{ borderRadius: '20px' ,webkitUserDrag:"none"}} />
                              
                                
                          </Col>                    
                      </Row> 
                  </div>
                  <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container" style={{paddingBottom: "0px",paddingTop: "9rem"}} > 
                      <Row>
                      <Col lg={12} md={12} sm={12} col={12}>
                      
                      
                                <img src={require("../../assets/images/PNG/contactus_mb.png")} alt="" style={{ borderRadius:'18px' ,webkitUserDrag:"none"}} />
                              
                            
                          </Col>                      
                      </Row> 
                  </div>  
    
        <div className="container" style={{padding:"20px"}}>
            
          <div className="row">
          <div className="col-md-6" style={{paddingLeft:"1px"}}>
            
          <div className="card mb-2" style={{ borderRadius: "20px", display: "flex", alignItems: "center" ,flexDirection:"row",padding:"38px"}}>
            <img 
                src={require("../../assets/images/PNG/location.png")} 
                alt="Location" 
                style={{ width:"10%", height:"10%", marginRight: "20px", flexShrink: 0 }} 
            />
            <div>
            <h5 style={{ color: "#000000",margin: 0 }}>Corporate Office</h5>
            <h6 style={{margin: 0,color:"#808080",fontWeight:"300" }}>A-46, Madhura Nagar,<br></br>Hyderabad,Telangana, India - 500038.</h6>
            </div>
        </div>
        <div className="card mb-2" style={{ borderRadius: "20px", display: "flex", alignItems: "center" ,flexDirection:"row",padding:"38px"}}>
            <img 
                src={require("../../assets/images/PNG/message.png")} 
                alt="Location" 
                style={{ width:"10%", height:"10%", marginRight: "20px", flexShrink: 0 }} 
            />
            <div>
            <h5 style={{ color: "#000000",margin: 0 }}>Email ID</h5>
            <h6 style={{margin: 0,color:"#808080",fontWeight:"300" }}>care@nutsby.com</h6>
            </div>
        </div>
        <div className="card mb-2" style={{ borderRadius: "20px", display: "flex", alignItems: "center" ,flexDirection:"row",padding:"38px"}}>
            <img 
                src={require("../../assets/images/PNG/call.png")} 
                alt="Location" 
                style={{ width:"10%", height:"10%", marginRight: "20px", flexShrink: 0 }} 
            />
            <div>
            <h5 style={{ color: "#000000",margin: 0 }}>Contact Us</h5>
            <h6 style={{margin: 0,color:"#808080",fontWeight:"300" }}>+91 9701823543</h6>
            </div>
        </div>
            </div>
            <div className="col-md-6" style={{paddingLeft:"0px"}}>
              <div className="card " style={{borderRadius:"20px",padding:"20px"}}>
                <h4 style={{color:"#000000",fontWeight:"600",marginBottom:"5px"}}>Get in touch with us</h4>
                <Form>
                  <div className="form-group" style={{marginBottom:"5px"}}>
                    <label style={{padding:"4px"}}><span style={{color:""}}>Name</span></label>
                    <input type="text" className="form-control affliate" placeholder="Full Name" required />
                  </div>
                  <div className="row">
                  <div className="form-group col-6"  style={{paddingRight:'2px'}}>
                    <label style={{padding:"4px"}}>Phone no</label>
                    <input type="link" className="form-control affliate" placeholder="Phone no" required />
                  </div>
                  <div className="form-group  col-6" style={{paddingLeft:'2px'}}>
                    <label style={{padding:"4px"}}>Email Id </label>
                    <input type="link" className="form-control affliate" placeholder="Website Link" />
                  </div>
                  <div className="form-group">
                  <label style={{ padding: "4px" }}>
                  Write a message <span style={{ color: "#9D9D9D" }}></span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Write Here..."
                    rows="3"
                    name="description"
                    style={{backgroundColor:"#F5F5F5"}}
                  ></textarea>
                   </div>
                   
                   <div className="col-12 col-lg-3 col-md-4">
  <button
    type="submit"
    className="btn w-100"
    style={{
      backgroundColor: "#023047",
      color: "white",
      borderRadius: "25px",
      marginRight: "14px",
      fontFamily: "Inter",
    
    }}
  >
    Submit
  </button>
</div>

                  </div>
              
                 
                </Form>
                
              </div>
            </div>
    
          
           
          </div>
    
          {/* Submit Button */}
         
        </div></>
      );
    }
    

export default ContactJS;