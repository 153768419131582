import React from "react";
import {Row,Col,Image} from 'react-bootstrap';
import Ratings from "../MainComponents/Ratings";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import '../SubCss/allreview.css'
const AllReviews = (props)=>{
    return(
        <>
        <div class="single-comment mb-20 mt-20">
    <div className="row justify-content-between">
        <div className="user d-flex d-none d-md-block d-lg-block d-sm-block" style={{paddingLeft:"0px",margintop:'20px'}}>
            <Col lg={12} md={12} sm={12} xs={12}>
                <Row className="d-flex justify-content-between" >               
                    <Col lg={3} md={3} sm={3} xs={3}  style={{display:"flex",justifyContent:"center"}}>
                   
                    <Image src={props.img} style={{ objectFit: "cover", borderRadius: '100px' }} alt="Profile"
                   id="image_profile"   roundedCircle/>
                                                    
                    </Col>
                    <Col lg={9} md={9} sm={9} xs={9} style={{paddingLeft:"0px"}}>
                    <div className="mb-8">
                        <div className="d-flex align-items-center">
                        <a style={{color:"black",fontSize:"20px"}} className="font-heading text-brand">{props.name}</a>                            
                        </div>

                        <Row className="align-items-center">
                       <div className="d-flex align-items-center">
                          <Ratings rating={props.rating} width={20} height={20} />
                          <span className="font-small ml-3 fw-bold">{props.title}</span>
                          <span style={{ color: "#000", marginLeft: '10px',fontSize:"18px" }}>{props.feedback}</span>
                        </div>
                      </Row>

                        <Row>
                        <span style={{color:"#9C9C9C" ,fontSize:"14px"}}>Reviewed on Dt {props.date}</span>
                        </Row>

                        <Row>
                            {(props.feedback_img!=null)?<img src={props.feedback_img} style={{width:"100px",height:"100px"}}alt="" />:<span className="font-xs text-muted;"><br/></span>
                            }                        
                        <p className="mb-10">{props.review} </p>
                        </Row>
                        <Row className="mt-20 d-none d-md-block d-lg-block">
                            <hr className="col-lg-12 col-md-7 col-sm-12 col-12"></hr>    
                        </Row> 
                    </div>
                    </Col>

                </Row>
            </Col>           
        </div>
        <div className="user d-flex  d-md-none d-lg-none d-sm-none" style={{paddingLeft:"10px",margintop:'20px'}}>
            <Col lg={12} md={12} sm={12} xs={12}>
                <Row className="d-flex justify-content-between" >               
                    <Col lg={3} md={3} sm={3} xs={3} >
                   
                    <Image src={props.img} style={{ objectFit: "cover", borderRadius: '100px' }} alt="Profile"
            id="image_profile"   roundedCircle/>
                                                    
                    </Col>
                    <Col lg={9} md={9} sm={9} xs={9}>
                    <div className="mb-8">
                        <div className="d-flex align-items-center">
                        <a style={{color:"black",fontSize:"18px"}} className="font-heading text-brand">{props.name}</a>                            
                        </div>

                        <Row>
  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
   
    <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
    <span style={{ fontSize: '14px' }}>{props.rating}</span>
      {props.rating > 0 && (
        <FontAwesomeIcon icon={faStar} style={{ color: 'gold', fontSize: '14px',marginLeft: '3px' }} />
      )}
     

  
          <span className="font-small fw-bold" style={{ marginBottom: '4px', fontSize: '14px',marginLeft:'5px',fontWeight:'500' }}>{props.title}</span>
          <span style={{ color: "#000", fontSize: '14px',marginLeft:'5px',fontWeight:'500' }}>{props.feedback}</span>
        
    </div>

    
    <span style={{ color: "rgb(119, 119, 119)", marginLeft: 'auto', fontSize: '12px' }}>{`Dt ${props.date}`}</span>
  </div>
</Row>



                        <Row>
                            {(props.feedback_img!=null)?<img src={props.feedback_img} style={{width:"100px",height:"100px"}}alt="" />:<span className="font-xs text-muted;"></span>
                            }                        
                      <p className="mb-10 mt-1" style={{
    color: "#777777",
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    lineClamp: 3, 
   
    fontSize: '14px' 
}}>
  {props.review}
</p>
                        </Row>
                        <Row className="mt-20 d-none d-md-block d-lg-block">
                            <hr className="col-lg-12 col-md-7 col-sm-12 col-12"></hr>    
                        </Row> 
                    </div>
                    </Col>

                </Row>
            </Col>           
        </div>
    </div>
</div>
        </>

    );
}
export default AllReviews