import React, { useEffect, useState } from "react";
import {Container, Row, Col,Modal,Image, Offcanvas,Card} from 'react-bootstrap'; 
import { CartListApiCall,AddToCart,RemoveItemFromCart,ApplyCouponApi,ClearUnavaialbeCartApiFunction,EditContactAPIFunction,AddOrder, UpdateAddressSelection, RemoveCoupon, OrderRelatedData} from '../Service/ApiCalling';
import Button from 'react-bootstrap/Button';
import "../SubCss/CartModal.css";
import Loader from "react-js-loader";
import { width } from "@fortawesome/free-solid-svg-icons/fa0"; 
import SvgIcons,{CouponRemove, DiscountIcon,EliteIcon, NewAddToCartIcon, NewExploreIcon} from "../MiniComponents/SvgIcons.jsx";
import CouponsList from "./SubComponents/CouponsList";
import { faI } from "@fortawesome/free-solid-svg-icons";
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import CartItemCard from "../MainComponents/SubComponents/MiniComponents/CartItemCard.jsx";
import CartFreeCardItem from "../MainComponents/SubComponents/MiniComponents/CartFreeCardItem.jsx";
import Addressbook from "./SubComponents/Addressbook";
import useRazorpay from "react-razorpay";
import Login from "./Login";
import { ShimmerDiv } from "shimmer-effects-react";
import { FaArrowLeft } from "react-icons/fa";
import {ReactComponent as HomeIcon} from '../assets/images/homegroup2.svg';
import {ReactComponent as WorkIcon} from '../assets/images/presentation.svg';
import {ReactComponent as OtherIcon} from '../assets/images/menu.svg';
import {ReactComponent as PersonIcon} from '../assets/images/homegroup1.svg';
import {ReactComponent as CouponIcon} from '../assets/images/coupon_image.svg';
import Lottie from "lottie-react";
import couponTag from '../assets/lottie/coupon-tag.json';
import { useNavigate } from "react-router-dom";
import { bottombarshow } from "../Service/ApiNames.js";
import { useMediaQuery } from '@mui/material';
const CartButton = styled.button`
    font-weight: 500;
    font-family: "Inter", sans-serif;
    background-image:linear-gradient(to right,#29210E, #29210E);
    border: none !important;
    border-radius: 50%;
    
    padding: 3px;
    font-size: 0em;
    margin-top:45px;
`;

const CartButton2 = styled.div`
    font-weight: 500;
    font-family: "Inter", sans-serif;
    color:#F0D8A9;
    font-size: 1em;
    margin-top:45px;
`;

export default function CartList(){

    const [showLoginModal,
        setshowLoginModal] = React.useState(false);
    
    const handleMapClose = () => setshowLoginModal(false);

    const [Razorpay] = useRazorpay();

    const [isVisible, setIsVisible] = useState(false);
    const [shimmer_loading,setShimmerLoading] = useState(true);
    const shimmeritems = Array.from({ length: 5 });
   

    const [nameinput, setnameinput] = useState('Name');
    const [mobilenumberinput, setmobilenumberinput] = useState('Mobile Number');

    const handleChange = (event) => {
        setnameinput(event.target.value);
      };

      const handlesetmobilenumberinputChange = (event) => {
        let value = event.target.value.replace(/\D/g, ""); // Remove non-digit characters

    // Validate the input
    if (value.length <= 10) {
      // if (value.length === 0 || /^[6-9]/.test(value)) {
        setmobilenumberinput(event.target.value);
      // }
    }
        
      };
    const childref = React.useRef();

    const [ChildChanged, setChildChanged] = useState(""); 

    const [itemsTotal, setitemsTotal] = useState(0.0);
    const [deliveryAmount, setdeliveryAmount] = useState(null);
    const [couponAmount, setcouponAmount] = useState(0.0);
    const [ElitSaved, setElitSaved] = useState("0.0");

    const [ElitePlanInCart, setElitePlanInCart] = useState(0);
    const [removeClicked,setRemoveClicked] = useState(false);

    // var is_coupon_applied="0";
    const [is_coupon_applied, setisCouponApplied] = useState("0");
    const [is_checked, setIsChecked] = useState("0");
    const [ElitePlanId, setElitePlanId] = useState("0");
    const [is_subscribed, setIsSubscribed] = useState(0);
    const [gloabal_is_subscribed, setGlobalIsSubscribed] = useState(0);
    const [final_amount, setFinalAmount] = useState(0.00);
    const [finalelitecouponamount, setfinalelitecouponamount] =useState(0.00)
    const [temp_final_amount, setTempFinalAmount] = useState(0.00);
    const [elite_final_amount, setEliteFinalAmount] = useState(0.00);
    const [temp_elite_final_amount, setTempEliteFinalAmount] = useState(0.00);
    const [cartProducts, setcartProducts] = useState([]);
    const [free_products, setfree_products] = useState([]);
    const [coupon_products, setcoupon_products] = useState([]);
    const [NostockcartProducts, setNostockcartProducts] = useState([]);
    const [cartCalculationData, setcartCalculationData] = useState([]);
    const [cartCount, setcartCount] = useState("");
    const [addressData, setaddressData] = useState([]);
    const [addressData1, setaddressData1] = useState([]);
    
   
    const [fitCastAvaiable, setfitCastAvaiable] = useState("0");
    const [fitbalance, setFitBalance] = useState(0);
    const [tempfitCastAvaiable, setTempFitCastAvaiable] = useState("0");
    const [couponshow, setcouponShow] = useState(false);
    const [couponid, setCouponID] = useState("");
    const [cartDataTotal,setCartDataTotal] = useState([]);
    const [orderNumber,setOrderNumber] = useState('');
const [orderIdEncoded,setOrderIdEncoded]= useState('');
const [orderID,setOrderID]= useState('');
    var Couponsetlocal = '';
    const handlecouponClose = () => setcouponShow(false);
    const handlecouponShow = () => setcouponShow(true);
    var isGuest = "0";

 const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };
    const [addressshow, setaddressshow] = useState(false);
    const handleaddressshowClose = () => setaddressshow(false);
    const handleaddressshowShow = () => setaddressshow(true);
    
    const navigate = useNavigate()
    const gotToNewPagesss = () => {
      // navigate("/membership_details");
    //   window.location.href = `/membership_details`;
    }
  const fetchInfo=async ()=> {  
    console.log("called from Card","fetcehed info")
    // setIsSubscribed(localStorage.getItem('is_subscribed'));
        // console.log(is_subscribed,"is_subscribed");
        isGuest = localStorage.getItem("is_guest");
        if(isGuest==="1"){
            // console.log(isGuest,"isGuest");
        }
        
        try{
        let CartListApiCallDatatemp = await CartListApiCall();
        if (CartListApiCallDatatemp.error==="0") {
            console.log(CartListApiCallDatatemp.selected_address,'total12134')
               setaddressData1(CartListApiCallDatatemp.selected_address); 
            setTimeout(() => {
                setShimmerLoading(false);
                setElitSaved(CartListApiCallDatatemp.elite_saved);
                setIsSubscribed(Number(CartListApiCallDatatemp.is_subscribed));
                
                let test=CartListApiCallDatatemp;
                setElitePlanId(CartListApiCallDatatemp.plan_item_id);
                setNostockcartProducts(CartListApiCallDatatemp.no_stock_cart_items);
                setcartCalculationData(CartListApiCallDatatemp);
                setfree_products(CartListApiCallDatatemp.free_products);
                setFinalAmount(CartListApiCallDatatemp.total_price3);

                setTempFinalAmount(CartListApiCallDatatemp.total_price3);
                setEliteFinalAmount(CartListApiCallDatatemp.elite_total_price3);
                setTempEliteFinalAmount(CartListApiCallDatatemp.elite_total_price3);
                setcartCount(CartListApiCallDatatemp.total_quantity);
                setcartProducts(CartListApiCallDatatemp.cart_items);                
                setfitCastAvaiable(CartListApiCallDatatemp.fp_amount);
                setTempFitCastAvaiable(CartListApiCallDatatemp.fp_amount);
                setCartDataTotal(CartListApiCallDatatemp);

                if(CartListApiCallDatatemp.selected_address!=null){
                    // console.log("address not nul");
                    
                    setaddressData(CartListApiCallDatatemp.selected_address);
                 
                    setnameinput(CartListApiCallDatatemp.selected_address.name);
                    setmobilenumberinput(CartListApiCallDatatemp.selected_address.mobile_number);
                 
                }else{                    
                        setnameinput("");
                        setmobilenumberinput("Please Add your Contact Details");
                      
                        
                }
                

                // setFitBalance(Number(CartListApiCallDatatemp.fp_amount))
                
                    setcouponAmount(cartCalculationData.coupon_amount);                
                
                
                      
                // var del_amt = cartCalculationData.delivery_amount; 
                // console.log(del_amt,"del_amout");
                // var a_del_amt = del_amt.replace("₹","");
                // console.log(a_del_amt,"a_del_amt");
                // var finaal_del_amount =  parseFloat(a_del_amt);
                // console.log(finaal_del_amount,"final_del_amout");
                // setdeliveryAmount(finaal_del_amount); 

                var del_amt = CartListApiCallDatatemp.delivery_amount;
                console.log(CartListApiCallDatatemp.delivery_amount,"cartCalculationData.delivery_amount");

if (del_amt) {
    console.log(del_amt, "del_amout");
    var a_del_amt = del_amt.replace("₹", "");
    console.log(a_del_amt, "a_del_amt");
    var finaal_del_amount = parseFloat(a_del_amt);
    console.log(finaal_del_amount, "final_del_amout");
    setdeliveryAmount(finaal_del_amount);
} else {
    console.error("delivery_amount is undefined or null");
}

                setitemsTotal(CartListApiCallDatatemp.items_total);
                if(is_checked=="1"){
                    CalculateFitcash();
                }else{
                    // console.log("Hello Nutsby!");
                    
                }
                Couponsetlocal = localStorage.getItem('CouponId');
                console.log(CartListApiCallDatatemp.coupon_id,":Cart list response coupon iD")

                if(Couponsetlocal!='' && Number(CartListApiCallDatatemp.coupon_id)>0){
                    console.log(":Applied");
                    ApplyCouponApiFunction(Couponsetlocal);
                }else{
                    console.log(":Not applied");
                }

                // if(cartProducts.length<1){
                //     RemoveCouponApiFunction();
                // }
                
            }, 1500);
            
        }else{
            setShimmerLoading(false);
            setcartProducts([]);
        }
    }catch (error){
        console.log(error,"cart");
    }
  };
  
//   const ApplyCouponApiFunction = async (couponId) => {
//     console.log(couponId,":Applied couponn iD")
//     setcouponShow(false);
//     setCouponID(couponId);
    
//     // console.log("ApplyCouponApiFunction Cart", couponId);
//     try{
//     let data = await ApplyCouponApi(couponId);
//     if(data.error=="0"){
//         // console.log("ApplyCouponApiFunction Cart", couponId);
//         setisCouponApplied("1");
//         localStorage.setItem('CouponId', couponId);
//         var disc_amt;
//         if(data.discount_amount.includes("₹")){
//             disc_amt = data.discount_amount.replace("₹","");
//         }
//         if(disc_amt.includes(",")){
//             disc_amt = data.discount_amount.replace(",","");
//         }
        
//         var ord_total;
//         if(data.order_total.includes("₹")){
//             ord_total = parseFloat(data.order_total.replace("₹",""));
//         }
//         if(ord_total.includes(",")){
//             ord_total = parseFloat(data.order_total.replace(",",""));
//         }
        
        
        
//         setcouponAmount(disc_amt);
        
//         if(is_subscribed===1){
//             setEliteFinalAmount(ord_total);
//         }else{
//             setFinalAmount(ord_total);
//         }
//         // console.log("ApplyCouponApiFunction Cart", is_coupon_applied);
        
//         setcoupon_products(data.coupon_products);
//     }
// }catch(error){
//     console.log(error,"apply_coupon")
// }
//   };
const ApplyCouponApiFunction = async (couponId) => {
    
    setcouponShow(false);
    setCouponID(couponId);
    
    // console.log("ApplyCouponApiFunction Cart", couponId);
    try{
    let data = await ApplyCouponApi(couponId);
    if(data.error=="0"){
        // console.log("ApplyCouponApiFunction Cart", couponId);
        setisCouponApplied("1");
        localStorage.setItem('CouponId', couponId);
        var disc_amt = data.discount_amount.replace(/[₹,]/g, '');
        var ord_total = parseFloat(data.order_total.replace(/[₹,]/g, ''));
        setfinalelitecouponamount(ord_total);
        // var disc_amt;
        //         if(data.discount_amount.includes("₹")){
        //             disc_amt = data.discount_amount.replace("₹","");
        //         }
        //         if(disc_amt.includes(",")){
        //             disc_amt = data.discount_amount.replace(",","");
        //         }
                
        //         var ord_total;
        //         if(data.order_total.includes("₹")){
        //             ord_total = parseFloat(data.order_total.replace("₹",""));
        //         }
        //         if(ord_total.includes(",")){
        //             ord_total = parseFloat(data.order_total.replace(",",""));
        //         }



        console.log(disc_amt,"amountrejgke");
        console.log(data.order_total,"amountrejgke12");
        console.log(ord_total,"amountrejgke2");
        setcouponAmount(disc_amt);
        
        if(ElitePlanInCart === 1 || is_subscribed === 1){
            // alert(ord_total);
            setEliteFinalAmount(ord_total);
        }else{
            setFinalAmount(ord_total);
            // alert(ord_total);
        }
        // console.log("ApplyCouponApiFunction Cart", is_coupon_applied);
        
        setcoupon_products(data.coupon_products);
    }
}catch(error){
    console.log(error,"apply_coupon")
}
  };

  const RemoveCouponApiFunction = async () =>{
    try{
    let data = await RemoveCoupon();
    if(data.error==="0"){
        // console.log("RemoveCouponApiFunction Cart", data);
        setisCouponApplied("0");
        Couponsetlocal = '';
        localStorage.setItem('CouponId','');
        // console.log(localStorage.getItem('CouponId'),"kljhfiwuehfiuwfhriwuefh");
        fetchInfo();
        setcoupon_products([]);

    }
}catch (error){
    console.log(error,"remove_coupon")
}
  }

  const UpdateAddressSelectionApi = async (id) => {
    try{
        let data = await UpdateAddressSelection(id);
        if(data.error === "0"){
            // console.log("Sucesss1 updated");
        }else{
            // console.log("Error updated");
        }
    }catch (error){
        console.log(error,"update_address")
    }
}



const handleAddressSelect = () => {
    // Close the Offcanvas
    handleaddressshowClose();
    setShimmerLoading(true);
    fetchInfo();
};

const CalledChecked =()=>{
    // console.log("CalledChecked");
    setIsChecked("0");
    console.log(is_checked,"is Checked")
    CalculateFitcash();
}

  const CalculateFitcash =()=>{
    // console.log("IS CALLED");
    var final_amt=(is_subscribed===1) ? temp_elite_final_amount:temp_final_amount;
    if(is_checked==="1"){
        // console.log("IS CHECKED");
        
        // console.log("final_amt:",final_amt,", fitCastAvaiable:",fitCastAvaiable);

        if(Number(final_amt)<=Number(fitCastAvaiable)){
            // console.log("Less");
            setEliteFinalAmount(0.00);  
            setfitCastAvaiable(fitCastAvaiable-elite_final_amount);
            setFitBalance(elite_final_amount);
            setFinalAmount(0.00); 
        }else if(Number(final_amt)>Number(fitCastAvaiable)){
            setEliteFinalAmount(elite_final_amount-fitCastAvaiable);
            setFinalAmount(final_amt-fitCastAvaiable);
            setfitCastAvaiable(0.00);
            
            setFitBalance(fitCastAvaiable); 
           
        }

    }else{
        
        // console.log("Called IS UNCHECKED");
        // console.log("IS UNCHECKED");
        setEliteFinalAmount(temp_elite_final_amount);
        setFinalAmount(final_amt);
        setFitBalance(0); 
        setfitCastAvaiable(tempfitCastAvaiable);
    }
}


const orderRelatedData = async(id) => {
    try{
    let data = await OrderRelatedData(id);
    if(data.error === "0"){
        console.log(data,"orderess");
        setOrderID(data.order_id) ;
        setOrderIdEncoded(data.order_id_encode);
        setOrderNumber(data.order_number);
        console.log(data.order_id_encode,"orderess");
        window.location.href = `/order-details/${data.order_id_encode}`;
        fetchInfo();
    }else{
        // fetchInfo();  
    }
}catch (error){
    console.log(error,"order_data_after_add_order")
}
}


const handleRazorpayPayment = async (key,order_id,amount) => {   

    const options = {
      key: key, // Enter the Key ID generated from the Dashboard
      amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Nutsby",
      description: "Order Placing", 
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        console.log(response,"raxorpay successResponse");
        orderRelatedData(response.razorpay_order_id);
       
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: { 
        email: "",
        contact: mobilenumberinput,
        name:nameinput,
      }
    };
  
    const rzp1 = new Razorpay(options);
    
  
    rzp1.on("payment.failed", function (response) {
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
    });
  
    rzp1.open();







     
        // var options2 = {
        //   description: 'Credits towards consultation',
        //   image: 'https://i.imgur.com/3g7nmJC.png',
        //   currency: 'INR',
        //   key: '', // Your api key
        //   amount: '5000',
        //   name: 'foo',
        //   prefill: {
        //     email: 'void@razorpay.com',
        //     contact: '9191919191',
        //     name: 'Razorpay Software'
        //   },
        //   theme: {color: '#F37254'}
        // }
        // RazorpayCheckout.open(options).then((data) => {
        //   // handle success
        //   alert(`Success: ${data.razorpay_payment_id}`);
        // }).catch((error) => {
        //   // handle failure
        //   alert(`Error: ${error.code} | ${error.description}`);
        // });
      
  };


useEffect(() => {

        
    // window.bottombarshow = false;

    
    CalculateFitcash(); 
    const storedValue = localStorage.getItem('ElitePlanInCart');
        if (storedValue !== null) {
            setElitePlanInCart(Number(storedValue));
        }
  }, [is_checked]);


  useEffect(() => {
    setGlobalIsSubscribed(localStorage.getItem('is_subscribed'));
    console.log(gloabal_is_subscribed,"asdfhisaudfgiasfghisaufd");
    fetchInfo(); 
  }, []);
 
  
  const AddToCartCallElite=async (p_id,qnt)=> { 
    try{
    let addcartDatatemp = await AddToCart(p_id,qnt);
    if (addcartDatatemp.error === "0") {
        
        fetchInfo();  
    }
}catch(error){
    console.log(error,"add_elite")
}
  };

  const [totalamt_toPay,setTotalToPay] = useState('');
  const AddOrderFunctionCall=async ()=> {
    // AddOrder(couponCode,total,itemsTotal,paymentType,fpused,orderSource,freeItems,utm_source,utm_medium,utm_campaign) 
    var totalamt=(is_subscribed === 1 || ElitePlanInCart === 1) ? elite_final_amount: final_amount;
    console.log(totalamt,"total RaxorAmoutn");      
    try{
    let addOrderDatatemp = await AddOrder(couponid,totalamt,itemsTotal,"Online",fitbalance,"Website",free_products,"","","");
    if (addOrderDatatemp.error === "0") {
        // console.log(addOrderDatatemp,"add order");
        if(addOrderDatatemp.type==="Razorpay")
        {
            handleRazorpayPayment(addOrderDatatemp.razorpay_key,addOrderDatatemp.order_id,totalamt);
        }
    }
}catch(error){
    console.log(error,"add_order");
}
  };



  const EditContactAPIFunctionCall=async ()=> { 
    try{
    let EditContactAPIDatatemp = await EditContactAPIFunction(addressData.id,nameinput,mobilenumberinput);
    if (EditContactAPIDatatemp.error === "0") {
        toggleVisibility();
    }
}catch(error){
    console.log(error,"edit_contact")
}
  };

  const ClearUnavaialbeCartApiFunctionCall=async ()=> { 
    try{
    let addcartDatatemp = await ClearUnavaialbeCartApiFunction();
    if (addcartDatatemp.error === "0") {
        fetchInfo();  
    }
}catch (error){
    console.log(error,"clear_unavialable_cart")
}
  };
 
  const RemoveFromCartCall=async (c_id,clicked)=> { 

    if(clicked===true){
        setRemoveClicked(true);
    }
    // AddToCart 
  try{
            let addcartDatatemp = await RemoveItemFromCart(c_id);
            if (addcartDatatemp.error === "0") {  
                
                    
                
                
                    const newList = cartProducts.map((item) => {
                        if (item.cart_id === c_id) {
                          const updatedItem = {
                            ...item,
                            cart_quantity: 0,
                          };
                  
                          return updatedItem;
                        } 
                        return item;
                      });
                  
                      setcartProducts(newList);
                      setRemoveClicked(false);

                      setcartCount(addcartDatatemp.count);
                      
                      fetchInfo();
                  
                  
                // (e) =>setdetailsData((prevState) => ({ ...prevState, [e.target.name]: 1 }));
                // this.setState( detailsData.name="AKash");
            //   console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC")
            }
        }catch (error){
            console.log(error,"remove_cart_items")
        }
     
   
  };

  const handleCartButtonClick = () => {
    if (ElitePlanInCart === 0) {
        AddToCartCallElite(ElitePlanId, "1");
        setElitePlanInCart(1);
        localStorage.setItem('ElitePlanInCart', '1');
    } else {
        setElitePlanInCart(0);
        AddToCartCallElite(ElitePlanId, 0);
        localStorage.setItem('ElitePlanInCart', '0');
    }
};
const isLargeScreen = useMediaQuery('(min-width: 992px)'); 

const handleButtonClick = () => {
  if (isLargeScreen) {
   
    setshowLoginModal(true);
  } else {
    // Redirect to login page
    window.location.href = '/mobile-login';
  }
};

if(!shimmer_loading){
    return(
        <> 
         
        {/* <Container className='login-logo d-flex justify-content-center align-items-center' style={{width:"100%",height:"100%",marginTop:"150px"}}>
        <div className="modal show" style={{ display: 'block', position: 'fixed',marginTop:"7.7%",zIndex:"1"}}> */}
      {/* <Modal.Dialog style={{float:"right"}} >
        <Modal.Header closeButton>
            </Modal.Header>
        
        <Modal.Footer />
      </Modal.Dialog> */}
        {(cartProducts.length>0) ? 
        <Offcanvas.Body style={{padding:"5px"}}>  
          {/* <Col lg={12} md={12} sm={12} xs={12} xl={12}> */}
            <div className='Loginform' style={{backgroundColor:"white",height:"auto%",overflow: "auto"}}>
                <Container style={{display:"grid"}}>
                <Row >
                {< Col md = {
                        12
                    }
                    sm = {
                        12
                    }
                    lg = {
                        12
                    }
                    xl = {
                        12
                    }
                    xxl = {
                        12
                    } >
                       
                        {NostockcartProducts.length > 0
                            ? <Card
                                    className='coupon p-2 bg-white mt-20'
                                    style={{
                                      border: "1px solid #D9D9D9",
                                      borderRadius:"27px"
                                     }}>
                                    <Card.Header
                                        style={{
                                        backgroundColor: "transparent"
                                    }}>
                                        <div
                                            style={{
                                            position: 'absolute',
                                            top: '-2px',
                                            left: '-2px',
                                            backgroundColor:'#FFE2E2',
                                            padding: '5px',
                                            borderTopLeftRadius: '27px',
                                            borderBottomRightRadius: '27px'
                                        }}>
                                            <span
                                            className="free_item"
                                                style={{   
                                                    color:"#FF0000", 
                                                    fontSize:"15px",   
                                                    fontFamily:"Inter",                                    
                                                padding: "10px"
                                            }}>Unavailable item(s)</span>
                                        </div>
                                    </Card.Header>
                                      <Card.Body> 
                                            {NostockcartProducts.map((element, index) => (
                                                
                                            <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12} >
                                                
                                                    <Row className="justify-content-center align-items-center">    
                                                            <Col xs="auto">
                                                                <div className="img-fluid">
                                                                <Image src={element.image} layout="fill" quality={100}  style={{margin:"0px !important",padding:"0px",width:"50px",height:"50px"}} rounded />
                                                                </div>
                                                            </Col>

                                                            <Col>                          
                                                                <div style={{maxWidth: "100%",fontFamily:'Inter',fontFamily: "Inter", fontWeight: 400,fontSize:'14px'}} >
                                                                {element.short_name}
                                                                </div>                                       
                                                            </Col>
                                                    </Row>  
                                                    <Row>
                                                            <div style={{color:"#219EBC",fontSize:"18px",fontFamily:"Inter",marginBottom:"10px"}}>                        
                                                            You can buy instead
                                                            </div>
                                                    </Row>  
                                                    <Row style={{border:"1px solid #E3E4EA",borderRadius:"22px",padding:"8px"}}>
                                                        <CartItemCard removeCart={RemoveFromCartCall} 
                                                        cart_id={element.cart_id} 
                                                        removeSuggest={ClearUnavaialbeCartApiFunctionCall} 
                                                        uncheck={CalledChecked} 
                                                        
                                                        cartrefresh={fetchInfo} type="suggested" image={element.suggested_item.image} 
                                                        name={element.suggested_item.name} product_total={element.suggested_item.price} 
                                                        premium_price={element.suggested_item.premium_price} cart_quantity={element.suggested_item.cart_quantity} 
                                                        product_id={element.suggested_item.product_id} dis={element.suggested_item.discount_percentage} product_total_mrp={element.suggested_item.mrp}/>
                                                    </Row>                           
                                                </Col>                                     
                                            ))}
                                      </Card.Body>
                                </Card>
                            : <> </>}
                     </Col>}


                    <Col sm={12} md={12} style={{marginTop:"20px"}}>                    
                     <p><b>Items Added ({cartCount})</b></p>
                    {cartProducts.map((element) => {
                        if(cartProducts.length>0 && element.product_id!==ElitePlanId) {
                            
                            return (
                                <> 
                                {
                                (element.product_id===ElitePlanId)?
                                <></>:
                                <CartItemCard 
                                removeCart={RemoveFromCartCall} 
                                cart_id={element.cart_id} 
                                removeSuggest={ClearUnavaialbeCartApiFunctionCall} 
                                uncheck={CalledChecked} 
                                cartrefresh={fetchInfo} type="normal" 
                                image={element.image} name={element.name} 
                                product_total={element.product_total} 
                                premium_price={element.premium_price} 
                                cart_quantity={element.cart_quantity} 
                                product_id={element.product_id} 
                                dis={element.dis} product_total_mrp={element.product_total_mrp}
                                is_subscribed = {is_subscribed}
                                />}
                                {/* <Row>
                                <Col sm={3} md={3} xs={3} lg={3 } >
                                    <Image src={element.image} layout="fill" quality={100}  style={{margin:"0px !important",padding:"0px"}} rounded />
                                </Col>
                                <Col  sm={9} md={9} xs={9} lg={9}>
                                    <Row>
                                        <Col sm={11} md={11} xs={10} lg={11}>
                                            <p className="cart-item-name">{element.name}</p>
                                        </Col>
                                        <Col sm={1} md={1} xs={2} lg={1}> 
                                        <a onClick={() =>  RemoveFromCartCall(element.cart_id)} style={{float:"right"}}>
                                        
                                        <svg width="16" height="16" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.5 61C47.3447 61 61 47.3447 61 30.5C61 13.6553 47.3447 0 30.5 0C13.6553 0 0 13.6553 0 30.5C0 47.3447 13.6553 61 30.5 61Z" fill="#F4F5FA"/>
                                        <path d="M46.437 17.3957C46.437 16.8758 46.235 16.3871 45.8666 16.0202C45.1313 15.2849 43.8509 15.2849 43.1156 16.0202L30.8743 28.2689L18.633 16.0202C17.8978 15.2849 16.6173 15.2849 15.882 16.0202C15.5151 16.3871 15.3116 16.8758 15.3116 17.3957C15.3116 17.9156 15.5137 18.4043 15.882 18.7712L28.1233 31.0199L15.882 43.2686C15.5151 43.6355 15.3116 44.1242 15.3116 44.6441C15.3116 45.164 15.5137 45.6527 15.882 46.0196C16.6173 46.7549 17.8978 46.7549 18.633 46.0196L30.8743 33.7709L43.1156 46.0196C43.4825 46.388 43.9712 46.59 44.4911 46.59C45.011 46.59 45.4982 46.388 45.8666 46.0196C46.2335 45.6527 46.437 45.164 46.437 44.6441C46.437 44.1242 46.235 43.6355 45.8666 43.2686L33.6253 31.0199L45.8666 18.7712C46.2335 18.4043 46.437 17.9156 46.437 17.3957Z" fill="#6977C2" stroke="#6977C2" stroke-width="1.5"/>
                                        </svg>

                                            </a>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={7} xs={7} lg={7}>
                                            <p><b style={{fontSize:"18px"}}>{element.product_total}</b>&nbsp;<span style={{fontSize:"14px"}} className="mrp-strike">{element.product_total_mrp}</span></p>
                                        </Col>
                                        &nbsp;
                                        <Col sm={4} md={4} xs={4} lg={4} >
                                            <Container className="discount-tag" style={{height:"25px",width:"100%",float:"right"}}>
                                            
                                                <DiscountIcon></DiscountIcon>
    
                                                &nbsp;
                                                <span style={{fontSize:"10px"}}>{element.dis}% Off </span>
                                            </Container> 
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col sm={5} md={5} xs={6} lg={5}>
                                            <Container className="premimum-tag" ><EliteIcon/>&nbsp;<b className="premimum-tag-text" >{element.premium_price}</b></Container>
                                        </Col>
                                        <Col sm={7} md={7} xs={6} lg={7}> 
                                        <Container className="cart-inc-btn-radius" style={{width:"75%",float:"right"}}>
                                            <Row>
                                                <Col sm={4} md={4} xs={4} lg={4}>
                                                <Button className="light-greenes-blue cart-inc-btn " variant="transparent" onClick={() =>  AddToCartCall(element.product_id,(Number(element.cart_quantity)-1))}>-</Button>
                                                </Col>
                                                <Col sm={4} md={4} xs={4} lg={4}>
                                                <Button className="cart-inc-btn" variant="transparent">{element.cart_quantity}</Button>
                                                </Col>
                                                <Col sm={4} md={4} xs={4} lg={4}>
                                                <Button className="light-greenes-blue cart-inc-btn" variant="transparent" onClick={() =>  AddToCartCall(element.product_id,(Number(element.cart_quantity)+1))}>+</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                        
                                        </Col>
                                    </Row>
                                </Col>
                                </Row> */}
                        
                        
                            </>
                            )
                        }
                                            
                        
                    })}

                    {free_products.length>0 ? 
                    <Col md = {12} sm ={1} lg = {12} xl = {12} xxl = {12} >
                                        
                                <Card
                                        className='coupon p-2 bg-white mt-20'
                                        style={{
                                            border: "1px solid #85D2E2",
                                            borderRadius:"27px"
                                        }}>
                                        <Card.Header
                                            style={{
                                            backgroundColor: "transparent"
                                        }}>
                                            <div
                                                style={{
                                                position: 'absolute',
                                                top: '-2px',
                                                left: '-2px',
                                                backgroundColor:'#E3FAFF',
                                                padding: '5px',
                                                borderTopLeftRadius: '27px',
                                                borderBottomRightRadius: '27px'
                                            }}>
                                                <span
                                                className="free_item"
                                                    style={{
                                                    fontSize:"15px",
                                                    fontFamily:"Inter",
                                                    color:"#023047",
                                                    padding: "10px"
                                                }}>Free Item(s)</span>
                                            </div>
                                        </Card.Header>
                                            <Card.Body> 
                                            
                                                <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12} >
                                                <CartFreeCardItem image={require("../assets/images/RITEBITE.png")} name={"Nutsby Nutrieats Cashew Regular 200g | Heart Nutrieats"} price={"25"} qnty={2}/> 
                                                </Col>                             
                                            </Card.Body>
                                </Card>
                           
                    </Col> :""}
                    {(coupon_products.length>0) ? 
                    <Col md = {12} sm ={1} lg = {12} xl = {12} xxl = {12} style={{marginBottom:'20px'}}>
                                       
                                <Card
                                        className='coupon p-2 bg-white  mt-20'
                                        style={{
                                            border: "1px solid #353535",
                                            borderRadius:"27px"
                                        }}>
                                        <Card.Header
                                            style={{
                                            backgroundColor: "transparent"
                                        }}>
                                            <div
                                                style={{
                                                position: 'absolute',
                                                top: '-2px',
                                                left: '-2px',
                                                backgroundColor:'#FFB703',
                                                padding: '5px',
                                                borderTopLeftRadius: '27px',
                                                borderBottomRightRadius: '27px'
                                            }}>
                                                <span
                                                className="free_item"
                                                    style={{
                                                        fontSize:"15px",
                                                        fontFamily:"Inter",
                                                    padding: "10px"
                                                }}>Coupon Gifts</span>
                                            </div>
                                        </Card.Header>
                                            <Card.Body> 
                                            
                                                <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12} >
                                                {coupon_products.map((element, index) => (
                                                    <CartFreeCardItem  image={element.image} name={element.name} price={element.price} qnty={element.quantity}/>
                                                ))}
                                                
                                                </Col>                             
                                            </Card.Body>
                                </Card>
                           
                    </Col>:""}


                    {/* {(gloabal_is_subscribed === "1")?<></>:
                    <Col md = {12} sm ={1} lg = {12} xl = {12} xxl = {12} style={{marginBottom:'20px'}}>
                        <Row>               
                        <div className="d-flex justify-content-center mt-20">
                                        <div className="plancard2 elite-card">
                                        
                                        
                                            <div className="price-section d-flex justify-content-between" style={{marginTop:'40px',}}>
                                                        
                                                        <div className="d-grid justify-content-start">
                                                            <span  align="start" style={{color:"#FFFFFF",fontSize:"22px"}}>Buy at ₹199</span>
                                                            <div align="start">
                                                            <span style={{color:"#F0D8A9"}}>Get Benefits for 3 months </span>
                                                            
                                                            </div>                                             
                                                            
                                                            <span  align="start" style={{cursor:"pointer",color:"#FFFFFF"}} onClick={()=>gotToNewPagesss()}>Explore <NewExploreIcon /></span>
                                                        </div>
                                                        {ElitePlanInCart===0?
                                                        <CartButton onClick={handleCartButtonClick} type="button">
                                                                        <NewAddToCartIcon />
                                                                    
                                                            </CartButton>:
                                                            <CartButton2 onClick={handleCartButtonClick} type="button">
                                                            <div style={{marginLeft:"4px",fontFamily:"Inter"}}>Remove</div>
                                                            </CartButton2>}
                                            </div>

                                        
                                        </div>
                                    </div>
                        </Row> 
                    </Col>} */}
                    
 
                </Col>


                {((localStorage.getItem('is_guest') === "1")||(fitCastAvaiable==="0"))?<></>:
                

                (is_coupon_applied==="0")?
                (<Container className="px-3">
                    <Row style={{padding:"0px 10px"}}>
                        <Col sm={1} md={1} xs={1} lg={1}>
                            <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.9457 8.48482C18.882 8.64033 18.7732 8.77323 18.6334 8.86645C18.4935 8.95967 18.329 9.00895 18.161 9.00797H5.6509C5.47414 9.00782 5.30174 8.95313 5.15723 8.85136C5.01271 8.74959 4.90313 8.6057 4.84343 8.43933C4.78862 8.27378 4.78423 8.09567 4.8308 7.92762C4.87737 7.75956 4.9728 7.60912 5.105 7.49539L10.3365 3.13961C10.9348 2.63943 11.699 2.3818 12.4781 2.41759C13.2571 2.45339 13.9944 2.78001 14.5444 3.33295L14.6809 3.46942L18.7637 7.55225C18.8827 7.67237 18.9635 7.82503 18.9959 7.99099C19.0283 8.15694 19.0108 8.32878 18.9457 8.48482Z" fill="#FFBE40"/>
                                <path d="M14.7491 4.74317C14.7507 6.20508 14.4688 7.65341 13.9189 9.00797H5.6509C5.47414 9.00782 5.30174 8.95313 5.15723 8.85136C5.01271 8.74959 4.90313 8.6057 4.84343 8.43933C4.78862 8.27377 4.78423 8.09567 4.8308 7.92762C4.87737 7.75956 4.9728 7.60912 5.105 7.49539L10.3365 3.13961C10.9348 2.63943 11.699 2.3818 12.4781 2.41759C13.2571 2.45339 13.9944 2.78001 14.5444 3.33295L14.6809 3.46942C14.7266 3.89246 14.7494 4.31767 14.7491 4.74317Z" fill="#FFDF40"/>
                                <path d="M19.2984 7.30225H3.37654C1.64926 7.30225 0.249023 8.70248 0.249023 10.4298V22.9398C0.249023 24.6671 1.64926 26.0673 3.37654 26.0673H19.2984C21.0257 26.0673 22.426 24.6671 22.426 22.9398V10.4298C22.426 8.70248 21.0257 7.30225 19.2984 7.30225Z" fill="#59ABFF"/>
                                <path d="M14.4536 7.30225C13.8752 9.80709 12.4652 12.0421 10.4536 13.6427C8.44191 15.2434 5.9473 16.1152 3.37654 16.1162C2.31807 16.1204 1.26459 15.971 0.249024 15.6726V10.4298C0.248725 10.019 0.329417 9.61214 0.486483 9.23256C0.643549 8.85298 0.873909 8.50808 1.16439 8.21761C1.45486 7.92713 1.79975 7.69677 2.17934 7.53971C2.55892 7.38264 2.96574 7.30195 3.37654 7.30225H14.4536Z" fill="#7BC7FF"/>
                                <path d="M24.7005 14.9788V18.3906C24.6981 18.9177 24.4876 19.4225 24.1149 19.7953C23.7422 20.168 23.2374 20.3784 22.7102 20.3808H18.1611C17.634 20.3784 17.1292 20.168 16.7565 19.7953C16.3837 19.4225 16.1733 18.9177 16.1709 18.3906V14.9788C16.1726 14.7056 16.2299 14.4356 16.3394 14.1853C16.4489 13.935 16.6083 13.7097 16.8078 13.523C17.1746 13.1795 17.6585 12.9884 18.1611 12.9885H22.7102C23.2374 12.9909 23.7422 13.2014 24.1149 13.5741C24.4876 13.9468 24.6981 14.4517 24.7005 14.9788Z" fill="#443D66"/>
                                <path d="M24.7003 14.9788V18.2996C23.1235 18.1009 21.606 17.573 20.2463 16.7502C18.8866 15.9273 17.7151 14.8278 16.8076 13.523C17.1745 13.1795 17.6583 12.9884 18.161 12.9885H22.7101C23.2372 12.9909 23.742 13.2014 24.1148 13.5741C24.4875 13.9468 24.6979 14.4517 24.7003 14.9788Z" fill="#5C5C8E"/>
                            </svg>
                         </Col>
                        <Col sm={9} md={9} xs={9} lg={9}>
                        Redeem Fit Cash (₹{fitCastAvaiable})</Col>
                        <Col sm={2} md={2} xs={2} lg={2} className="text-end">
                            <input  type="checkbox"
                             onChange={(e) => {
                                if (e.target.checked) {
                                    
                                    setIsChecked("1");
                                 //   is_checked="1";
                                 
                                }else{
                                    setIsChecked("0");
                                   // is_checked="0";
                                } 
                              }}
                            className="custom-radio" />     
                            </Col>
                    </Row>
                    <Row className="mt-10 mx-3">
                            <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                        </Row> 
                </Container>):<></>}
                

                
                {(localStorage.getItem('is_guest') === "1")?<></>:

(is_checked==="0"?
                (<Container className="px-3" style={{cursor:"pointer"}}>
                    <Row className="coupon-row-class" onClick={handlecouponShow}>
                        <Col sm={1} md={1} xs={1} lg={1}>
                        <svg width="25" height="24" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M33.1583 14.4176C33.4792 14.4176 33.6932 14.2139 33.6932 13.9082V8.55946C33.6932 7.64253 32.8909 6.87842 31.9816 6.87842H3.31219C2.34941 6.87842 1.60059 7.64253 1.60059 8.55946V13.9082C1.60059 14.2139 1.81454 14.4176 2.13546 14.4176C3.63312 14.4176 4.80984 15.5893 4.80984 17.0156C4.80984 18.4419 3.57963 19.6136 2.13546 19.6136C1.81454 19.6136 1.60059 19.8173 1.60059 20.123V25.4717C1.60059 26.3887 2.4029 27.1528 3.31219 27.1528H31.9281C32.8909 27.1528 33.6397 26.3887 33.6397 25.4717V20.123C33.6397 19.8173 33.4257 19.6136 33.1048 19.6136C31.6072 19.6136 30.4304 18.4419 30.4304 17.0156C30.4839 15.5893 31.6606 14.4176 33.1583 14.4176ZM29.4142 17.0156C29.4142 18.8495 30.8048 20.3267 32.6234 20.5814V25.4717C32.6234 25.8283 32.3025 26.134 31.9816 26.134H27.1677V25.3699C27.1677 25.0642 26.9537 24.8605 26.6328 24.8605C26.3119 24.8605 26.0979 25.0642 26.0979 25.3699V26.134H3.31219C2.93778 26.134 2.67034 25.8283 2.67034 25.4717V20.5814C4.48892 20.3267 5.8796 18.7985 5.8796 17.0156C5.8796 15.1817 4.48892 13.7045 2.67034 13.4498V8.55946C2.67034 8.20287 2.99126 7.89723 3.31219 7.89723H26.0444V8.66134C26.0444 8.96698 26.2584 9.17075 26.5793 9.17075C26.9002 9.17075 27.1142 8.96698 27.1142 8.66134V7.89723H31.9281C32.3025 7.89723 32.5699 8.20287 32.5699 8.55946V13.4498C30.8048 13.7045 29.4142 15.1817 29.4142 17.0156Z" fill="#219EBC"/>
                            <path d="M26.6335 15.3345C26.3126 15.3345 26.0986 15.5382 26.0986 15.8439V18.2381C26.0986 18.5437 26.3126 18.7475 26.6335 18.7475C26.9544 18.7475 27.1684 18.5437 27.1684 18.2381V15.8439C27.1684 15.5382 26.9009 15.3345 26.6335 15.3345Z" fill="#219EBC"/>
                            <path d="M26.6335 20.072C26.3126 20.072 26.0986 20.2758 26.0986 20.5814V22.9756C26.0986 23.2813 26.3126 23.485 26.6335 23.485C26.9544 23.485 27.1684 23.2813 27.1684 22.9756V20.5814C27.1684 20.3267 26.9009 20.072 26.6335 20.072Z" fill="#219EBC"/>
                            <path d="M26.6335 10.5461C26.3126 10.5461 26.0986 10.7499 26.0986 11.0555V13.4498C26.0986 13.7554 26.3126 13.9592 26.6335 13.9592C26.9544 13.9592 27.1684 13.7554 27.1684 13.4498V11.0555C27.1684 10.7499 26.9009 10.5461 26.6335 10.5461Z" fill="#219EBC"/>
                            <path d="M14.9184 13.7044C14.9184 12.4309 13.8486 11.4121 12.5114 11.4121C11.1742 11.4121 10.1045 12.4309 10.1045 13.7044C10.1045 14.978 11.1742 15.9968 12.5114 15.9968C13.8486 15.9968 14.9184 14.927 14.9184 13.7044ZM11.1742 13.7044C11.1742 12.9913 11.7626 12.4309 12.5114 12.4309C13.2603 12.4309 13.8486 12.9913 13.8486 13.7044C13.8486 14.4176 13.2603 14.978 12.5114 14.978C11.7626 14.978 11.1742 14.3667 11.1742 13.7044Z" fill="#219EBC"/>
                            <path d="M19.4646 18.0344C18.1274 18.0344 17.0576 19.0532 17.0576 20.3268C17.0576 21.6003 18.1274 22.6191 19.4646 22.6191C20.8018 22.6191 21.8715 21.6003 21.8715 20.3268C21.8715 19.0532 20.8018 18.0344 19.4646 18.0344ZM19.4646 21.6003C18.7157 21.6003 18.1274 21.0399 18.1274 20.3268C18.1274 19.6136 18.7157 19.0532 19.4646 19.0532C20.2134 19.0532 20.8018 19.6136 20.8018 20.3268C20.8018 21.0399 20.2134 21.6003 19.4646 21.6003Z" fill="#219EBC"/>
                            <path d="M20.6964 12.5327C20.4824 12.3289 20.1615 12.3289 19.9475 12.5327L11.2825 20.7851C11.0686 20.9888 11.0686 21.2945 11.2825 21.4983C11.3895 21.6001 11.55 21.6511 11.6569 21.6511C11.7639 21.6511 11.9244 21.6001 12.0314 21.4983L20.6964 13.2459C20.9103 13.0421 20.9103 12.7365 20.6964 12.5327Z" fill="#219EBC"/>
                            </svg>
                            {/* <CouponIcon style={{height:"25px",width:"25px"}} /> */}

                         </Col> 
                        <Col sm={9} md={9} xs={9} lg={9}>Coupons and Deals</Col>
                        <Col sm={2} md={2} xs={2} lg={2} className="text-end">
                            <svg width="11" height="13" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.862744 17.7426C1.18104 18.0609 1.69711 18.0609 2.0154 17.7426L10.1659 9.59213C10.4842 9.27383 10.4842 8.75785 10.1659 8.43955L2.0154 0.289013C1.6971 -0.0292804 1.18104 -0.0292804 0.862743 0.289013C0.54445 0.607306 0.54445 1.12337 0.862743 1.44167L8.43687 9.01584L0.862744 16.59C0.544451 16.9083 0.544451 17.4243 0.862744 17.7426Z" fill="black"/>
                            </svg>
                        </Col>
                    </Row>
                    {is_coupon_applied=="1"?( <Row>

                    <div className="coupon-container" style={{borderRadius:"42px",width:'auto',marginLeft:"11%",marginTop:"5px"}}>
                    
                    <span style={{fontSize:"15px",fontFamily:"Inter",marginRight:"10px"}}>{couponid}</span>
                         <span onClick={()=> RemoveCouponApiFunction()}>                                                   
                     <CouponRemove />                                          
                     </span>
                    </div>
                    </Row>):(<></>)}
                   
                    <Row className="mt-10 mx-3">
                            <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                        </Row> 
                </Container>):<></>)
                }
                 

                 <Offcanvas show={couponshow} onHide={handlecouponClose} placement='end' scroll='true'style={{width: "30rem",zIndex:"10001"}}>
                    <Offcanvas.Header >
                    <Button variant="link" onClick={handlecouponClose} style={{ textDecoration: 'none' }}>
            <FaArrowLeft size={24} color="black"/> {/* Customize the arrow size and style as needed */}
          </Button>
                        <Offcanvas.Title style={{}}>Coupons & Deals</Offcanvas.Title>
                        
                    </Offcanvas.Header>
                    <Offcanvas.Body>                  
                    <CouponsList order_amount={cartCalculationData.total_price2} onSelectCoupon={ApplyCouponApiFunction}> </CouponsList>
                    </Offcanvas.Body>
                 </Offcanvas>

                 {
                 (localStorage.getItem('is_guest') === "1")?<></>:
                         
                 (addressData!=null)?
                    <Container>                 
                        <Container onClick={toggleVisibility} className="px-3" style={{cursor:"pointer"}}>
                                    <Row>
                                        <Col sm={1} md={1} xs={1} lg={1} style={{paddingTop:"10px"}}>
                                        <PersonIcon style={{
                                            width: "25px",
                                            height: "25px"
                                        }}/>
                                        </Col> 
                                        <Col sm={9} md={9} xs={9} lg={9}>
                                            <p className="mb-0">{nameinput}</p>
                                            <p className="gray-text mb-0">{mobilenumberinput}</p>
                                        </Col>
                                        <Col sm={2} md={2} xs={2} lg={2} className="text-end">
                                            {isVisible ? 
                                            <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.914 1.43557C21.7909 0.869291 21.4544 0.487669 20.8799 0.327634C20.2521 0.151186 19.731 0.344048 19.2878 0.791325C17.5684 2.51478 15.8532 4.24233 14.1379 5.96989C13.2762 6.83161 12.4063 7.68924 11.4543 8.63714L3.60027 0.783118C2.94372 0.126565 2.00813 0.0896338 1.40082 0.668221C0.793505 1.24681 0.785298 2.22754 1.42544 2.87178C4.38813 5.85089 7.36314 8.82589 10.3422 11.7886C10.9906 12.4328 11.9016 12.4492 12.5376 11.8132C15.5331 8.83821 18.5163 5.85499 21.4913 2.86357C21.8812 2.46964 22.0371 1.98543 21.914 1.43557Z" fill="black"/>
                                            </svg>
                                            :
                                            <svg width="11" height="13" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.862744 17.7426C1.18104 18.0609 1.69711 18.0609 2.0154 17.7426L10.1659 9.59213C10.4842 9.27383 10.4842 8.75785 10.1659 8.43955L2.0154 0.289013C1.6971 -0.0292804 1.18104 -0.0292804 0.862743 0.289013C0.54445 0.607306 0.54445 1.12337 0.862743 1.44167L8.43687 9.01584L0.862744 16.59C0.544451 16.9083 0.544451 17.4243 0.862744 17.7426Z" fill="black"/>
                                            </svg>}
                                        </Col>
                                    </Row>
                                    

                                    <Row className="mt-10 mx-3">
                                            <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                                        </Row> 

                                        
                        </Container>  
                        <Container>
                        <Row className="mt-10 mx-3"></Row>
                                    {isVisible ? 
                                        <div className="text-center">
                                        {/* Add your input fields here */}
                                        <div className="input-group input-group-md mb-2"> 
                                                <Form.Control type="text" className="form-control" value={nameinput} onChange={handleChange} placeholder="Name" style={{borderRadius:"20px"}}/> 
                                            </div>
                                            <div className="input-group input-group-md mb-3"> 
                                                <Form.Control type="number" className="form-control" 
                                                inputMode="tel"
                                                pattern={'[6-9]{1}[0-9]{9}'}
                                                maxLength={10}
                                                onChange={handlesetmobilenumberinputChange} 
                                                value={mobilenumberinput} 
                                                
                                                
                                                
                                                placeholder="Contact Number" style={{borderRadius:"20px"}}/> 
                                            </div> 
                                            <button type="button" onClick={()=>{EditContactAPIFunctionCall()}} class="cart-pay-btn btn btn-outline-info my-2 text-bold" style={{width:"50%",border:"#FFB703 1px solid"}}>Update</button>
                                        </div>:<></>
                                    }
                        </Container>                                    
                        <Container onClick={handleaddressshowShow} className="px-3" style={{cursor:"pointer"}}>
                                <Row>
                                    <Col sm={1} md={1} xs={1} lg={1}>
                                    {(addressData.type==="Home") ? (
                                        <div>
                                        {/* Home Icon */}
                                        <HomeIcon style={{width:"25px",height:"25px"}}/>
                                        {/* Home Icon */}
                                        </div>
                                    ):((addressData.type==="Work") ? (<div>
                                        {/* Work Icon */}
                                        <WorkIcon style={{width:"25px",height:"25px"}}/>

                                        {/* Work Icon */}
                                    </div>):(<div>
                                            {/* Others Icon */} 
                                            <OtherIcon style={{width:"25px",height:"25px"}}/>
                                        {/* Others Icon */}
                                    </div>))} 
                                    </Col> 
                                    <Col sm={9} md={9} xs={9} lg={9}>
                                        <p className="mb-0">{addressData.type}</p>
                                        <p className="gray-text mb-0 cart-address-oneline">
                {addressData.complete_address && addressData.complete_address.trim() !== ""
                    ? addressData.complete_address
                    : "Please add your address"}
            </p>
                                    </Col>
                                    <Col sm={2} md={2} xs={2} lg={2} className="text-end">
                                        <svg width="11" height="13" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.862744 17.7426C1.18104 18.0609 1.69711 18.0609 2.0154 17.7426L10.1659 9.59213C10.4842 9.27383 10.4842 8.75785 10.1659 8.43955L2.0154 0.289013C1.6971 -0.0292804 1.18104 -0.0292804 0.862743 0.289013C0.54445 0.607306 0.54445 1.12337 0.862743 1.44167L8.43687 9.01584L0.862744 16.59C0.544451 16.9083 0.544451 17.4243 0.862744 17.7426Z" fill="black"/>
                                        </svg>
                                    </Col>
                                </Row>
                                <Row className="mt-10 mx-3">
                                        <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                                    </Row> 
                        </Container>
                    </Container>
                    :<></>
                    
                    }
                 
                    {/* Address Module/ */}
                    <Offcanvas show={addressshow} onHide={handleaddressshowClose} placement='end' scroll='true'style={{width: "30rem",zIndex:"10001"}}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title style={{}}>Select Address</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>                  
                            <Addressbook onAddressSelect={handleAddressSelect}/>
                    </Offcanvas.Body>
                    </Offcanvas>
                  {/* Address Module/ */}
                
                {/* Cart Calculation Details  */}
                <Container className="px-3" style={{fontWeight:"500"}}>
                    {/* Price total */}
                    <Row>
                        <Col sm={6} md={6} xs={6} lg={6} >
                        <p>Price ({cartCount} {(cartCount>1) ? `items`:`item`})</p>
                        </Col>
                        <Col sm={6} md={6}xs={6} lg={6} >
                        <p className="text-end">{itemsTotal}</p>
                        </Col>
                    </Row>
                    {/* Price total */}

                    {/* Shipping */}
                    <Row>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p>Shipping</p>
                        </Col>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p className="text-end">{(deliveryAmount===0) ?"Free":`${cartDataTotal.delivery_amount}`}</p>
                        </Col>
                    </Row>
                    {/* Shipping */}


                    
                    {/* Coupon Discount */}
                    {(Number(((couponAmount  ?? "0").replace("₹","")).replace(".",""))>0) ? <Row>
                        <Col sm={6} md={6} xs={6} lg={6} >
                        <p>Coupon Discount</p>
                        </Col>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p className="text-end">{(Number(((couponAmount ?? "0").replace("₹","")).replace(".",""))<1) ? `-₹ 0`:`-₹${couponAmount}`}</p>
                        </Col>
                    </Row>:""
                    } 
                    {/* Coupon Discount */}

                    {/* Fit Cash Discounut */}
                    {fitbalance!=0?( <Row>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p>Fit Cash Discount</p>
                        </Col>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p className="text-end">{`-₹ ${fitbalance}`}</p>
                        </Col>
                    </Row>):(<></>)}
                   
                    {/* Fit Cash Discounut */}

                     {/* Elite Membership discount */}
                     {(Number(is_subscribed)===1) ? 
                     <Row>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p>Elite Membership discount</p>
                        </Col>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p className="text-end">{(Number(((ElitSaved  ?? "0").replace("₹","")).replace(".",""))<1) ? `₹ 0`:`-₹ `+ElitSaved}</p>
                        </Col>
                    </Row>:""}
                    {/* Elite Membership discount */}
                    
                    <Row className="mt-10">
                        <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                    </Row> 
                    {/* Grand total */}
                    <Row>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p>Total</p>
                        </Col>
                        <Col sm={6} md={6} xs={6} lg={6}>
                        <p className="text-end">₹
                          {is_subscribed === 1 ? ` ${elite_final_amount}` : `${final_amount}`}
                            </p>
                        </Col>
                    </Row>
                    {/* Grand total */}
                </Container>
                {/* Cart Calculation Details  */}

                </Row>
                </Container> 
            </div>

            {
            
            (localStorage.getItem('is_guest') === "1")?<Container>
            <Row>
            <Col sm={4} md={3} xs={4} lg={4}>
                    <p className="light-greenes-blue" style={{marginBottom: "0rem",marginTop:"0px"}}>Total</p>
                    <p style={{marginBottom: "0rem"}}><b style={{marginBottom: "0rem",marginTop:"0px"}}>₹ {is_subscribed === 1 || ElitePlanInCart === 1 ? ` ${elite_final_amount}` : `${final_amount}`}</b></p>
            </Col>
            <Col sm={8} md={9} xs={8} lg={8} style={{float: "right"}}>
                <Button style={{marginBottom: "0rem",marginTop:"0px",float: "right",fontSize:"smaller"}} className="cart-pay-btn" variant="transparent" onClick={handleButtonClick} ><span style={{fontSize:"medium"}}>Login to Continue </span>
                </Button>
                </Col>
            </Row>
        </Container>:
            
            ((addressData1!=null)?
            
            <Container style={{marginTop:"0.5rem"}}>
                <Row>
                <Col sm={6} md={6} xs={6} lg={6}>
                        <p className="light-greenes-blue" style={{marginBottom: "0rem",marginTop:"0px"}}>Total</p>
                   <p style={{ marginBottom: "0rem" }}>
  <b style={{ marginBottom: "0rem", marginTop: "0px" }}>
    ₹ {
      (is_subscribed === 1 || ElitePlanInCart === 1) && localStorage.getItem('CouponId') !== ""
        ? finalelitecouponamount 
        : is_subscribed === 1 || ElitePlanInCart === 1
          ? elite_final_amount 
          : final_amount
    }
  </b>
</p>

                </Col>
                <Col sm={6} md={6} xs={6} lg={6} style={{float: "right"}}>
                    <Button style={{marginBottom: "0rem",marginTop:"0px",float: "right"}} className="cart-pay-btn" variant="transparent" onClick={()=>{AddOrderFunctionCall()}} ><span style={{marginright:"10px"}}>Continue</span>
                    </Button>
                    </Col>
                </Row>
            </Container>:<Container>
                <Row>
                <Col sm={4} md={4} xs={4} lg={4}>
                        <p className="light-greenes-blue" style={{marginBottom: "0rem",marginTop:"0px"}}>Total</p>
                        <p style={{marginBottom: "0rem"}}><b style={{marginBottom: "0rem",marginTop:"0px"}}>₹ {
      is_subscribed === 1 || ElitePlanInCart === 1 
        ? elite_final_amount
        : (is_subscribed === 1 || ElitePlanInCart === 1) && localStorage.getItem('CouponId') != ""
          ? finalelitecouponamount
          : final_amount
    }</b></p>
                </Col>
                <Col sm={8} md={8} xs={8} lg={8} style={{float: "right"}}>
                    <Button style={{marginBottom: "0rem",marginTop:"0px",float: "right",width:"100%",padding:"5px",backgroundColor: "#FFB703",borderRadius:"25px",color:"black"}} className="cart-pay-btn1" variant="transparent" onClick={handleaddressshowShow} ><span style={{marginright:"10px",fontSize:"medium"}}>Add address to continue</span>
                    </Button>
                    </Col>
                </Row>
            </Container>)
            }
            
            
            {/* </Col> */}
            {/* <Col lg={6} md={6} className="">
                <div className='imageContainer d-none d-lg-block' style={{width:"100%",overflow:"hidden",borderRadius:"0px 27px 27px 0px"}}>
                <img src={require("../assets/images/LoginGroup.png")} alt="" />
                </div>
            </Col> */}
          
        </Offcanvas.Body>:
        <Container style={{height:"100%",placeContent:"center"}}>
        {/* <Loader type="box-rectangular" bgColor={"#009FC6"} size={100} /> */}
        <div className="text-center mt-10">
                <svg xmlns="http://www.w3.org/2000/svg" width="222" height="139" viewBox="0 0 222 139" fill="none">
                    <g clip-path="url(#clip0_461_26208)">
                    <g clip-path="url(#clip1_461_26208)">
                    <path d="M-98.8203 123.058C-62.8591 111.961 0.0716855 103.544 36.6589 112.227C67.5907 119.784 88.5855 125.312 121.065 120.46C129.694 119.135 142.445 116.443 142.473 105.545C123.212 113.95 91.6265 111.856 71.5788 106.58C55.2534 101.264 39.9934 80.53 35.5917 64.4883C32.0042 49.123 44.2751 43.5681 57.1201 40.6521C81.4624 35.4687 149.303 34.7794 170.07 47.4135C188.718 58.7616 137.512 69.3668 130.922 70.4016C106.233 74.2729 78.2479 73.6096 54.1586 66.7297C45.9018 64.198 36.2453 59.0406 46.1953 50.5551C55.7464 43.0913 76.8612 43.5681 89.1191 43.2373C93.3732 43.0913 113.821 42.0177 115.889 44.9208C119.063 49.4019 111.261 62.6719 112.527 64.4883C113.967 66.5302 123.744 64.4624 126.185 62.1026C131.161 57.2906 133.334 44.4975 135.202 43.7417C139.31 42.0971 164.413 47.8109 168.468 50.5697C175.524 55.3687 173.51 61.4928 171.029 68.6922C166.708 80.8495 161.092 89.5053 151.234 97.8983C149.687 99.2104 148.54 100.325 147.18 101.849C156.463 105.575 184.208 100.842 193.997 97.1685C211.285 90.6584 215.432 80.1862 227.597 68.3872C237.241 59.0406 250.979 57.5825 263.811 56.6289C276.736 55.6752 294.117 55.7012 306.028 61.0841" stroke="#023047" stroke-width="1.13529" stroke-miterlimit="10"/>
                    </g>
                    </g>
                    <defs>
                    <clipPath id="clip0_461_26208">
                    <rect width="220.232" height="138.585" fill="white" transform="translate(0.922852 0.0566406)"/>
                    </clipPath>
                    <clipPath id="clip1_461_26208">
                    <rect width="405.25" height="87.0185" fill="white" transform="translate(-98.9873 36.583)"/>
                    </clipPath>
                    </defs>
                </svg>
                <p style={{marginTop:"10px",color:"#BABECF",fontSize:"14px",fontWeight:"600",fontFamily:"Inter"}}>Looks like<br/>you’ve not added anything <br/>to your cart</p>
                <Button variant="primary" onClick={() => window.location.href = '/'} style={{ borderColor: "#FFB703", backgroundColor: "#FFB703", color: "#023047", borderRadius: "25px",fontFamily:"Inter",fontWeight:"500",fontSize:"14px"}}>
                Browse Products
                </Button>
              </div>
        </Container>
        }
    {/* </div>
        </Container> */}
          <Modal show={showLoginModal} onHide={handleMapClose} size="lg" >
                <Modal.Body style={{padding:0}} > 
                {/* <div className="text-light text-end" style={{marginTop:"25px",marginRight:"30px",cursor:"pointer"}} onClick={} ><svg width="25" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18.7227" cy="18.6152" r="18.1602" fill="#E8ECFF"/>
        <path d="M18.7243 20.414L12.8162 26.3257C12.3304 26.8118 11.4984 26.8118 11.0125 26.3258C11.0124 26.3257 11.0124 26.3257 11.0123 26.3257M18.7243 20.414L11.2264 24.7352C11.0426 24.9189 10.9414 25.1634 10.9414 25.4235C10.9414 25.6834 11.0426 25.928 11.2264 26.1117L11.0123 26.3257M18.7243 20.414L24.6325 26.3257L24.6325 26.3257C24.873 26.5663 25.1942 26.6996 25.5344 26.6996C25.8746 26.6996 26.1957 26.5664 26.4362 26.3258L18.7243 20.414ZM11.0123 26.3257C10.7717 26.0851 10.6387 25.7636 10.6387 25.4235C10.6387 25.0833 10.7717 24.7618 11.0123 24.5212M11.0123 26.3257L11.0123 24.5212M11.0123 24.5212C11.0124 24.5212 11.0124 24.5211 11.0125 24.5211L16.9207 18.6093L11.0125 12.6976C11.0125 12.6976 11.0124 12.6975 11.0124 12.6975C11.0124 12.6975 11.0124 12.6975 11.0123 12.6974C10.7717 12.4569 10.6387 12.1353 10.6387 11.7952C10.6387 11.4551 10.7717 11.1336 11.0123 10.893C11.0124 10.893 11.0124 10.893 11.0124 10.8929C11.0124 10.8929 11.0125 10.8929 11.0125 10.8929C11.4984 10.4068 12.3304 10.4069 12.8162 10.893L18.7243 16.8047L24.6325 10.893C25.1183 10.4069 25.9502 10.4068 26.4362 10.8929C26.4362 10.8929 26.4363 10.893 26.4363 10.893L26.2222 11.107C26.4061 11.2907 26.5073 11.5352 26.5073 11.7952C26.5073 12.0552 26.4061 12.2998 26.2222 12.4835L11.0123 24.5212ZM26.4362 12.6976L20.5279 18.6093L26.4362 24.5211C26.4362 24.5211 26.4362 24.5211 26.4362 24.5212L26.4363 24.5212L26.2222 24.7352C26.4061 24.9189 26.5073 25.1634 26.5073 25.4235C26.5073 25.6834 26.4061 25.928 26.2222 26.1117L26.4362 12.6976ZM26.4362 12.6976C26.4362 12.6975 26.4363 12.6975 26.4363 12.6974L26.4362 12.6976Z" fill="#6977C2" stroke="#6977C2" stroke-width="0.605339"/>
      </svg> </div> */}
      {/* <Row>
        <Col></Col>
        <Col>
         <div className="text-light text-end" style={{marginTop:"25px",marginRight:"30px",cursor:"pointer"}}   ><svg width="25" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18.7227" cy="18.6152" r="18.1602" fill="#E8ECFF"/>
          <path d="M18.7243 20.414L12.8162 26.3257C12.3304 26.8118 11.4984 26.8118 11.0125 26.3258C11.0124 26.3257 11.0124 26.3257 11.0123 26.3257M18.7243 20.414L11.2264 24.7352C11.0426 24.9189 10.9414 25.1634 10.9414 25.4235C10.9414 25.6834 11.0426 25.928 11.2264 26.1117L11.0123 26.3257M18.7243 20.414L24.6325 26.3257L24.6325 26.3257C24.873 26.5663 25.1942 26.6996 25.5344 26.6996C25.8746 26.6996 26.1957 26.5664 26.4362 26.3258L18.7243 20.414ZM11.0123 26.3257C10.7717 26.0851 10.6387 25.7636 10.6387 25.4235C10.6387 25.0833 10.7717 24.7618 11.0123 24.5212M11.0123 26.3257L11.0123 24.5212M11.0123 24.5212C11.0124 24.5212 11.0124 24.5211 11.0125 24.5211L16.9207 18.6093L11.0125 12.6976C11.0125 12.6976 11.0124 12.6975 11.0124 12.6975C11.0124 12.6975 11.0124 12.6975 11.0123 12.6974C10.7717 12.4569 10.6387 12.1353 10.6387 11.7952C10.6387 11.4551 10.7717 11.1336 11.0123 10.893C11.0124 10.893 11.0124 10.893 11.0124 10.8929C11.0124 10.8929 11.0125 10.8929 11.0125 10.8929C11.4984 10.4068 12.3304 10.4069 12.8162 10.893L18.7243 16.8047L24.6325 10.893C25.1183 10.4069 25.9502 10.4068 26.4362 10.8929C26.4362 10.8929 26.4363 10.893 26.4363 10.893L26.2222 11.107C26.4061 11.2907 26.5073 11.5352 26.5073 11.7952C26.5073 12.0552 26.4061 12.2998 26.2222 12.4835L11.0123 24.5212ZM26.4362 12.6976L20.5279 18.6093L26.4362 24.5211C26.4362 24.5211 26.4362 24.5211 26.4362 24.5212L26.4363 24.5212L26.2222 24.7352C26.4061 24.9189 26.5073 25.1634 26.5073 25.4235C26.5073 25.6834 26.4061 25.928 26.2222 26.1117L26.4362 12.6976ZM26.4362 12.6976C26.4362 12.6975 26.4363 12.6975 26.4363 12.6974L26.4362 12.6976Z" fill="#6977C2" stroke="#6977C2" stroke-width="0.605339"/>
        </svg>
        </div>
        </Col>
      </Row> */}
                        <Login clss={handleMapClose} /> 
                </Modal.Body>
            </Modal>
        
        </>

    );
}else{
    return (<Container className="mt-20" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
            
        
        <Row>
        {shimmeritems.map((_, index) => (
          <Col className="" xs={12} sm={12} md={12} lg={12}>
          <ShimmerDiv className="cartshimmer" mode="light" height={100} width={100} />
          <Row className="mt-20"/>
          
          </Col>
        ))}
        
        </Row>      
         
        
    </Container>);
}
}
