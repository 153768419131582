 
import { Container, Row, Col, Button, Breadcrumb } from 'react-bootstrap';
import { NavLink,Link, useParams } from 'react-router-dom';
// import Overlay from 'react-bootstrap/Overlay';
import React,{ useState,useEffect } from 'react';
import { nutrieatsDataApi,AddToCart, NotifyMeApi, GetHeaderDetailsApi } from '../Service/ApiCalling';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; 
import SubscribeForm from "../MainComponents/SubscribeForm";
import DownloadTheApp from "../MainComponents/DownloadTheApp";
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import '../fonts/Inter-Regular.ttf'; 
import { DiscountIcon, EliteIcon } from "../MiniComponents/SvgIcons";
import ProductItemCard from '../MainComponents/SubComponents/MiniComponents/ProductItemCard';
import { useCart } from '../MainComponents/SubComponents/MiniComponents/CartContext';
import Data from '../assets/lottie/data.json';
import "../SubCss/goals.css";
import "../SubCss/nutrieats.css";
import Slider from 'react-slick';
import { ShimmerDiv,ShimmerContentBlock, ShimmerTitle,ShimmerButton } from "shimmer-effects-react";
import MetaTags from '../MainComponents/Metatags';
import { ToastContainer, toast } from 'react-toastify';
import BottomBar from "./../MainComponents/SubComponents/Bottom";
export default function NutriEatsPage() {
  const shimmeritems = Array.from({ length: 6 });
  const shimmeritems1 = Array.from({ length: 2 });
  const ess_shimmeritems = Array.from({ length: 4 })
  const ess_shimmeritems1 = Array.from({ length: 3 })
  const main_shimmeritems = Array.from({ length: 2 });
    const [essential,setessential] =useState([]);
    const [must_buy,setmustbuy] =useState([]);
    const [sliders, setsliders] = useState([]);
    const [top_grossing, settop_grossing] = useState([]);
    const [is_subscribed,setIsSubscribed] = useState('');
    const [main_goal,setmaingoal] =useState([])
    const [sub_goal,setsubgoal] =useState([])
    const { setCartCount } = useCart();
    const [addClicked,setAddClicked] = useState(null);
    const [notifyClicked, setNotifyClicked] = useState(null);
    const [productsLoading,setLoading] = useState(true);

    const params = useParams();
    const [keyWordTag, setseKeywordtag] = useState([]);
    const [title_tag,setTitleTag] = useState("");
    const [meta_description_tag,setMetaDescriptionTag] = useState("");
    const [canonical_tag,setCanonicalTag] = useState("");
    const [og_title,setOGTitle] = useState("");
    const [og_url,setOGURL] = useState("");
    const [og_meta_description,setOGMetaDescription] = useState("");
    const [og_image,setOgImage] = useState("");
    const [alttagnames,setAltTagNames] = useState([]);
    const [altTags,setAltTags] = useState([]);
    const [banner,setbanner]= useState("");
    const [tag,setTag] = useState("");
    const [h1,setH1] = useState([]);
    const [h2,setH2] = useState([]);
    const [h3,setH3] = useState([]);
    const [alttext,setAltText] = useState("");


    const fetchInfo=async ()=> {  
        // if(params.id==="1"){
            let nutrieatsDataApiData = await nutrieatsDataApi();
            if (nutrieatsDataApiData.error==="0") {  
                setessential(nutrieatsDataApiData.cards)
                setsliders(nutrieatsDataApiData.sliders);
                settop_grossing(nutrieatsDataApiData.top_grossing);
                setmaingoal(nutrieatsDataApiData.sub_main_goals);
                setsubgoal(nutrieatsDataApiData.sub_sub_goals);
                setmustbuy(nutrieatsDataApiData.must_buy_supplements_cards);
                setLoading(false);
                setTitleTag("Nutrieats: Top-Rated Products for Health and Wellness | Nutsby");
                setMetaDescriptionTag("Find the best Nutritional products to support your health and wellness journey. Shop premium nutrition essentials at Nutsby.✅100% Authentic");
                setseKeywordtag(["Healthy snacks",
"Nuts and dried fruits",
"nuts and seeds",
"nuts and dry fruits",
"dried fruit snacks",
"dried fruit healthy",
"dried fruits online"]);
                setCanonicalTag("https://www.nutsby.com/nutrieats");
                setAltText("high protein nuts and dried fruits up to 20% off");
                setOGTitle("Top-Rated Products for Health and Wellness | Nutsby");
                setOGURL("https://www.nutsby.com/nutrieats");
                setOGMetaDescription("Find the best Nutritional products to support your health and wellness journey. Shop premium nutrition essentials at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/nutrieats.jpg");
            }else{
              setLoading(false);
            }
            
            
       

    
    }
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024, // Medium to large screens
          settings: {
            slidesToShow: 4, // Ensure 6 slides are shown even after 1024px
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600, // Small to medium screens
          settings: {
            slidesToShow: 2, // Show 5 slides on smaller screens
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480, // Very small screens
          settings: {
            slidesToShow: 2, // Show 3 slides on very small screens
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 300, // Tiny screens
          settings: {
            slidesToShow: 2, // Show 2 slides on tiny screens
            slidesToScroll: 1,
          },
        },
      ],
    };
    const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      appendDots: (dots) => (
        <div
          style={{
            position: 'relative',
            bottom: '-5px', // Adjust this to move the dots closer to the slider
            display: 'flex',
            justifyContent: 'center',
            paddingLeft:'0px'
          }}
        >
          <ul style={{ margin: '0px' }}>{dots}</ul>
        </div>
      ),
    };
    
const AddToCartCall=async (p_id,qnt,clicked,index,max_qty)=> { 
    // AddToCart 
    // console.log(qnt)
    // if(qnt>0)
    //     {
    console.log(max_qty);
    if(clicked === true){
      
      setAddClicked(index);
    }
 
            let addcartDatatemp = await AddToCart(p_id,qnt);
            if (addcartDatatemp.error === "0") {
                // fetchInfo();  
                toast.success(addcartDatatemp.message, {
                  autoClose: 3000,
                });
                    const newList = top_grossing.map((item) => {
                        if (item.product_id === p_id) {
                          const updatedItem = {
                            ...item, 
                            cart_quantity: addcartDatatemp.cart_quantity,
  
                          };
                  
                          return updatedItem;
                        } 
                        return item;
                      });
                  
                      settop_grossing(newList); 
                    // setcartCount(addcartDatatemp.items_count);
  
                    // const newupdatedItem = {
                    //     ...cartCalculationData,
                    //     items_total: addcartDatatemp.cart_price,
                    //     total_price: addcartDatatemp.grand_total,
                    //     total_price: addcartDatatemp.grand_total_raw,
  
                    //   };
                    //   setcartCalculationData(newupdatedItem);
                // (e) =>setdetailsData((prevState) => ({ ...prevState, [e.target.name]: 1 }));
                // this.setState( detailsData.name="AKash");
          
              const headerData = await GetHeaderDetailsApi();
              if (headerData.error === "0") {
                if(headerData.cart_count!=null){
                  setCartCount(headerData.cart_count);
                }else{
                  setCartCount(0);
                }
                
                console.log(headerData.cart_count,"khfaosdufhuafs") 
              }
            }
          
   
  };

  const NotifyMeCall = async (p_id, type,clicked,index) => {
    if(clicked===true){
      setNotifyClicked(index);
    }
    try {
      let Data = await NotifyMeApi(p_id, type);
      if (Data.error === "0") {
        if (type === "stop") {
          Data.is_notified = "0";
          console.log("is_stopped");
        } else {
          Data.is_notified = "1";
          console.log("is_notified");
          toast.success(Data.message, {
            autoClose: 3000,
          });
        }
        const newList = top_grossing.map((item) => {
          if (item.product_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            setTimeout(function() {
              setNotifyClicked(null);
              
            }, 1100);
            return updatedItem;
          }
          return item;
        });
        settop_grossing(newList);
      } else {
        console.log(Data.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

useEffect(() => {
  
  setIsSubscribed(localStorage.getItem("is_subscribed"));
 
 
    fetchInfo(); 
  }, []);


const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  
  const productsresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    minidesktop: {
      breakpoint: { max: 1495, min: 1200 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1200, min: 900 },
    items: 4,
  },
  minitablet: {
    breakpoint: { max: 900, min: 464 },
  items: 3,
},
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3, 
  },
}; 

  const productsresponsivesss = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2, 
    },
  };

 

  
if(!productsLoading){

  return (
    <>
     <MetaTags
      
      title={title_tag}
      description={meta_description_tag}
      keywords={keyWordTag}
      canonical={canonical_tag}
      url="https://www.nutsby.com"
      image="https://www.nutsby.com/images/logo.jpg" 
      alt={alttext}
      ogTitle={og_title}
      ogUrl={og_url}
      ogDescription={og_meta_description}
      ogImage={og_image}
      indexing="index, follow"
      schema={{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Nutsby Fitcare LLP",
        "url": "https://www.nutsby.com",
        "logo": "https://www.nutsby.co/logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91-1234567890",
          "contactType": "Customer Service",
        },
      }}
    />
     {/* <MetaTags 
        title={metaInfo.title}
        description={metaInfo.description}
        url={metaInfo.url}
        image={metaInfo.image}
        alt={metaInfo.alt}
        keywords={metaInfo.keywords}
        canonical={metaInfo.canonical}
        ogTitle={metaInfo.ogTitle}
        ogUrl={metaInfo.ogUrl}
        ogDescription={metaInfo.ogDescription}
        ogImage={metaInfo.ogImage}
        robots={metaInfo.robots}
        viewport={metaInfo.viewport}
        author={metaInfo.author}
        language={metaInfo.language}
        charset={metaInfo.charset}
        geoRegion={metaInfo.geoRegion}
        geoPlaceName={metaInfo.geoPlaceName}
        geoPosition={metaInfo.geoPosition}
        icbm={metaInfo.icbm}
      /> */}

{
  
    <div> 
         {/* <section style={{marginBottom:'20px'}}>
          <div onClick={()=>{}} >
            <div className="mainCarousel d-none d-md-block d-lg-block " style={{paddingBottom: "20px",paddingTop: "8rem"}} > 
            <Row style={{display:'flex',justifyContent:'center'}}>
                  <Col lg={12} md={12} sm={12} col={12} >
                    <img src={require("../assets/images/PNG/nutrieats.png")} alt="" /> 
                    </Col>                    
                </Row> 
            </div>
            <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block" style={{paddingBottom: "20px",paddingTop: "3rem"}} > 
                <Row>
                <Col lg={12} md={12} sm={12} col={12}>
                <img src={require("../assets/images/PNG/nutrieats.png")} alt="" /> 
                    </Col>                      
                </Row> 
            </div>  
          </div>
        </section>   */}
        <section >
            <div onClick={()=>{}} >
              <div className="mainCarousel d-none d-md-block d-lg-block container" style={{paddingBottom: "0px",paddingTop: "10rem",borderRadius:'30px'}} > 
              <Row >
                    <Col lg={12} md={12} sm={12} col={12}  >
                      
                          
                          
                            <img src={require("../assets/images/PNG/nu_banner.png")} alt="" style={{ borderRadius: '20px' ,webkitUserDrag:"none"}} />
                          
                            
                      </Col>                    
                  </Row> 
              </div>
              <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container" style={{paddingBottom: "0px",paddingTop: "9rem"}} > 
                  <Row>
                  <Col lg={12} md={12} sm={12} col={12}>
                  
                  
                            <img src={require("../assets/images/nutrieats_banner_mobile.png")} alt="" style={{ borderRadius:'18px' ,webkitUserDrag:"none"}} />
                          
                        
                      </Col>                      
                  </Row> 
              </div>  
             
             <Row className='d-block d-md-none d-lg-none d-sm-none'>
              <Col xs={12} sm={12} md={8} lg={8} className='breadcrumb' style={{marginBottom:"0px"}}>
                 
                   <Breadcrumb>
                    <Breadcrumb.Item href="#"> Home </Breadcrumb.Item>
                    
                      <Breadcrumb.Item href="#"> NutriEats </Breadcrumb.Item>
                    
                  </Breadcrumb>
                </Col>
                </Row> 

                <div className='d-none d-md-block d-lg-block d-sm-block container'>
              <Col  className='breadcrumb' style={{marginBottom:"0px"}}>
              <Breadcrumb>
                    <Breadcrumb.Item href="#"> Home </Breadcrumb.Item>
                    
                      <Breadcrumb.Item href="#">NutriEats </Breadcrumb.Item>
                    
                  </Breadcrumb>
                </Col>
                </div> 
                </div>
                
           

          </section>

  {/*<!--second section --->*/}
  <section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",marginBottom:'10px' }}>
  {/* Heading */}
  <h4 style={{ textAlign: "center", margin: window.innerWidth >= 576 ?"20px 0":"10px 0px" }}>Essential Supplements</h4>

  {/* Content */}
  <div className="container" style={{ cursor: "pointer", padding: '0px 10px' }}>
    <Row noGutters>
      {essential.length > 0 && essential.map((item, index) => (
        <Col lg={3} md={3} sm={6} xs={6} key={index} style={{ padding: '5px', textAlign: 'center' }}>
          <NavLink className="cardcategory"  to={`/${item.url_title}/category/${item.hash_id}`}>
            <div className="image-container" style={{ overflow: "hidden", margin: 0 }}>
              <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
            {/* Name below the image */}
            <h5 className='musttry' style={{ marginTop: '10px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                  {item.name}
                </h5>
          </NavLink>
        </Col>
      ))}
    </Row>
  </div>
</section>


{/* <!-- fifth section --> */}
<section className="container" style={{ display: "flex", alignItems: "center", justifyContent: "center",marginBottom:'20px' }}>
<Col style={{ cursor: "pointer", padding: '0px 10px' }}>
  <Row noGutters>
    {sub_goal.length > 2 && (
      <>
        <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '5px' }}>
          <NavLink className="cardcategory" to={`/${sub_goal[1].url_title}/category/${sub_goal[1].hash_id}`}>
            <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
              <img src={sub_goal[1].web_image} alt={sub_goal[1].name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
          </NavLink>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '5px' }}>
          <NavLink className="cardcategory" to={`/${sub_goal[2].url_title}/category/${sub_goal[2].hash_id}`}>
            <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
              <img src={sub_goal[2].web_image} alt={sub_goal[2].name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
          </NavLink>
        </Col>
      </>
    )}
  </Row>
</Col>

  </section>


  {/* <!-- Fourth section --> */}
  <section className="container" style={{marginBottom:'20px'}}>
       
        <Row>      
            <Col md={12}>
                <div className="newarrivalproducts" > 
                <h4 style={{ textAlign: "start", margin: "30px 10px 5px 10px" }}>Top Grossing Products</h4>
                </div>
            </Col>
        </Row>  

        <div style={{  paddingBottom: "20px"}}>
        <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={true} infinite={true} className="stayfit1">   

                { top_grossing.map((element) =>
                                <Col style={{padding:"3px"}}> 
                                 <ProductItemCard
                                   url_title={element.url_title}
                                    add_cart={AddToCartCall}
                                    image={element.image}
                                    rate={element.average_rating}
                                    dis={element.discount_percentage}
                                    name={element.name}
                                    tag={element.item_tag}
                                    elite_price={element.elite_price}
                                    saleprice={element.price}
                                    mrp={element.mrp}
                                    cart_qnt={element.cart_quantity}
                                    id={element.product_id}
                                    notified={element.is_notified}
                                    max_qnty={element.max_cart_quantity}
                                    nuin={element.nuin}
                                    stock={element.stock}
                                    notify_me = {NotifyMeCall}
                                    is_subscribed = {is_subscribed}
                                    clicked = {addClicked === element.product_id}
                                    notifyClicked = {notifyClicked === element.product_id}
                                    index = {element.product_id}
                                    /> 
                               
                                </Col> 
  )}
              
                                  
            </Carousel> </div>
        </div> 
      
  </section>

{/*<!--third section --->*/}
<div>
  <div>
  {/* <section className='container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', paddingLeft: '5px',marginBottom:'20px' }}> */}
  <section className='container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',marginBottom:'20px' }}>
  
  <Col style={{ cursor: "pointer", padding: '0px 10px' }}>
  <Row noGutters>
    {main_goal && main_goal.length > 0 && (
      <>
        {/* <div className="image-container" style={{ flex: '4', paddingRight: '2px' }}>
          <NavLink className="cardcategory" to={`/Products/Category/${sub_goal[0].id}`}>
            <img src={sub_goal[0].web_image} alt="Sub Goal 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </NavLink>
        </div>

        <div className="image-container" style={{ flex: '9', paddingLeft: '2px' }}>
          <NavLink className="cardcategory" to={`/Products/Collection/${main_goal[0].id}`}>
            <img src={main_goal[0].web_image} alt="Main Goal 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </NavLink>
        </div> */}
         
         <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '5px' }}>
          <NavLink className="cardcategory" to={`/${sub_goal[0].url_title}/category/${sub_goal[0].hash_id}`}>
            <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
              <img src={sub_goal[0].web_image} alt={sub_goal[0].name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
          </NavLink>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '5px' }}>
          <NavLink className="cardcategory" to={`/${main_goal[0].url_title}/collection/${main_goal[0].hash_id}`}>
            <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
              <img src={main_goal[0].web_image} alt={main_goal[0].name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
          </NavLink>
        </Col>
         
      </>
    )}
    </Row>
  </Col>
</section>
  </div>
</div>


<section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" ,marginBottom:"30px"}}>
      {/* Heading */}
      <h4 style={{ textAlign: "center", margin: "20px 0" }}>Must Buy Supplements</h4>

      {/* Carousel */}
      <div className="container" style={{ cursor: "pointer", padding: '5px 20px' }}>
        <Slider {...settings}>
          {must_buy.length > 0 && must_buy.map((item, index) => (
            <div key={index} style={{ padding: '5px' }}>
              <NavLink className="cardcategory" to={`/${item.url_title}/collection/${item.hash_id}`}>
                <div className="image-container" style={{ overflow: "hidden", margin:'3px' }}>
                  <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "100%", padding:'5px'}} />
                </div>
                <h6 className='musttry' style={{ marginTop: '10px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                  {item.name}
                </h6>
              </NavLink>
            </div>
          ))}
        </Slider>
      </div>
    </section>
    <ToastContainer />
    <BottomBar/>
   </div>
   }
    </>
  );
}else{
  return (
    <section>
    <Container className="mt-100 d-none d-md-block d-lg-block d-sm-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
    <Row className="mt-10">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto"  style={{borderRadius:"10px"}}/>
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={12} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        
      
    
      <Row className="mt-20" >
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto" style={{borderRadius:"10px"}} />
  </Col>
    </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
      
        <Row className="mt-20">
        {shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
        </Col>
        ))}
        </Row>
      
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
      </Col>
    </Row>
</Container>
<Container className="mt-80 d-block d-md-none d-lg-none d-sm-none" style={{paddingBottom: "20px",height:"",alignContent:"center",paddingTop:'3rem'}}>
<Row className="mt-10">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={180} width="auto"  style={{borderRadius:"10px"}}/>
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={12} sm={12} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
        
      
    
      <Row className="mt-20" >
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={180} width="auto" style={{borderRadius:"10px"}} />
  </Col>
    </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
      
        <Row className="mt-20">
        {shimmeritems1.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
        </Col>
        ))}
        </Row>
      
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems1.map((_, index) => (
        <Col xs={4} sm={4} md={4} lg={4} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={120} width="auto" />      
        </Col>
        ))}
        </Row>  
       
      </Col>
    </Row>
</Container>
</section>
);
}
}
 
