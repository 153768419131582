import React, {useCallback, useEffect, useState} from "react";
import {UniversalSearchAPI,DefaultSearchAPI,ClearTagsAPI,AddTagsAPI} from '../../Service/ApiCalling';
import { Container, Form ,Dropdown, Modal, Row, Col, Carousel } from 'react-bootstrap';
import {ReactComponent as Strengthimg} from '../../assets/images/musclebuilding.svg'
import {ReactComponent as Football} from '../../assets/images/football.svg'
import {ReactComponent as Happyimg} from '../../assets/images/layer22.svg'
import {ReactComponent as FitnessForever} from '../../assets/images/moustache.svg'
import { debounce } from "@mui/material";
import "../../SubCss/Searchscreen.css";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { height } from "@fortawesome/free-solid-svg-icons/fa0";
const SearchScreen = () => {
    const [searchString,setSearchString] =React.useState("");
    const [isloading,setIsLoading] =React.useState(true);
    const [loading,setLoading] =React.useState(true);
    const [prods,setProdsData] =React.useState([]);
    const [brands,setBrandsData] =React.useState([]);
    const [categories,setCategoriesData] =React.useState([]);
    const [recent_searched,setRecentSearched] =React.useState([]);
    const [goals_list,setGoalsList] =React.useState([]);
    const [categories_list,setCategoriesList] =React.useState([]);
    const navigate = useNavigate();
    const universal_search=async (searchvalue)=>{
        if(searchvalue.length>0){
          let data=await UniversalSearchAPI(searchvalue);
          if(data.error=="0"){  
            setBrandsData(data.brands);
            setProdsData(data.products);
            setCategoriesData(data.categories);
            setLoading(false);
          }else{
            setBrandsData([]);
            setProdsData([]);
            setCategoriesData([]);
            setLoading(false);
          }
        }
      }

      const productsresponsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        minidesktop: {
          breakpoint: { max: 1495, min: 1200 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1200, min: 900 },
        items: 3,
      },
      minitablet: {
        breakpoint: { max: 900, min: 464 },
      items: 5,
    },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 5, 
      },
    }; 
      const debouncedSearch = useCallback(
        debounce((value) => handleUniversalSearch(value), 300), // 300ms delay
        []
      );
    const handleUniversalSearch=async (searchString)=> { 
        setSearchString(searchString);
        universal_search(searchString);
        setIsLoading(false);
      };

      const ClearTagsAPIFunction=async (id)=>{
        let data=await ClearTagsAPI(id);
        if(data.error=="0"){
          DefaultSearchPageAPIFunction();
        }
      }

      const AddTagsAPIFunction=async (product_name,reftype,refid)=>{
        let data=await AddTagsAPI(product_name,reftype,refid);
        if(data.error=="0"){
        }
      }

      const DefaultSearchPageAPIFunction=async ()=>{  
        let data=await DefaultSearchAPI();
        if(data.error=="0"){
          setCategoriesList(data.categories);
          setGoalsList(data.goals);
          setRecentSearched(data.recent_search);
          setIsLoading(false);
        }else{
          setIsLoading(false);
        }
      } 

      useEffect(() => {
        const handleResize = () => {
          // Detect screen size
          const screenWidth = window.innerWidth;
          // Redirect to homepage for lg or xl screens
          if (screenWidth >= 992) { // 992px is the breakpoint for large screens in Bootstrap
            navigate('/');
          }
        };
    
        // Add event listener to check for resize
        window.addEventListener('resize', handleResize);
    
        // Check on component mount as well
        handleResize();
    
        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
      }, [navigate]);
      useEffect(() => {
        DefaultSearchPageAPIFunction();
      }, []);
 
    return ( <> 
       <div className="d-block d-lg-none mt-200 container" style={{ display: "flex", flexDirection: "column", alignItems: "center",marginTop:"10rem" }}>
       <div className="search-input-container">
       <svg width="20" height="18" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:"10px"}}>
        <path d="M13.5277 11.2715L10.5725 8.47459C11.0746 7.7181 11.366 6.82397 11.366 5.86441C11.366 3.14967 9.04061 0.949219 6.17217 0.949219C3.30374 0.949219 0.978516 3.14967 0.978516 5.86441C0.978516 8.57925 3.30363 10.7795 6.17217 10.7795C7.27559 10.7795 8.29764 10.453 9.13865 9.89827L12.0586 12.6619C12.2615 12.8537 12.5275 12.9492 12.7931 12.9492C13.0591 12.9492 13.3248 12.8537 13.528 12.6619C13.9334 12.2777 13.9334 11.6555 13.5277 11.2715ZM6.17217 9.18704C4.23337 9.18704 2.66151 7.69956 2.66151 5.86461C2.66151 4.02966 4.23337 2.54208 6.17217 2.54208C8.11108 2.54208 9.68283 4.02966 9.68283 5.86461C9.68283 7.69956 8.11108 9.18704 6.17217 9.18704Z" fill="#023047"/>
        </svg>
    <input
        type="search"
        onChange={(e) => debouncedSearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission if it exists
            window.location.href = `/search-result/${e.target.value}`;
            AddTagsAPIFunction(e.target.value,"Text","");
          }
        }}
        placeholder="What are you looking for?"
        aria-label="Search"
        style={{
            borderRadius: "38px",
            outline: "none",
            border: "none",
            height: "30px",
            width: "95%",
            fontFamily:"Inter",
            fontSize:"13px",
            boxShadow: "none !important",
            background:'none'
        }}
    />
            </div>
           
        
         
        { isloading?
         <Container className="text-center">
         <div class="spinner-border" role="status">
           <span class="visually-hidden">Loading...</span>
         </div> 
       </Container>:
        searchString!=""?
        <div>
        <Container style={{marginLeft:"10px",marginTop:"10px",marginBottom:"10px"}}>
          {categories.map((element, index) => {
                    return(
                        <a href={`/${element.url_title}/category/${element.hash_id}`} key={index} style={{display:"flex",alignItems:"center",marginBottom:"5px"}} onClick={() => AddTagsAPIFunction(searchString, "Category",element.id)}>
                            <img src={element.image} alt={""} className="serachimage" />
                            <span className="serachname">{element.name}</span>
                        </a>
                    );
                   })}
                    {brands.map((element, index) => {
                    return(
                      <a href={`/${element.url_title}/brand/${element.hash_id}`} key={index} style={{display:"flex",alignItems:"center",marginBottom:"5px"}} 
                      onClick={() => AddTagsAPIFunction(searchString, "brand",element.id)}>
                        <img src={element.image} alt={""} className="serachimage" />
                        <span className="serachname">{element.name}</span>
                      </a>
                    );
                   })}

                   {prods.map((element, index) => {
                    return(
                  <a href={`/${element.url_title}/p/${element.nuin}`} key={index} style={{display:"flex",alignItems:"center",marginBottom:"5px"}} 
                  onClick={() => AddTagsAPIFunction(searchString, "Item",element.id)}>
                    <img src={element.image} alt={""} className="serachimage" />
                    <span className="serachname">{element.name}</span>
                  </a>
                    );
                   })}  
        </Container>
          <a href={"/search-result/"+searchString} onClick={(e)=>{

            AddTagsAPIFunction(searchString,"Text","");
          }}>
                  <div style={{display:"flex",marginLeft:"25px",marginTop:"15px"}}>
                
                        <div style={{borderRadius:"50%",backgroundColor:"#CCF5FF",display:"flex",justifyContent:"center",alignItems:"center",width:"50px",height:"50px",marginRight:"10px"}}>
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.5974 14.6232L12.689 10.6609C13.3531 9.58925 13.7385 8.32257 13.7385 6.96318C13.7385 3.1173 10.6629 0 6.8691 0C3.07533 0 0 3.1173 0 6.96318C0 10.8092 3.07518 13.9262 6.8691 13.9262C8.32848 13.9262 9.68023 13.4637 10.7925 12.6778L14.6545 16.5929C14.9228 16.8646 15.2746 17 15.6259 17C15.9777 17 16.3291 16.8646 16.5979 16.5929C17.1341 16.0487 17.1341 15.1672 16.5974 14.6232ZM6.8691 11.6703C4.30485 11.6703 2.22592 9.56299 2.22592 6.96347C2.22592 4.36396 4.30485 2.25655 6.8691 2.25655C9.4335 2.25655 11.5123 4.36396 11.5123 6.96347C11.5123 9.56299 9.4335 11.6703 6.8691 11.6703Z" fill="#009FC6"/>
                          </svg>
                        </div>
                        <div>
                            <div>
                              <p style={{fontSize:"16px",color:"#009FC6",margin:"0px"}}>Show all results for &gt;</p>
                              <p style={{color:"black"}}>{searchString}</p>
                            </div>                  
                        </div>
                    
                  </div>
                  </a>
        </div>
        :
        isloading?
        <Container className="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div> 
      </Container>:
        <Container>
            <div style={{marginTop:"20px"}}>
            <span style={{fontSize:"15px",fontWeight:"600",fontFamily:"Inter",marginLeft:"10px"}}>Recent Searches</span>

            
            <div className="horizontal-list-view">

              {recent_searched.map((element, index) => {
                    return( 
                      <div className="horizontal-list-item">                    
                        <Row className="search-tags" onClick={()=>{
                          if(element.ref_type=="Item" && element.ref_id!="0"){
                            window.location.href = `/${element.url_title}/p/${encodeURIComponent(element.nuin)}`;
                          }else if(element.ref_type=="category" &&  element.ref_id!="0"){
                            window.location.href = `/${element.url_title}/category/${encodeURIComponent(element.hash_id)}`;
                          }else{
                            window.location.href = "/search-result/"+element.searched;
                          }
                        }}> 
                            
                            <Col md={7} sm={7} lg={10} xs={7} xl={10}>
                              <div> 
                              <span style={{fontFamily:"Inter",fontSize:"15px"}}>
                                {element.searched}
                                </span>
                              </div>
                               
                            </Col>
                            <Col md={5} sm={5} lg={1} xs={5} xl={1} >
                              <div 
                              onClick={()=> ClearTagsAPIFunction(element.id)}>
                              <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7.45703" cy="7.63086" r="7" fill="#F4F5FA"/>
                                <path d="M7.45703 8.39543L5.21506 10.6387C5.00824 10.8457 4.65583 10.8457 4.44901 10.6387M7.45703 8.39543L4.56689 10.5209M7.45703 8.39543L9.69901 10.6387L9.69902 10.6388C9.8011 10.7409 9.9376 10.7975 10.082 10.7975C10.2265 10.7975 10.363 10.7409 10.4651 10.6387C10.5672 10.5366 10.6237 10.3999 10.6237 10.2556C10.6237 10.1113 10.5672 9.9746 10.465 9.87247L10.4651 9.87252L10.3472 9.99034L10.465 9.87244L7.45703 8.39543ZM4.44901 10.6387L4.56689 10.5209M4.44901 10.6387C4.44903 10.6388 4.44905 10.6388 4.44908 10.6388L4.56689 10.5209M4.44901 10.6387C4.34687 10.5366 4.29036 10.4 4.29036 10.2556C4.29036 10.1113 4.34686 9.97464 4.44901 9.87252L4.56689 9.99034L4.44909 9.87245L6.69113 7.62907M4.56689 10.5209C4.49604 10.4501 4.45703 10.3558 4.45703 10.2556C4.45703 10.1554 4.49604 10.0611 4.56689 9.99034L6.92676 7.62907M6.69113 7.62907L6.80887 7.74689L6.92676 7.62907M6.69113 7.62907L4.44908 5.38569M6.69113 7.62907L6.80887 7.51126L6.92676 7.62907M6.92676 7.62907L4.44908 5.38569M4.44908 5.38569L4.5669 5.2678L4.44901 5.38562C4.34687 5.2835 4.29036 5.14684 4.29036 5.00251C4.29036 4.85818 4.34686 4.72151 4.44901 4.6194C4.44902 4.61939 4.44903 4.61937 4.44905 4.61936C4.44906 4.61935 4.44907 4.61934 4.44908 4.61932C4.65591 4.41246 5.00826 4.41248 5.21505 4.6194L7.45703 6.86271M4.44908 5.38569L7.45703 6.86271M7.45703 6.86271L7.33921 6.9806L7.45703 7.09848L7.57485 6.9806L7.45703 6.86271ZM8.22294 7.62907L10.465 5.3857L10.3472 5.26781M8.22294 7.62907L10.465 4.61931C10.5672 4.72144 10.6237 4.85815 10.6237 5.00251C10.6237 5.14682 10.5672 5.28349 10.4651 5.38562L10.3472 5.26781M8.22294 7.62907L8.10519 7.74689L7.98731 7.62907M8.22294 7.62907L8.10519 7.51126L7.98731 7.62907M10.3472 5.26781L7.98731 7.62907M10.3472 5.26781L7.98731 7.62907" fill="#6977C2" stroke="#6977C2" stroke-width="0.333333"/>
                                </svg>
                              </div>
                               
                            </Col>
                            
                        </Row>
                        </div>
                    );
                    })}
                    
              </div>
                    
            </div>
    
          <div style={{border:'1px solid #D9D9D9',borderRadius:"21px",paddingTop:"10px",marginTop:"20px"}}>
            <span style={{fontSize:"15px",fontWeight:"600",fontFamily:"Inter",marginLeft:"20px"}}>Search By Your Goals</span>
            <Row style={{margin:"10px"}}>                             
               <Col sm={3} xs={3} className="d-flex flex-column align-items-center justify-content-center" onClick={()=>{
                       window.location.href = '/maingoal/muscle-building';                       
                    }}>
                <div className="round-div">
                    <Strengthimg style={{ width: "33px", height: "33px" }}/>
                </div>
                <span style={{ fontSize: "12px", fontFamily: "Inter", marginTop: "5px",textAlign:"center"}}>Muscle Building</span>
                </Col>

                <Col sm={3} xs={3} className="d-flex flex-column align-items-center justify-content-center" onClick={()=>{
                       window.location.href = '/maingoal/athelete-journey';                       
                    }}>
                      <div class="round-div">
                        <Football  style={{width:"33px",height:"33px"}}/>
                    </div>
                <span style={{fontSize: "12px", fontFamily: "Inter",textAlign:"center"}}>Athlete Journey</span>
                </Col> 
                <Col sm={3} xs={3} className="d-flex flex-column align-items-center justify-content-center" onClick={()=>{
                       window.location.href = '/maingoal/happy-healthy';                       
                    }}>
                      <div class="round-div">
                        <Happyimg  style={{width:"33px",height:"33px"}}/>
                    </div>
                <span style={{fontSize: "12px", fontFamily: "Inter",textAlign:"center"}}>Happy &Healthy</span>
                </Col> 
                <Col sm={3} xs={3} className="d-flex flex-column align-items-center justify-content-center" onClick={()=>{
                       window.location.href = '/maingoal/fitness-forever';                       
                    }}>
                      <div class="round-div">
                        <FitnessForever style={{width:"34px",height:"34px"}}/>
                    </div>
                <span style={{fontSize: "12px", fontFamily: "Inter",textAlign:"center"}}>Forever Fitness</span>
                </Col>                                                   
            </Row>

          </div>

          <div style={{marginBottom:"50px",marginTop:"30px"}}>
          <span style={{fontSize:"15px",fontWeight:"600",fontFamily:"Inter",marginTop:"20px",marginLeft:"10px"}}>Search By Categories</span>
            <Row className="mt-20">
            {categories_list.map((element, index) => {
                    return(                     
                        
                          <Col sm={4} xs={4} className="d-flex flex-column align-items-center justify-content-center">
                            <NavLink to={`/${element.url_title}/category/${encodeURIComponent(element.hash_id)}`} style={{display:"contents",color:"black"}}>
                            <div class="cat-round-div" >
                            <img src={element.image} alt="Description of the image" style={{width:"80px",height:"80px"}}/>
                            </div>
                        <span style={{fontSize: "12px", fontFamily: "Inter",textAlign:"center",marginBottom:"10px"}}>{element.name}</span>
                        </NavLink>
                       </Col>  
                                                      
                    );
                    })}
            </Row>
          </div>


         </Container>
        }
       </div>
    </>
    );
}
export default SearchScreen;