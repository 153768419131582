 
import Feedback from "react-bootstrap/esm/Feedback.js";
import {guest_login_API,generate_otp_API,app_id,refereshtoken_API,verify_otp_API,check_referral_code} from "./ApiNames.js";
import axios from 'axios'; 
 

const baseURL = process.env.REACT_APP_API_URL;


console.log('Base URL:', process.env.REACT_APP_API_URL);
console.log('Base URL:', baseURL);
console.log(process.env.NODE_ENV);
console.log(process.env.PUBLIC_URL);

// OLD
const FormData = require('form-data');
async function CallApi(url,body){
    try{
      let current_timestamp=Math.round(+new Date()/1000);
    let headerdata={  
        'Client-Id': app_id
      }
    if(localStorage.getItem('access_token')!==null && localStorage.getItem('access_token')!=="null")
    {
        headerdata={  
            'Client-Id': app_id,
            'Authorization':"Bearer "+localStorage.getItem('access_token')
          }
    }
   
    if(((current_timestamp+100)>(localStorage.getItem('access_expiry_timestamp'))) && localStorage.getItem('access_token')!==undefined && localStorage.getItem('access_token')!=="undefined")
    {
        await RefershHeaders();  
        headerdata={  
            'Client-Id': app_id,
            'Authorization':"Bearer "+localStorage.getItem('access_token')
        } 
    }

    let config = {
        method : "post",
        mode: 'no-cors',
        maxBodyLength: Infinity,
        url : process.env.NODE_ENV==="production"?`${baseURL}${url}`:`${url}`,
        headers : headerdata,
        data :  body
    }


    return await axios(config).then(res => res.data);
    }catch(error){
      if (error.response) {
        
        console.log('Server responded with an error:', error.response.data);
      } else if (error.request) {
        
        console.log('No response received:', error.request);
      } else {
        
        console.log('Error setting up request:', error.message);
      }
    }
}



async function RefershHeaders(){
    let current_timestamp=Math.round(+new Date()/1000);
    let headerdata={  
        'Client-Id': app_id
      }
    if(((current_timestamp+100)>(localStorage.getItem('access_expiry_timestamp'))) && localStorage.getItem('refresh_token')!==null)
    { 
        
        let body = new FormData();
        body.append('refresh_token', localStorage.getItem('refresh_token')); 

        let response=await axios.post(process.env.NODE_ENV==="production"?`${process.env.REACT_APP_API_URL}${refereshtoken_API}`:refereshtoken_API,body,{
            headers:headerdata
           }).then(res => res.data);
           localStorage.setItem('access_expiry_timestamp', response.access_expiry_timestamp); 
           localStorage.setItem('access_token', response.access_token); 
           console.log(response,'apiresponsew')
    }
   
}




export default async function GuestLogin() {
     
    // cookies.remove('myCat')
    console.log("called1",localStorage.getItem('access_expiry_timestamp')    )
    let current_timestamp=Math.round(+new Date()/1000);
    
    let ret={  
      'Content-Type': 'application/json',
        'Client-Id': app_id,
        
      }
    if(localStorage.getItem('access_expiry_timestamp')===null) 
    {  
        let res=await CallApi(guest_login_API,{}); 
        if(res.error==="0")
        {
            localStorage.setItem('is_guest', '1');
            localStorage.setItem('access_expiry_timestamp', res.access_expiry_timestamp); 
            localStorage.setItem('access_token', res.access_token);
            localStorage.setItem('refresh_token', res.refresh_token); 
        } 
        window.location.reload();
        console.log("current_timestamp:",current_timestamp,"access_expiry_timestamp",(localStorage.getItem('access_expiry_timestamp')))
        
        ret={  
          'Content-Type': 'application/json',
            'Client-Id': app_id,
            'Authorization':"Bearer "+localStorage.getItem('access_token'),
            
          }
        
    } 
    return ret;
  }

  export async function AddOrder(couponCode,total,itemsTotal,paymentType,fpused,orderSource,freeItems,utm_source,utm_medium,utm_campaign) { 
    let body = new FormData();
    body.append('coupon_code', couponCode); 
    body.append('total_amount', total); 
    body.append('items_total', itemsTotal); 
    body.append('payment_type', paymentType); 
    body.append('rewards_used', fpused); 
    body.append('order_placed_from', 'mobile'); 
    body.append('order_source', orderSource); 
    body.append('free_items',  JSON.stringify(freeItems).toString()); 
    body.append('utm_source', utm_source); 
    body.append('utm_medium', utm_medium); 
    body.append('utm_campaign', utm_campaign); 
 
    try {
      let res = await CallApi("/add_order", body);
      console.log(res, "AddOrder");
      return res;
    } catch (error) {
      console.error("Error while adding order:", error);
      return { error: true, message: "Failed to add order" }; // Handle error case
    }
  }

  export async function OrderRelatedData(razor_order_id){
    let body = new FormData();
    body.append('razor_order_id', razor_order_id);
    let res = await CallApi("/order_related_small_data",body);
    console.log(res.body,"Order Related Data");
    return res;
  }

  export async function GenerateOtp(mobile) { 
        let body = new FormData();
        body.append('mobile_number', mobile); 
        body.append('mobile_number_country_id', "1"); 

      let res=await CallApi(generate_otp_API,body);
     
      return res;
  }
  


  export async function VerifyOtp(mobile,otp) { 
    let body = new FormData();
    body.append('mobile_number', mobile);
    body.append('otp', otp);  
    body.append('mobile_number_country_id', "1"); 

  let res=await CallApi(verify_otp_API,body);
  if(res.error==='0'){
    localStorage.setItem('is_guest', '0');
    localStorage.setItem('access_expiry_timestamp', res.access_expiry_timestamp); 
    localStorage.setItem('access_token', res.access_token);
    localStorage.setItem('refresh_token', res.refresh_token);
  }
     
  return res;
}


export async function HomePage() {  
  let res=await CallApi("/new/home",{});
    console.log(res,":Homepageres")
  return res;
}

export async function TopGrossingProductsApi() {  
  let res=await CallApi("/top_grossing_products",{});
    console.log(res,":TopGrossres")
  return res;
}
export async function RecentlyviewedApi() {  
  let res=await CallApi("/top_grossing_products",{});
    console.log(res,":RecentlyViewedres")
  return res;
}

export async function OrderAgainProductsApi() {  
  let res=await CallApi("/order_again_products",{});
    console.log(res,":OrderAgainres")
  return res;
}

export async function ProductDetailsApi(id) {

  let body = new FormData();
  body.append('product_id', id);  

let res=await CallApi("/product_details",body);
  console.log(res,":res")
return res;
}

export async function CheckProductAvaiabilityApi(pin) {
  
  let body = new FormData();
  body.append('pincode', pin);  

  let res=await CallApi("/get_estimated_deliverydate",body);
    console.log(res,":res")
  return res;
}

export async function AddToCart(p_id,qnt) { 
  let body = new FormData();
  body.append('product_id', p_id);
  body.append('quantity', qnt);  


let res=await CallApi("/add_to_cart",body);
  console.log(res,":res")
return res;
}
export async function viewsimilarpro(p_id) { 
  let body = new FormData();
  body.append('product_id', p_id);

let res=await CallApi("/out_of_stock_related_products",body);
  console.log(res,":res")
return res;
}

export async function ordercancelremarks(o_id,feedback) { 
  let body = new FormData();
  body.append('order_id', o_id);
  body.append('feedback',feedback );

let res=await CallApi("/add_order_cancellation_remarks",body);
  console.log(res,":res")
return res;
}

export async function ordercancel(o_id,returntype) { 
  let body = new FormData();
  body.append('order_id', o_id);
  body.append('return_type',returntype );

let res=await CallApi("/cancel_order",body);
  console.log(res,":res")
return res;
}

export async function AddwalletApi(amt){
    let body = new FormData();
    body.append('add_amount', amt);  


  let res=await CallApi("/add_wallet_balance",body);
    console.log(res,":res")
  return res;
}

export async function RemoveItemFromCart(c_id) { 
  let body = new FormData();
  body.append('cart_id', c_id);  


let res=await CallApi("/delete_cart_item",body);
  console.log(res,":res")
return res;
}

export async function SubmitMembershipFeedback(membership_id,review){
  let body = new FormData();
  body.append("membership_id",membership_id);
  body.append("review",review);
  let res = await CallApi("/submit_membership_feedback",body);
  console.log(res, "SubmitReview res")
  return res;
}

export async function RetrieveWebNotification(){
  let res = await CallApi("/send_web_notifications");
  console.log(res, "WEbbbbbbbb")
  return res;
}

export async function AddSubScriptionPlan(item_id){
  let body = new FormData();
  body.append("item_id",item_id);
  let res = await CallApi("/direct_buy_subscription_plan",body);
  console.log(res, "AddSubScriptionPlan res")
  return res;

}

export async function emailsubscriptionsApi(email){
  let body = new FormData();
  body.append("ref_type","Website Home Nutsby");
  body.append("email_id",email);
  let res = await CallApi("/email_subscriptions",body);
  console.log(res, "emailsubscriptionsApi res")
  return res;

}

export async function UserRegistration(name,email,mobile,otp,gender,referral_code) { 
  let body = new FormData();
  body.append('mobile_number', mobile);
  body.append('otp', otp);  
  body.append('email_id',email); 
  body.append('name',name);
  body.append('fcm_token', "");
  body.append('mobile_number_country_id',"+91");
  body.append('device_details', "");
  body.append('gender',gender);
  body.append('referral_code',referral_code);
  body.append('platform',"Website");
  
let res=await CallApi("/registeration2",body);
  console.log(res,":res")
return res;
}
export async function verify_referral_code(referral_code) { 
  let body = new FormData();
  body.append('referral_code', referral_code); 
 let res=await CallApi(check_referral_code,body);
console.log(res)

return res;
}
export async function MembershipreviewList(){
  let res = await CallApi("/membership_review_list");
  console.log(res,"MembershipReviews res")
  return res;
}

export async function UpdateFavStatus(p_id) { 
  let body = new FormData();
  body.append('product_id', p_id); 


let res=await CallApi("/add_or_remove_favorite",body);
  console.log(res,":res")
return res;
}

export async function EditAddressApi(id,name,mobile,area,address2,landmark,address,pincode,markerPosition,activeButton,otherType) {
  let body = new FormData();
  body.append('address_id',id); 
  body.append('name',name); 
  body.append('mobile_number', mobile);
  body.append('loc',markerPosition);
  body.append('loc_address',address);
  body.append('address_type',activeButton );
  body.append('address_line1', area);
  body.append('address_line2',address2);  
  body.append('pincode', pincode);  
  body.append('landmark',landmark);  
  body.append('other_address_type',otherType);  

let res=await CallApi("/edit_customer_address",body);
  console.log(res,":res")
return res;
}

export async function DeleteAddressApi(id) {
  let body = new FormData();
  body.append('address_id',id); 
let res=await CallApi("/delete_customer_address",body);
  console.log(res,":res")
return res;
}

export async function CustomerAddressDetailsApi(id) {
  let body = new FormData();
  body.append('address_id',id); 
let res=await CallApi("/customer_address_details",body);
  console.log(res,":customer_address_details")
return res;
}

export async function Logout(){
  let res = await CallApi("/logout"); 
  // await GuestLogin();
  localStorage.clear();
  window.location.reload();
  // localStorage.setItem('is_guest', '1');
  // localStorage.setItem('access_expiry_timestamp', null); 
  // localStorage.setItem('access_token',null);
  // localStorage.setItem('refresh_token',null); 
  console.log(res,"Logout res")
  return res;
}

export async function GetOrderList() { 
  
  let res=await CallApi("/new/orders_list",{});
    console.log(res,":GetOrderList res")
  return res;
}

export async function GetOrderDetails(id) {
  console.log(id,"order_id") 
  let body = new FormData();
  body.append('order_id', id);  

let res=await CallApi("/order_details",body);
  console.log(res,":res")
return res;
}

export async function GetFitPointsList() { 
  
  let res=await CallApi("/fitpoints_api",{});
    console.log(res,":GetFitPointsList res")
  return res;
}

export async function GetAdressList() { 
  
  let res=await CallApi("/customer_address_list",{});
    console.log(res,":GetAdressList res")
  return res;
}

export async function GetFavList() { 
  
  let res=await CallApi("/favourites_list",{});
    console.log(res,":GetFavList res")
  return res;
}


export async function CartListApiCall() { 
  
  let res=await CallApi("/cart_list",{});
    console.log(res,":cart_list res")
  return res;
}

export async function ProfileDetails(){
  let res=  await CallApi("/profile_details",{});
  console.log(res,"GetProfileDetails res")
  return res;
}

export async function EditProfileDetails(name,email_id,date_of_birth,image){
  console.log(name,"name")
  console.log(email_id,"name")
  console.log(date_of_birth,"name")  
  console.log(image,"image");
  let body = new FormData();
  body.append('name', name);  
  body.append("email_id",email_id);
  body.append("date_of_birth",date_of_birth);
  body.append("mobile_number_country_id","1");
  body.append("image",image);

  let res = await CallApi("/edit_profile",body);
  console.log(res, "editProfile res")
  return res;
}
export async function RateOrderdetailsApi(id) {
  let body = new FormData();
  body.append('order_id', id);  
let res=await CallApi("/order_review_products",body);
  console.log(res,":res")
return res;
}

export async function OrderRatingSubmitApi(id,ratings,deliveryrating) {
  let body = new FormData();
  body.append('order_id', id); 
  body.append('item_ratings', ratings);
  body.append('rating', deliveryrating);
  
let res=await CallApi("/order_review_submitting",body);
  console.log(res,":res")
return res;
}

export async function GoalHomeMuscleBuildingApi() {  
let res=await CallApi("/muscle_building_data",{});
  console.log(res,":res")
return res;
}

export async function HappyHealthyDataApi() {  
  let res=await CallApi("/happy_healthy_data",{});
    console.log(res,":res")
  return res;
  }
  
  export async function AthleteDataApi() {  
    let res=await CallApi("/athlete_data",{});
      console.log(res,":res")
    return res;
    }
    export async function MembershipDetails(){
      let res = await CallApi("/membership_details");
      console.log(res, "MembershipDet res")
      return res;
    }
    export async function FitnessForeverDataApi() {  
      let res=await CallApi("/fitness_forever_data",{});
        console.log(res,":res")
      return res;
      }

    export async function haircareDataApi() {  
      let res=await CallApi("/hair_care_data",{});
        console.log(res,":hair_care_data")
      return res;
      }
    export async function skincareDataApi() {  
      let res=await CallApi("/skin_care_data",{});
        console.log(res,":skin_care_data")
      return res;
      }
      export async function eyecareDataApi() {  
        let res=await CallApi("/eye_care_data",{});
          console.log(res,":eye_care_data")
        return res;
        }
    export async function sleepsupportDataApi() {  
      let res=await CallApi("/sleep_support_data",{});
        console.log(res,":sleep_support_data")
      return res;
      }
    export async function jointhealthDataApi() {  
      let res=await CallApi("/joint_health_data",{});
        console.log(res,":joint_health_data")
      return res;
      }  
     export async function stressreliefDataApi() {  
       let res=await CallApi("/stress_relief_data",{});
         console.log(res,":stress_relief_data")
       return res;
       }  

     export async function digestionDataApi() {  
       let res=await CallApi("/digestion_data",{});
         console.log(res,":digestion_data")
       return res;
        }     
    export async function liverDataApi() {  
         let res=await CallApi("/liver_health_data",{});
           console.log(res,":liver_health_data")
         return res;
          }
    export async function weightgainDataApi() {  
            let res=await CallApi("/weight_gain_data",{});
              console.log(res,":liver_health_data")
            return res;
             }     
    export async function weightlossDataApi() {  
              let res=await CallApi("/weight_loss_data",{});
                console.log(res,":liver_health_data")
              return res;
               }
    export async function age_8_17DataApi() {  
      let res=await CallApi("/age_8_17",{});
        console.log(res,":age_8_17")
      return res;
       }        
       
    export async function age_18_27DataApi() {  
        let res=await CallApi("/age_18_27",{});
          console.log(res,":age_18_27")
        return res;
         }        

    export async function age_28_37DataApi() {  
          let res=await CallApi("/age_28_37",{});
            console.log(res,":age_28_37")
          return res;
           }  

    export async function age_38_50DataApi() {  
     let res=await CallApi("/age_38_50",{});
       console.log(res,":age_38_50")
     return res;
      }     
      
    export async function age_50_aboveDataApi() {  
        let res=await CallApi("/age_50_above",{});
          console.log(res,":age_50_above")
        return res;
         }      

    export async function nutrieatsDataApi() {  
        let res=await CallApi("/nutrieats_home",{});
            console.log(res,":nutrieats_home")
        return res;
           }     

     export async function healthysnacksDataApi() {  
      let res=await CallApi("/healthy_snacks",{});
          console.log(res,":healthy_snacks")
      return res;
         }     

     export async function fresh_healthyDataApi() {  
      let res=await CallApi("/fresh_healthy",{});
          console.log(res,":fresh_healthy")
      return res;
         }    
      export async function PeanutbutterDataApi() {  
          let res=await CallApi("/peanut_butter_data",{});
              console.log(res,":peanut_butter_data")
          return res;
             }     
      export async function allCategoriesDataApi() {  
        let res=await CallApi("/new/allcategories",{});
          console.log(res,":allcategories")
        return res;
        }
        export async function allbrandsDataApi() {  
          let res=await CallApi("/brands",{});
            console.log(res,":brands")
          return res;
          }
      
      export async function GetHeaderDetailsApi() {  
          let res=await CallApi("/header_retrive_data",{});
            console.log(res,":header_retrive_data")
          return res;
          }

export async function AddAddressApi(name,mobile,area,address2,landmark,address,pincode,markerPosition, activeButton, otherType) {
  try {
    let body = new FormData();
    body.append('name',name); 
    body.append('mobile', mobile);
    body.append('loc', markerPosition);
    body.append('loc_address',address);
    body.append('address_type', activeButton);
    body.append('address_line_1', area);
    body.append('address_line_2', address2);  
    body.append('pincode', pincode);  
    body.append('landmark', landmark);  
    body.append('other_address_type',otherType);  

    let res=await CallApi("/add_customer_address",body);
      console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }
}

export async function register_affiliate(payee_name,instagram_link,website_link,category,description,bank_name,bank_branch_name,bank_ifsc_code, bank_account_number, bank_upi_id) {
  try {
    let body = new FormData();
    body.append('payee_name',payee_name); 
    body.append('instagram_link',instagram_link); 
    body.append('website_link',website_link); 
    body.append('category',category); 
    body.append('description',description); 
    body.append('bank_name',bank_name); 
    body.append('bank_branch_name',bank_branch_name); 
    body.append('bank_ifsc_code',bank_ifsc_code); 
    body.append('bank_account_number',bank_account_number); 
    body.append('bank_upi_id',bank_upi_id); 

    let res=await CallApi("/register_affiliate",body);
      console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }
}


export async function CategoryWiseProducts(category_hash_id,page_number,brand_filter,sort_condition,price_filter,category_filter,discount_filter,selected_qnt,flavour_name) { 
  try {
    let body = new FormData();
    body.append('category_hash_id', category_hash_id);
    body.append('page_number', page_number);  
    body.append('brand_filter', JSON.stringify(brand_filter));  
    body.append('sort_condition', JSON.stringify(sort_condition));  
    body.append('price_filter', JSON.stringify(price_filter));  
    body.append('category_filter', JSON.stringify(category_filter));  
    body.append('discount_filter', JSON.stringify(discount_filter));  
    body.append('selected_qnt', JSON.stringify(selected_qnt));  
    body.append('flavour_name', JSON.stringify(flavour_name));  


    let res=await CallApi("/category_wise_products_list_for_app",body);
    console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }

}


export async function product_collection_items(collection_hash_id,page_number,brand_filter,sort_condition,price_filter,category_filter,discount_filter,selected_qnt,flavour_name) { 
  try {
    let body = new FormData();
    body.append('collection_hash_id', collection_hash_id);
    body.append('page_number', page_number);  
    body.append('brand_filter', JSON.stringify(brand_filter));  
    body.append('sort_condition', JSON.stringify(sort_condition));  
    body.append('price_filter', JSON.stringify(price_filter));  
    body.append('category_filter', JSON.stringify(category_filter));  
    body.append('discount_filter', JSON.stringify(discount_filter));  
    body.append('selected_qnt', JSON.stringify(selected_qnt));  
    body.append('flavour_name', JSON.stringify(flavour_name));  


    let res=await CallApi("/product_collection_items",body);
    console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }

}
export async function BrandWiseProducts(brand_hash_id,page_number,brand_filter,sort_condition,price_filter,category_filter,discount_filter,selected_qnt,flavour_name) { 
  try {
    let body = new FormData();
    body.append('brand_hash_id', brand_hash_id);
    body.append('page_number', page_number);  
    body.append('sort_condition', sort_condition); 
    body.append('price_filter', JSON.stringify(price_filter));  
    body.append('category_filter', JSON.stringify(category_filter));  
    body.append('discount_filter', JSON.stringify(discount_filter));  
    body.append('selected_qnt', JSON.stringify(selected_qnt));  
    body.append('flavour_name', JSON.stringify(flavour_name));  


    let res=await CallApi("/brand_products",body);
    console.log(res,":res")
    return res;
  } catch (e) {
    console.log('Error')
  }

}


export async function SearchProductsAPI(search_string,page_number,brand_filter,sort_condition,price_filter,category_filter,discount_filter,selected_qnt,flavour_name) { 
  try {
    let body = new FormData();
    body.append('search_string', search_string);
    body.append('page_num', page_number);  
    body.append('sort_condition', JSON.stringify(sort_condition));  
    body.append('selectedPrice', JSON.stringify(price_filter));   
    body.append('selectedDiscount', JSON.stringify(discount_filter));  
    body.append('selectedquantites', JSON.stringify(selected_qnt));  
    body.append('selectedFlavour', JSON.stringify(flavour_name));  
    body.append('selectedBrand', JSON.stringify(brand_filter)); 

    let res=await CallApi("/universal_search_products_list_for_app",body);
    console.log(res,":universal_search_products_list_for_app")
    return res;
  } catch (e) {
    console.log('Error')
  }

}
export async function UpdateFavStatusAPI(p_id) { 
  let body = new FormData();
  body.append('product_id', p_id); 
let res=await CallApi("/add_or_remove_favorite",body);
  console.log(res,":res")
return res;
}

export async function ClearUnavaialbeCartApiFunction() {   
  let res=await CallApi("/remove_unavaiable_items",{});
  console.log(res,":res")
return res;
}

export async function EditContactAPIFunction(add_id,name,mobile) { 
  let body = new FormData();
  body.append('address_id', add_id);   
  body.append('name', name);   
  body.append('mobile_number', mobile);   
  let res=await CallApi("/edit_address_contact",body);
  console.log(res,":res")
return res;
}

export async function ReviewListAPI(p_id) { 
  let body = new FormData();
  body.append('item_id', p_id); 
let res=await CallApi("/item_review_list",body);
  console.log(res,":res")
return res;
}


export async function SubmitProductReview(item_id,rating,review,title,ReviewFiles){
  console.log(item_id,"item_id")
  console.log(rating,"rating")
  console.log(review,"review")  
  console.log(title,"title");
  console.log(ReviewFiles,"ReviewFiles[]")
  let body = new FormData();
  body.append('item_id', item_id);  
  body.append("rating",rating);
  body.append("review",review);
  body.append("title",title);
  ReviewFiles.forEach((file, index) => {
    body.append(`ReviewFiles[${index}]`, file);
  });

  let res = await CallApi("/item_review",body);
  console.log(res, "item_review res")
  return res;
}

export async function AverageRatingListApi(p_id) { 
  let body = new FormData();
  body.append('item_id', p_id); 
let res=await CallApi("/customer_avg_review_ratings",body);
  console.log(res,":res")
return res;
}

export async function UniversalSearchAPI(search_string){
  let body = new FormData();
  body.append("search_string",search_string);
  let res = await CallApi("/universal_search",body);
  console.log(res,"/universal_search")
  return res;
}

export async function DefaultSearchAPI(){
  let res = await CallApi("/universal_search_default_page");
  console.log(res,"/universal_search_default_page")
  return res;
}

export async function ClearTagsAPI(id){
  let body = new FormData();
  body.append("tag_id",id);
  let res = await CallApi("/universal_search_delete_tag",body);
  console.log(res,"/universal_search_delete_tag")
  return res;
}

export async function AddTagsAPI(product_name, reftype, refid){
  let body = new FormData();
  body.append("product_name",product_name);
  body.append("reftype",reftype);
  body.append("refid",refid);
  let res = await CallApi("/add_search_product_name",body);
  console.log(res,"/add_search_product_name")
  return res;
}


export async function CouponsListAPI(order_amount){
  let body = new FormData();
  body.append("order_amount",order_amount);
  let res = await CallApi("/coupons_list",body);
  console.log(res,"/coupons_list")
  return res;
}

export async function ApplyCouponApi(coupon_id){
  let body = new FormData();
  body.append("coupon_id",coupon_id);
  let res = await CallApi("/apply_coupon",body);
  console.log(res,"/apply_coupon")
  return res;
}

export async function RemoveCoupon(){
  let body = new FormData();
  body.append("coupon_id","0");
  let res = await CallApi("/remove_coupon",body);
  console.log(res,"/remove_coupon")
  return res;
}

export async function RepeatOrder(order_id){
  let body = new FormData();
  body.append("order_id",order_id);
  let res = await CallApi("/repeat_order",body);
  console.log(res,"RepeatOrder res")
  return res;
  }
  export async function ItemWiseCoupons(product_id){
    let body = new FormData();
    body.append("product_id",product_id);
    let res = await CallApi("/item_wise_coupons_list",body);
    console.log(res,"item_wise_coupons")
    return res;
  }

  export async function UpdateAddressSelection(id){
    let body = new FormData();
    body.append("address_id",id);
    let res = await CallApi("/address_mark_as_default",body);
    console.log(res,"/address_mark_as_default")
    return res;
  }

  export async function NotifyMeApi(id,request_type){
    let body = new FormData();
    body.append("item_id",id);
    body.append("request_type",request_type);
    let res = await CallApi("/add_item_request_by_item_id",body);
    console.log(res,"/add_item_request_by_item_id")
    return res;
  }

  export async function FitPointDetails(fitpoint_id){
    let body = new FormData();
    body.append("fitpoint_id",fitpoint_id);
    let res = await CallApi("/fitpoints_details",body);
    console.log(res,"/fitpoints_details")
    return res;
  }
  
  export async function SubCategory(category_hash_id){
    let body = new FormData();
    body.append("category_hash_id",category_hash_id);
    let res = await CallApi("/sub_categories",body);
    console.log(res,"sub_categories");
    return res;
  }
  

  export async function AddItemRequest(item_name){
    let body = new FormData();
    body.append("item_name",item_name);
    let res = await CallApi("/add_item_request",body);
    console.log(res,"add_item_request");
    return res;
  }