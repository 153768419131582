import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button,Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { useCart } from './SubComponents/MiniComponents/CartContext.jsx';
import CartList from "./CartList";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Login from "./Login";
import { useMediaQuery } from '@mui/material';
function FooterJS() {
    const [cartshow, setcartShow] = React.useState(false);
    const isGuest = localStorage.getItem('is_guest') === "1"; // Check if the user is a guest
    const isnotGuest = !isGuest; // Determine if the user is not a guest

    const handlecartClose = () => setcartShow(false);
    const handlecaartShow = () => setcartShow(true);
    const isLargeScreen = useMediaQuery('(min-width: 992px)'); 
    const [showLoginModal,
        setshowLoginModal] = React.useState(false);
    
    const handleMapClose = () => setshowLoginModal(false);
const handleButtonClick = () => {
  if (isLargeScreen) {
   
    setshowLoginModal(true);
  } else {
    // Redirect to login page
    window.location.href = '/mobile-login';
  }
};
  return (

    <Row>
        <footer >
            <section className="section-padding footer-mid pt-50" style={{backgroundColor:"#023047"}}>
                <div className="container">
                    <div className="row">
                        <Col className="footer-link-widget order-1 order-md-1" xs={6} sm={6} md={3} lg={3}>
                            <h4 className="widget-title" style={{color:"#fff",fontSize:"30px"}}>Company</h4>
                            <ul className="footer-list mb-sm-0 mb-md-0">
                                <li><a href="/about-us">About Us</a></li> 
                                <li><a href="/shipping-policy">Shipping Policy</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/return-and-refund-policy">Return &amp; Refund Policy</a></li>
                                <li><a href="/terms-and-conditions">Terms &amp; Conditions</a></li>
                                {/* <li><a href="/contact-us">Contact Us</a></li>  */}
                                
                            </ul>
                        </Col>
                        <Col className="footer-link-widget order-3 order-md-2" xs={6} sm={6} md={3} lg={3}>
                            <h4 className="widget-title" style={{color:"#fff",fontSize:"30px"}}>Account</h4>
                            <ul className="footer-list mb-sm-0 mb-md-0">
                            {isnotGuest ? (
                <>
                    <li><a href="/order" className="pointer">Orders</a></li>
                    <li><a href="/wishlist" className="pointer">My Wishlist</a></li>
                    <li><a href="/profile" className="pointer">Profile</a></li>
                    <li><a href="/address-book" className="pointer">Address Book</a></li>
                    <li><a href="/fit-Cash" className="pointer">Fitcash</a></li>
                </>
            ) : (
                <li><a onClick={handleButtonClick} className="pointer" style={{ color: "white" }} >Login</a></li>
            )}
            <li><a onClick={handlecaartShow} className="pointer" style={{ color: "white" }}>View Cart</a></li>
            {/* <li><a href="/">Track My Order</a></li> */}
                            </ul>
                        </Col>
                        {/* <Col className="footer-link-widget order-2 order-md-3" xs={6} sm={6} md={3} lg={3}>
                            <h4 className="widget-title" style={{color:"#fff",fontSize:"30px"}}>Other Links</h4>
                            <ul className="footer-list mb-sm-0 mb-md-0">
                                <li><a href="/">Affiliate Program</a></li>
                                <li><a href="/">Become a Vendor</a></li>
                                <li><a href="/">Item Scan</a></li>
                            </ul>
                        </Col> */}
                        <Col className="footer-link-widget widget-install-app order-4 order-md-4" xs={6} sm={6} md={3} lg={3}>
                            <h4 className="widget-title" style={{color:"#fff",fontSize:"30px"}}>Payments</h4>
                            <p className="mb-20" style={{color:"#ffffff",fontSize:"18px"}}>Secured Payment Gateways</p>
                            <div className='cardsimgs' style={{backgroundColor:"#ffffff",padding:"10px",borderRadius:"10px",width:"260px"}}>
                                <img className="wow fadeIn animated animated" src="https://app.nutsby.com/public/common_assets/web/imgs/newhomepageimgs/visa.png" alt=""  rel="noopener noreferrer"  style={{visibility: "visible"}}/>
                                <img className="wow fadeIn animated animated" src="https://app.nutsby.com/public/common_assets/web/imgs/newhomepageimgs/mastercard.png" alt=""  rel="noopener noreferrer"   style={{visibility: "visible"}}/>
                                <img className="wow fadeIn animated animated" src="https://app.nutsby.com/public/common_assets/web/imgs/newhomepageimgs/maestro.png" alt=""  rel="noopener noreferrer"  style={{visibility: "visible"}}/>
                                <img className="wow fadeIn animated animated" src="https://app.nutsby.com/public/common_assets/web/imgs/newhomepageimgs/americanexpress.png"  rel="noopener noreferrer"  alt="" style={{visibility: "visible"}}/>
                            </div>
                            <div className='paymentsimgs' style={{backgroundColor: "#ffffff",padding: "10px",borderRadius: "10px",width: "260px",marginTop: "15px"}}>
                                <img className="wow fadeIn animated animated UPI" src="https://app.nutsby.com/public/common_assets/web/imgs/newhomepageimgs/UPI.png" alt=""  rel="noopener noreferrer"  style={{visibility: "visible"}}/>
                                <img className="wow fadeIn animated animated PhonePay" src="https://app.nutsby.com/public/common_assets/web/imgs/newhomepageimgs/PhonePe-Logo.png" alt=""  rel="noopener noreferrer"  style={{visibility: "visible"}}/>
                                <img className="wow fadeIn animated animated Gpay" src="https://app.nutsby.com/public/common_assets/web/imgs/newhomepageimgs/Googlepay.png" alt=""  rel="noopener noreferrer"  style={{visibility: "visible"}}/>
                            </div>
                        </Col>
                    </div>
                </div>
            </section>


            {/* <section className="section-padding footer-mid" style={{backgroundColor:"#001621"}}>
                <div className="container pt-15 pb-20">
                    <div className="row">
                        <Col className="footer-link-widget1" xs={12} sm={6} md={4} lg={3}>
                            <img src={require("../assets/images/nutsbylogo_footer.png")} alt="" style={{paddingTop:"30px", width:"auto"}}/>
                        </Col>
                        <Col className="footer-link-widget widget-title1 mt-30" xs={12} sm={6} md={4} lg={3}>
                            <h4 className="widget-title widget-title1" style={{color:"#fff",fontSize:"30px"}}>Address:</h4>
                            <span style={{color: "#fff"}}> A-46, Madhura Nagar, Hyderabad, <br/> Telangana, India - 500038.</span>
                        </Col>
                        <Col className="footer-link-widget widget-title1 mt-30" xs={12} sm={4} md={4} lg={2}>
                            <h4 className="widget-title" style={{color:"#fff",fontSize:"30px"}}>Email id:</h4>
                            <span><a href="mailto: care@nutsby.com" style={{color:"#ffffff"}}>care@nutsby.com</a></span>
                        </Col>
                        <Col className="footer-link-widget widget-title1 mt-30" xs={12} sm={4} md={4} lg={2}>
                            <h4 className="widget-title" style={{color:"#fff",fontSize:"30px"}}>Reach Us:</h4>
                            <span><a href="tel:+919963085543" style={{color:"#ffffff"}}>+91 99630 85543</a></span>
                        </Col>
                        <Col className="footer-link-widget" xs={12} sm={4} md={4} lg={2}>
                            <div style={{paddingTop:"60px"}} className='socialmedia_icons'>
                                <NavLink to="https://www.instagram.com/nutsbyofficial/" target='blank'>
                                    <img src={require("../assets/images/instagram.png")} alt="" style={{padding:"5px"}}/>
                                </NavLink>
                                <NavLink to="https://www.facebook.com/NutsbyOfficial" target='blank'>
                                    <img src={require("../assets/images/facebook.png")} alt="" style={{padding:"5px"}}/>
                                </NavLink>
                                <NavLink to="https://www.youtube.com/@NutsbyOfficial" target='blank'>
                                    <img src={require("../assets/images/youtube.png")} alt="" style={{padding:"5px"}}/>
                                </NavLink>
                                <NavLink to="https://www.linkedin.com/company/nutsbyofficial" target='blank'>
                                    <img src={require("../assets/images/linkedin.png")} alt="" style={{padding:"5px"}}/>
                                </NavLink>
                            </div>
                        </Col>
                        
                    </div>
                </div>
            </section> */}
            {/* <div className="footer-link-widget widget-install-app col-lg-3 col-md-4 col-sm-4">
                            <div className="mobile-social-icon" style={{justifyContent: "flex-start",marginTop:"25px"}}>
                            </div>
                        </div> */}

            <section className="section-padding footer-mid" style={{backgroundColor:"#001621"}}>
                <div className="container">
                    <div className="row">
                    
                        <div className="footer-link-widget col-lg-9 col-md-8 col-sm-8">
                        <p  style={{color:"#006881"}} className="font-sm mb-0 me-1">Copyright © 2024 , All Rights Reserved by Nutsby Fitcare LLP</p>
                        </div>
                        <div className="footer-link-widget col-lg-3 col-md-4 col-sm-4">
                        <p className="font-sm text1" style={{color:"#006881"}}>
                            Developed with <span style={{color:"#006881 !important"}}>❤</span> by&nbsp;
                            <a href="https://webgrid.in/" target="_blank"  rel="noopener noreferrer" style={{color:"#006881"}} className="text-gray-800 text-hover-primary">Web
                                Grid</a>
                        </p>
                        </div>
                    
                        
                    </div>
                </div>
                <Modal show={showLoginModal} onHide={handleMapClose} size="lg" >
                <Modal.Body style={{padding:0}} > 
               
                        <Login clss={handleMapClose} /> 
                </Modal.Body>
            </Modal>
            </section>

        </footer>
        <Offcanvas show={cartshow} onHide={handlecartClose} placement='end' style={{ width: "30rem", zindex:"10001"}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Cart</Offcanvas.Title>
        </Offcanvas.Header> 
        <CartList />               
      </Offcanvas>
    
    </Row>
  )
}

export default FooterJS
