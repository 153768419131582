import React, { useEffect, useState,useRef } from "react";
import {Container, Row, Col,Modal, Offcanvas, NavLink, Button } from 'react-bootstrap'; 
import { useNavigate, useParams } from 'react-router-dom';
import { CategoryWiseProducts,product_collection_items,AddToCart,viewsimilarpro,RemoveItemFromCart, BrandWiseProducts, NotifyMe, NotifyMeApi, GetHeaderDetailsApi, SubCategory, AddItemRequest } from '../Service/ApiCalling';
import { DiscountIcon, DownArrow, EliteIcon,EliteNewInfoIcon,NewAddToCartIconblue, FilterIcon } from '../MiniComponents/SvgIcons';
import {ReactComponent as EliteMewLogo} from './../assets/images/E_mem_fullLogo.svg';

import "../SubCss/ProductsList.css";
import Card from 'react-bootstrap/Card';
import InfiniteScroll from "react-infinite-scroll-component";
import Dropdown from 'react-bootstrap/Dropdown';
import ProductItemCard from "../MainComponents/SubComponents/MiniComponents/ProductItemCard";
import { useCart } from "../MainComponents/SubComponents/MiniComponents/CartContext";
import { Image, Shimmer } from 'react-shimmer'
import { ShimmerDiv,ShimmerContentBlock, ShimmerTitle,ShimmerButton } from "shimmer-effects-react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import BreadcrumbItem from 'react-bootstrap/BreadcrumbItem';
import Form from 'react-bootstrap/Form';
import MultiRangeSlider from "../Components/MultiRangeSlider";
import CartList from "../MainComponents/CartList";
import { ToastContainer, toast } from 'react-toastify';
import BottomBar from "./../MainComponents/SubComponents/Bottom"
export default function ProductsList() {

   
  const loaderRef = useRef(null);

  const [ActiveFilters, setActiveFilters] = useState("0");

  const [products_loading, setShimmer] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const shimmeritems = Array.from({ length: 16 });
  // Filters showing lists
  const [Brands, setBrands] = useState([]);
  const [collection_name, setcollection_name] = useState([]);
  
  const [categoryname, setcategoryname] = useState([]);
  const [Discounts, setDiscounts] = useState([]);
  const [Flavours, setFlavours] = useState([]);
  const [Prices, setPrices] = useState([]); 
  const [Quantites, setQuantites] = useState([]); 
  const [Subcategories,setSubCategories] = useState([]);
  const [subcategoryname,setsubcategoryname] = useState([]);
 
  // Filters showing lists

  // Filters applyed lists 
  const [ApplyedSort, setApplyedSort] = useState('');
  const [ApplyedSortShowName, setApplyedSortShowName] = useState('');
  const [is_subscribed,setIsSubscribed] = useState('');


  const [ApplyedBrands, setApplyedBrands] = useState([]);
  const [ApplyedDiscounts, setApplyedDiscounts] = useState([]);
  const [ApplyedFlavours, setApplyedFlavours] = useState([]);
  const [ApplyedPrices, setApplyedPrices] = useState([]); 
  const [ApplyedQuantites, setApplyedQuantites] = useState([]); 
  const [ApplyedCategories, setApplyedCategories] = useState([]); 
  // Filters applyed lists 

  const [PageNum, setPageNum] = useState(0); 
  const [ProductsCompletd, setProductsCompletd] = useState(false);

  const [Products, setProducts] = useState([]);
  const [list, setlist] = useState([]);
  const [PlanItemId, setPlanItemId] = useState(1);
  const [addClicked,setAddClicked] = useState(null);
  const [notifyClicked, setNotifyClicked] = useState(null);
  const [cartshow1, setcartShow1] = React.useState(false);
  const [similarproshow, setsimilarproshow] = React.useState(false);
  const [suggestprodsshow, setSuggestProdsShow] = React.useState(false);
 
  
  const handlecartClose1 = () => setcartShow1(false);
  const handlecaartShow1= () => setcartShow1(true);

  const handlesimilarClose1 = () => setsimilarproshow(false);
  const handlesimilarShow1= () => setsimilarproshow(true);

  const handleSuggestClose = () => setSuggestProdsShow(false);
  const handleSuggestShow= () => setSuggestProdsShow(true);


  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState('');
  const myRef = useRef();
 const params = useParams().id
 const type = useParams().type  
 const { setCartCount, setamount } = useCart();

 const handleSubmit = async (event) => {
  event.preventDefault();
  const form = event.currentTarget;

  console.log(description,"suggest desc");

  if (form.checkValidity() === false) {
    event.stopPropagation();
    
  } else {
    if(description.length>0){
      try {
        let Data = await AddItemRequest(description);
        if(Data!=null){
          if(Data.error==="0"){
            console.log(Data.messgae);
            setDescription('');
            handleSuggestClose();
          }else{
            console.log(Data.error);
          }
        }
  
        
      } catch (error) {
        console.error("Error submitting details:", error);
        toast.error("Failed to submit details.", { autoClose: 3000 });
      }
    }
    
  }
  setValidated(true);
};

  const fetchInfo=async (PNum)=> {
    // console.log(PNum,"ssss",PageNum)
    if(PageNum!==PNum || ApplyedSort!==""){
      // console.log("ssss")
      fetchCategories(params);
      console.log(ApplyedPrices,"kgsaueigelhi");

      
      if(type==="category"){
    

        let CategoryWiseProductsDatatemp = await CategoryWiseProducts(params,PNum,ApplyedBrands,ApplyedSort,ApplyedPrices,ApplyedCategories,ApplyedDiscounts,ApplyedQuantites,ApplyedFlavours,ApplyedCategories);
        if (CategoryWiseProductsDatatemp.error==="0") {
    
             let res=CategoryWiseProductsDatatemp;
             if(PNum>1)
              {
                const newArray = [...Products, ...res.product_list];
                //  console.log(newArray,"aaaa");
                 setProducts(newArray); 
              }else{ 
                 setProducts(res.product_list); 
              }
             
             setBrands(res.brands_list);
             setcategoryname(res.product_list[0].catgeory_name)
             console.log(res.product_list[0].catgeory_name,'categotywise products')
             setDiscounts(res.discounts);
             setFlavours(res.flavours);
             setPrices(res.prices);
             setQuantites(res.quantites);
    
             setPageNum(PNum);
            // setcartCount(CartListApiCallDatatemp.total_quantity);
            
            // setcartProducts(CartListApiCallDatatemp.cart_items);
            // setfitCastAvaiable(CartListApiCallDatatemp.fp_amount);
            // setaddressData(CartListApiCallDatatemp.selected_address);
            if(res.product_list.length===0){
              setProductsCompletd(true);
            }else{
              setProductsCompletd(false);
            }
            if (res.product_list.length < 16) {
              setHasMoreData(false);
            } else {
              setHasMoreData(true);
            }
            // console.log("Akash fetchInfo");
            setShimmer(false);
            // ApplyedPrices.filter( (ele, ind) => ind === ApplyedPrices.findIndex( elem => elem === ele && elem === 0))
    
        } else if(CategoryWiseProductsDatatemp.error==="1"){
          setHasMoreData(false);
          setProductsCompletd(true);
        }
        
        else{
          setProductsCompletd(true);
        }
      }else if(type==="brand"){
        let BrandWiseProductsDatatemp = await BrandWiseProducts(params,PNum,ApplyedBrands,ApplyedSort,ApplyedPrices,ApplyedCategories,ApplyedDiscounts,ApplyedQuantites,ApplyedFlavours);
        if (BrandWiseProductsDatatemp.error==="0") {
             let res=BrandWiseProductsDatatemp;
             if(PNum>1)
              {
                const newArray = [...Products, ...res.product_list];
                //  console.log(newArray,"aaaa");
                 setProducts(newArray); 
              }else{ 
                 setProducts(res.product_list); 
              }
             
             setBrands(res.brands_list);
             setDiscounts(res.discounts);
             setFlavours(res.flavours);
             setPrices(res.prices);
             setQuantites(res.quantites);
    
             setPageNum(PNum);
            // setcartCount(CartListApiCallDatatemp.total_quantity);
            
            // setcartProducts(CartListApiCallDatatemp.cart_items);
            // setfitCastAvaiable(CartListApiCallDatatemp.fp_amount);
            // setaddressData(CartListApiCallDatatemp.selected_address);
            if(res.product_list.length===0){
              setProductsCompletd(true);
            }else{
              setProductsCompletd(false);
            }
            // console.log("Akash fetchInfo");
            setShimmer(false);
            // ApplyedPrices.filter( (ele, ind) => ind === ApplyedPrices.findIndex( elem => elem === ele && elem === 0))
    
        }else if(BrandWiseProductsDatatemp.error==="1"){
          setHasMoreData(false);
          setProductsCompletd(true);
        } else{
          setProductsCompletd(true);
          
        }
      }else if(type==="collection"){


        let product_collection_itemsDatatemp = await product_collection_items(params,PNum,ApplyedBrands,ApplyedSort,ApplyedPrices,ApplyedCategories,ApplyedDiscounts,ApplyedQuantites,ApplyedFlavours);
        if (product_collection_itemsDatatemp.error==="0") {
    
             let res=product_collection_itemsDatatemp;
             if(PNum>1)
              {
                const newArray = [...Products, ...res.product_list];
                //  console.log(newArray,"aaaa");
                 setProducts(newArray); 
              }else{ 
                 setProducts(res.product_list); 
              }
              setcollection_name(res.collection_name);
             console.log(res.collection_name,'ttttttttttttttttttt');
             setBrands(res.brands);
             setDiscounts(res.discounts);
             setFlavours(res.flavours);
             setPrices(res.prices);
             setQuantites(res.quantites);
    
             setPageNum(PNum);
            // setcartCount(CartListApiCallDatatemp.total_quantity);
            
            // setcartProducts(CartListApiCallDatatemp.cart_items);
            // setfitCastAvaiable(CartListApiCallDatatemp.fp_amount);
            // setaddressData(CartListApiCallDatatemp.selected_address);
            if(res.product_list.length===0){
              setProductsCompletd(true);
            }else{
              setProductsCompletd(false);
            }
            if (res.product_list.length < 16) {
              setHasMoreData(false);
            } else {
              setHasMoreData(true);
            }
            // console.log("Akash fetchInfo");
            setShimmer(false);
            // ApplyedPrices.filter( (ele, ind) => ind === ApplyedPrices.findIndex( elem => elem === ele && elem === 0))
    
        }else if(product_collection_itemsDatatemp.error==="1"){
          setHasMoreData(false);
          setProductsCompletd(true);
        }
        
        else{
          setProductsCompletd(true);
          setShimmer(true);
        }
      }
    }  
    
   
};

const gotToNewPagesss = () => {
  // navigate("/membership_details");
  window.location.href = `/membership_details`;
}
const fetchCategories = async(category_id) =>{
  let Data = await SubCategory(category_id);
    if(Data.error==="0"){
      setSubCategories(Data.list);
      setsubcategoryname(Data.category_name)
      console.log(Data,"fetcgcategories");
    }
  
}

const fetchMoreData = () => {
  // console.log("fetchMore");
  fetchInfo(PageNum+1);
}
const handleCheck=(array,val) =>{
  let a=array.some(item => val === item);
  // console.log(a)
  return a;
};

const viewsimilar =async (p_id)=>{
  
  let viewsimilarprodata = await viewsimilarpro(p_id);
          if (viewsimilarprodata.error === "0") {
            handlesimilarShow1();
            setlist(viewsimilarprodata.products_list)
          }
}
const AddToCartCall=async (p_id,qnt,clicked,index)=> { 
  if(clicked === true){
      
    setAddClicked(index);
  }
  // AddToCart 
  // console.log(qnt)
  // if(qnt>0)
  //     {
          let addcartDatatemp = await AddToCart(p_id,qnt);
          if (addcartDatatemp.error === "0") {
              // fetchInfo();  
              //  handlecaartShow1();
                  const newList = Products.map((item) => {
                      if (item.product_id === p_id) {
                        const updatedItem = {
                          ...item,
                          product_total: addcartDatatemp.product_total,
                          product_total_mrp: addcartDatatemp.product_total_mrp,
                          cart_quantity: addcartDatatemp.cart_quantity,

                        };
                        setTimeout(function() {
            
                          setAddClicked(null);
                          
                        }, 1300);
                
                        return updatedItem;
                      } 
                      return item;
                    });
                    toast.success(addcartDatatemp.message, {
                      autoClose: 3000,
                    });
                    setProducts(newList); 
                  // setcartCount(addcartDatatemp.items_count);

                  // const newupdatedItem = {
                  //     ...cartCalculationData,
                  //     items_total: addcartDatatemp.cart_price,
                  //     total_price: addcartDatatemp.grand_total,
                  //     total_price: addcartDatatemp.grand_total_raw,

                  //   };
                  //   setcartCalculationData(newupdatedItem);
              // (e) =>setdetailsData((prevState) => ({ ...prevState, [e.target.name]: 1 }));
              // this.setState( detailsData.name="AKash");
            // console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC")
            const headerData = await GetHeaderDetailsApi();
              if (headerData.error === "0") {
                if(headerData.cart_count!=null){
                  setCartCount(headerData.cart_count);
                  setamount(headerData.cart_total)
                  
                }else{
                  setCartCount(0);
                  setamount(0)
                }
                
                // console.log(headerData.cart_count,"khfaosdufhuafs") 
              }
          }
      // }
 
};



const NotifyMeCall = async (p_id, type,clicked,index) => {
  if(clicked===true){
    setNotifyClicked(index);
  }
  try {
    let Data = await NotifyMeApi(p_id, type);
    if (Data.error === "0") {
      if (type === "stop") {
        Data.is_notified = "0";
        // console.log("is_stopped");
      } else {
        Data.is_notified = "1";
        toast.success(Data.message, {
          autoClose: 3000,
        });
        // console.log("is_notified");
      }
      const newList = Products.map((item) => {
        if (item.product_id === p_id) {
          const updatedItem = {
            ...item,
            is_notified: Data.is_notified,
          };
          setTimeout(function() {
            setNotifyClicked(null);
            
          }, 1100);
          return updatedItem;
        }
        return item;
      });
      setProducts(newList);
    } else {
      // console.log(Data.error);
    }
  } catch (error) {
    // console.log(error.message);
  }
};

useEffect(() => { // this hook will get called every time myArr has changed
  // perform some action every time myArr is updated
  setIsSubscribed(localStorage.getItem("is_subscribed"));
  
  // console.log("is_subscribed",is_subscribed);
  
  fetchInfo(1);
  // console.log('Updated State', ApplyedQuantites) 
}, [ApplyedQuantites])

useEffect(() => { // this hook will get called every time myArr has changed
  // perform some action every time myArr is updated
  console.log('Updated State', ApplyedPrices) 
  fetchInfo(1);
  
}, [ApplyedPrices])

useEffect(() => { // this hook will get called every time myArr has changed
  // perform some action every time myArr is updated
  fetchInfo(1);
  // console.log('Updated State', ApplyedFlavours) 
}, [ApplyedFlavours])

useEffect(() => { // this hook will get called every time myArr has changed
  // perform some action every time myArr is updated
  fetchInfo(1);
  // console.log('Updated State', ApplyedDiscounts) 
}, [ApplyedDiscounts])

useEffect(() => { // this hook will get called every time myArr has changed
  // perform some action every time myArr is updated
  fetchInfo(1);
  // console.log('Updated State', ApplyedBrands) 
}, [ApplyedBrands])

useEffect(() => { // this hook will get called every time myArr has changed
  // perform some action every time myArr is updated
  fetchInfo(1);
  // console.log('Updated State', ApplyedBrands) 
}, [ApplyedCategories])



useEffect(() => {
  // fetchInfo();
  // myRef.current.addEventListener('scroll', handleScroll);
  // return () => window.removeEventListener('scroll', handleScroll);
  const observer = new IntersectionObserver((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      // console.log("alkasj")
      fetchInfo(PageNum+1);
    }
  });
  
  // console.log(loaderRef,'loaderRef'); 

  if (loaderRef.current && ProductsCompletd===false) {
    // console.log("alkasj")
    observer.observe(loaderRef.current);
  }

  
  return () => {
    if (loaderRef.current) {
      // console.log("alkasj")
      observer.unobserve(loaderRef.current);
    }
  };
}, [fetchInfo]);


useEffect(() => { // this hook will get called every time myArr has changed
  // perform some action every time myArr is updated
  
  setProducts([]);
  fetchInfo(1);
  
  // console.log('Updated State', ApplyedSort) 
}, [ApplyedSort])

const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

useEffect(() => {
  if (Products.length > 0) {
    setShimmer(false);
  }
}, [Products]);

const isMobile = width <= 768;
 

const [cartshow, setcartShow] = React.useState(false);

const handlecartClose = () => setcartShow(false);
const handlecaartShow = () => setcartShow(true);

if(!products_loading){


  return (
    
    
    <Container  style={{paddingBottom: "20px",marginTop: ((isMobile)? "10rem":"10rem")}} >

              <Offcanvas show={similarproshow} onHide={handlesimilarClose1} placement='end' style={{ width: "rem" }}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Similar Products</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ padding: '0px 8px', height: '85%', overflowY: 'scroll' }}>
                  {list.length > 0 ? (
                    list.map((product, index) => (
                      <>
                      <Row>
                      <Col sm={6} md={6} xs={3} lg={3 } >
                          <img src={product.image} layout="fill" quality={100}  style={{margin:"0px !important",padding:"0px"}} rounded />
                      </Col>
                      <Col className="" sm={9} md={9} xs={9} lg={9}>
                          <Row>
                              <Col sm={11} md={11} xs={10} lg={11}>
                                  <p className="cart-item-name">{product.name}</p>
                              </Col>
                            
                          </Row>

                          <Row className="d-flex" sm={12} md={12} xs={12} lg={12}>
                              
                              {product.is_subscribed===1?
                                      <Container style={{paddingBottom:"3px"}} ><b className="premimum-tag-text1" >{product.elite_price}</b></Container>:
                                          <p style={{width:"auto"}}><b style={{fontSize:"18px"}}>{product.price}</b>&nbsp;<span style={{fontSize:"14px"}} className="mrp-strike">{product.mrp}</span></p>}
                              
                              

                                      {product.discount_percentage==="0"?<></>:
                                      
                                          <Container className="discount-tag" style={{width:"auto",height:"fit-content"}}>
                                          
                                              <DiscountIcon></DiscountIcon>

                                              &nbsp;
                                              <span style={{ fontSize: "10px" }}>{Math.round(product.discount_percentage)}% Off </span>

                                          </Container> 
                                      }
                              
                          </Row>
                          <Row className="d-flex justify-content-between">
                              <Col  sm={6} md={6} xs={6} lg={6}>
                                  {product.is_subscribed===1?
                                      <p><b style={{fontSize:"15px",textDecoration:"line-through"}}>{product.price}</b>&nbsp;<span style={{fontSize:"14px"}} className="mrp-strike">{product.mrp}</span></p>:
                                      <Row className="premimum-tag d-flex align-items-center" style={{width:"auto",paddingBottom:"3px",gap:"0px"}} >
                                          <Col xs={12} sm={12} md={12} lg={12} className="d-flex justify-content-baseline align-items-center">
                                          <EliteMewLogo style={{width:"100%"}}/>
                                              <b className="premimum-tag-text" >{product.elite_price}</b>
                                              &nbsp;
                                          
                                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 12" fill="none" style={{width:"50%",alignItems:"center"}} onClick={()=>gotToNewPagesss()}>
                                              <path d="M5.78298 0C2.58882 0 3.13776e-08 2.58882 3.13776e-08 5.78238C-7.90826e-05 6.54183 0.149448 7.29387 0.440042 7.99553C0.730636 8.69718 1.1566 9.33473 1.69362 9.87174C2.23064 10.4088 2.86818 10.8347 3.56984 11.1253C4.2715 11.4159 5.02353 11.5654 5.78298 11.5654C8.97594 11.5654 11.5654 8.97654 11.5654 5.78238C11.5654 2.58882 8.97594 0 5.78298 0ZM6.32267 2.08768C6.88645 2.08768 7.05209 2.41475 7.05209 2.78879C7.05209 3.2556 6.67865 3.68747 6.04078 3.68747C5.50711 3.68747 5.25293 3.41943 5.26859 2.97552C5.26859 2.60147 5.5812 2.08768 6.32267 2.08768ZM4.87828 9.24578C4.49279 9.24578 4.2115 9.01148 4.48074 7.9845L4.92225 6.16245C4.99875 5.87092 5.0114 5.75407 4.92225 5.75407C4.80721 5.75407 4.30667 5.95525 4.01153 6.15402L3.81938 5.839C4.75601 5.05657 5.83298 4.59759 6.29376 4.59759C6.67925 4.59759 6.7431 5.05296 6.55095 5.75407L6.045 7.66948C5.95525 8.00799 5.9938 8.12485 6.08354 8.12485C6.19919 8.12485 6.57746 7.98511 6.9497 7.69177L7.16774 7.9845C6.25641 8.89523 5.26317 9.24578 4.87828 9.24578Z" fill="white"/>
                                          </svg>
                                          
                                          </Col>
                                          
                                      
                                      </Row>
                                  }                            
                              </Col> 
                              
                              <Col sm={6} md={5} xs={6} lg={5}>
                                  {Number(product.cart_quantity)>0 ?
                                      <Container className="cart-inc-btn-radius" style={{width:"90%",float:"right"}}>
                                          <Row className="d-flex justify-content-center">
                                              <Col sm={4} md={4} xs={4} lg={4}>
                                              <Button ref={product.reference} className="light-greenes-blue cart-inc-btn " variant="transparent" onClick={() =>   { AddToCartCall(product.product_id,(Number(product.cart_quantity)-1))}}>-</Button>
                                              </Col>
                                              <Col sm={4} md={4} xs={4} lg={4}>
                                              <Button className="cart-inc-btn" variant="transparent">{product.cart_quantity}</Button>
                                              </Col>
                                              <Col sm={4} md={4} xs={4} lg={4}>
                                              <Button className="light-greenes-blue cart-inc-btn" variant="transparent" onClick={() =>   { AddToCartCall(product.product_id,(Number(product.cart_quantity)+1))}}>+</Button>
                                              </Col>
                                          </Row>
                                      </Container>: 
                                      <div className="cart-btn" style={{width:"75%",float:"right",height:"30px",backgroundColor:"#E7FAFF",textAlign:" -webkit-center",color:"#219EBC",borderRadius: "35px" }}  onClick={() =>  AddToCartCall(product.product_id,(Number(product.cart_quantity)+1))}>
                                      {/* onClick={() => AddToCartCall(data.product_id, (Number(data.cart_quantity) + 1))} */}
                                          
                                      {/* <NewAddToCartIconblue/> */}
                                      
                                      <span style={{fontSize:"16px"}}> Add</span>
                                      </div>
                                  }
                                  
                              </Col>
                          </Row>
                      </Col>
                  </Row>
                  <Row className="mt-10 mx-5">
                      <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                  </Row> 
                  </>
                    ))
                  ) : (
                    <p>No similar products found.</p>
                  )}
                </Offcanvas.Body>
              </Offcanvas>



               
               <Offcanvas
             show={cartshow1}
             onHide={handlecartClose1}
             placement='end'
             style={{
             width: "30rem"
         }}>
             <Offcanvas.Header closeButton>
                 <Offcanvas.Title>Cart</Offcanvas.Title>
             </Offcanvas.Header>
             <Offcanvas.Body>
                <CartList></CartList>
             </Offcanvas.Body>
                </Offcanvas>

              <Offcanvas show={cartshow} onHide={handlecartClose} placement='start' style={{width: "24rem",}}>
                   

                  <Container>
                    
                    <Row>
                      <Col xs={5} sm={5} md={5} lg={5} className="pt-5" style={{position: "absolute", height:"100%", backgroundColor:"#023047",paddingLeft:"0px",paddingRight:"0px"}}>
                        <div>
                          <div
                            onClick={() => setActiveFilters("0")}
                            style={{
                              padding: "1rem", 
                              backgroundColor: ActiveFilters === "0" ? "#002638" : "#023047", 
                              transition: "background-color 0.3s" 
                            }}
                          >
                            <h5 style={{color: "white",marginBottom:"0px"}}>Net Weight</h5>
                          </div>

                          {Brands.length > 1 && (
                            <div
                              onClick={() => setActiveFilters("1")}
                              style={{
                                padding: "1rem", 
                                backgroundColor: ActiveFilters === "1" ? "#002638" : "#023047", 
                                transition: "background-color 0.3s" 
                              }}
                            >
                              <h5 style={{color: "white",marginBottom:"0px"}}>Brand</h5>
                            </div>
                          )}

                          <div
                            onClick={() => setActiveFilters("2")}
                            style={{
                              padding: "1rem", 
                              backgroundColor: ActiveFilters === "2" ? "#002638" : "#023047", 
                              transition: "background-color 0.3s" 
                            }}
                          >
                            <h5 style={{color: "white",marginBottom:"0px"}}>Price</h5>
                          </div>

                          <div
                            onClick={() => setActiveFilters("3")}
                            style={{
                              padding: "1rem", 
                              backgroundColor: ActiveFilters === "3" ? "#002638" : "#023047", 
                              transition: "background-color 0.3s"
                            }}
                          >
                            <h5 style={{color: "white",marginBottom:"0px"}}>Flavour</h5>
                          </div>

                          <div
                            onClick={() => setActiveFilters("4")}
                            style={{
                              padding: "1rem", 
                              backgroundColor: ActiveFilters === "4" ? "#002638" : "#023047", 
                              transition: "background-color 0.3s" 
                            }}
                          >
                            <h5 style={{color: "white",marginBottom:"0px"}}>Discount</h5>
                          </div>

                          {Subcategories.length > 0 && (
                            <div
                              onClick={() => setActiveFilters("5")}
                              style={{
                                padding: "1rem", 
                                backgroundColor: ActiveFilters === "5" ? "#002638" : "#e0f4f4", /* Darker for active, lighter for inactive */
                                transition: "background-color 0.3s" 
                              }}
                            >
                              <h5 style={{color: "white",marginBottom:"0px"}}>Sub Categories</h5>
                            </div>
                          )}
                        </div>
                      </Col>

                        <Col xs={5} sm={5} md={5} lg={5}>
                        </Col>
                        <Col xs={7} sm={7} md={7} lg={7}>
                      <Offcanvas.Header closeButton style={{paddingTop:'20px' ,paddingBottom:'30px',paddingRight:'0px'}}> 
                      </Offcanvas.Header>
                      

                    {/* Net Weight  */}
                  {(ActiveFilters==="0") ? 
                  <>
                    <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"85%",overflow:"scroll",overflowX:"hidden"}}>
                      {Quantites.map((element) => {
                        let a=element.quantity;
                        return (
                          
                          <Row style={{padding:'10px'}}>
                            <Col  md={2} lg={2} xs={2} sm={2} className="text-start" >
                            <input  type="checkbox" checked={handleCheck(ApplyedQuantites,a)} className="checkbox" onChange={(event) =>{
                              
                              setPageNum(0)
                              if(event.target.checked)
                                {
                                  // console.log("checked",a)
                                  setApplyedQuantites([...ApplyedQuantites, a]);
                                }else{
                                  // console.log("unchecked",a) 
                                  let abb=ApplyedQuantites.splice(ApplyedQuantites.indexOf(a), 1); 
                                  setApplyedQuantites([...ApplyedQuantites]); 
                                }   
                                  // console.log(ApplyedQuantites,"=>ApplyedQuantites")
                              
                            
                              }} />
                            </Col>
                            <Col md={10} lg={10} xs={10} sm={10} className="text-start">
                            <span>{element.quantity+` `+element.weight_name + `(`+element.product_count+`)`}</span>
                            </Col>
                          </Row>
                        
                        );
                      })}
                      </Container>
                      {(Quantites.length>0) ? <Row className="mt-10 mx-3">
                          <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                      </Row> :""}
                  </>:""}
                    {/* Net Weight  */}

                    {/* Brands  */}
                  {(ActiveFilters==="1") ?
                  <>
                  <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"85%",overflow:"scroll",overflowX:"hidden"}}>
                  {Brands.map((element) => {
                     let a=element.id;
                    return (
                       
                      <Row style={{padding:'10px'}}>
                        <Col  md={2} lg={2} xs={2} sm={2}  className="text-start" >
                        <input  type="checkbox" checked={handleCheck(ApplyedBrands,element.id)} onChange={(event) =>{
                           
                           setPageNum(0)
                           if(event.target.checked)
                            {
                              // console.log("checked",a)
                              setApplyedBrands([...ApplyedBrands, a]);
                            }else{
                              // console.log("unchecked",a) 
                              let abb=ApplyedBrands.splice(ApplyedBrands.indexOf(a), 1); 
                              setApplyedBrands([...ApplyedBrands]); 
                            }   
                              // console.log(ApplyedBrands,"=>Applyedbrands")
                          
                         
                          }} className="checkbox" />
                        </Col>
                        <Col xs={10} sm={10} md={10} lg={10} className="text-start">
                        <span>{element.name+` (`+element.product_count+`)`}</span>
                        </Col>
                      </Row>
                   
                    );
                  })} </Container>
                  {(Brands.length>0) ? <Row className="mt-10 mx-3">
                      <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                  </Row> :""}

                  </>:""}
                  {/* Brands  */}


                  {(ActiveFilters==="2") ?
                  <>
                  
                  <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"85%",overflow:"scroll",overflowX:"hidden"}}>
                  {Prices.map((element) => {
                     let a=element.price_item.split("_"); 
                    return (
                     
                      <Row style={{padding:'10px'}}>
                        <Col xs={2} sm={2} md={2} lg={2}  className="text-start" >
                        <input  type="checkbox"  checked={handleCheck(ApplyedPrices,a[1])} onChange={(event) =>{
                          setPageNum(0);
                           
                           if(event.target.checked)
                            {
                              // console.log("checked",a[1])
                               setApplyedPrices([...ApplyedPrices, a[1]]);
                            }else{
                              // console.log("unchecked",a[1]) 
                              let abb=ApplyedPrices.splice(ApplyedPrices.indexOf(a[1]), 1);
                              // console.log(abb,"a,,a,a,")
                              // setApplyedPrices([]); 
                              setApplyedPrices([...ApplyedPrices]); 
                            }   
                              // console.log(ApplyedPrices,"=>ApplyedPrices")
                         
                            
                          
                         
                          }} className="checkbox" />
                        </Col>
                        <Col xs={10} sm={10} md={10} lg={10} className="text-start">
                        <span>{element.price+` (`+element.count+`)`}</span>
                        </Col>
                      </Row>
                   
                    );
                  })} </Container>
                  {(Prices.length>0) ? <Row className="mt-10 mx-3">
                      <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                  </Row> :""}
                  </>:""}
                  

                  {(ActiveFilters==="3") ?
                  <>
                  
                  <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"85%",overflow:"scroll",overflowX:"hidden"}}>
                  {Flavours.map((element) => {
                    let a=element.flavour; 
                    return (
                    
                      <Row style={{padding:'10px'}}> 
                        <Col  md={2} lg={2} xs={2} sm={2}  className="text-start" >
                        <input  type="checkbox" className="checkbox" checked={handleCheck(ApplyedFlavours,a)} onChange={(event) =>{
                           setPageNum(0)
                           
                           if(event.target.checked)
                            {
                              // console.log("checked",a)
                               setApplyedFlavours([...ApplyedFlavours, a]);
                            }else{
                              // console.log("unchecked",a) 
                              let abb=ApplyedFlavours.splice(ApplyedFlavours.indexOf(a), 1);
                              // console.log(abb,"a,,a,a,")
                              // setApplyedFlavours([]); 
                              setApplyedFlavours([...ApplyedFlavours]); 
                            }   
                              // console.log(ApplyedFlavours,"=>ApplyedFlavours") 
                         
                          }} />
                        </Col>
                        <Col md={10} lg={10} xs={10} sm={10}  className="text-start">
                        <span>{element.flavour+` (`+element.product_count+`)`}</span>
                        </Col>
                      </Row>
                    
                    );
                  })}</Container>
                  {(Flavours.length>0) ? <Row className="mt-10 mx-3">
                      <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                  </Row> :""}
                  </>:""}

                  {(ActiveFilters==="4") ?
                  <>                  
                 <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"85%",overflow:"scroll",overflowX:"hidden"}}>
                  {Discounts.map((element) => {
                    let a=element.split(" to ")[1]; 
                    return (
                     
                        <Row style={{padding:'10px'}}>
                          <Col  md={2} lg={2} xs={2} sm={2}  className="text-start" >
                          <input  type="checkbox" className="checkbox" checked={handleCheck(ApplyedDiscounts,a)} onChange={(event) =>{
                           
                           setPageNum(0)
                           if(event.target.checked)
                            {
                              // console.log("checked",a)
                              setApplyedDiscounts([...ApplyedDiscounts, a]);
                            }else{
                              // console.log("unchecked",a) 
                              let abb=ApplyedDiscounts.splice(ApplyedDiscounts.indexOf(a), 1);
                              // console.log(abb,"a,,a,a,")  

                              setApplyedDiscounts([...ApplyedDiscounts]); 
                            }   
                              // console.log(ApplyedDiscounts,"=>ApplyedDiscounts") 
                         
                          }} />
                          </Col>
                          <Col md={10} lg={10} xs={10} sm={10}  className="text-start">
                          <span>{(element.split(" to ")[0]==="0") ? element.split(" to ")[1]+"% or below" :((element.split(" to ")[0]+"% to "+element.split(" to ")[1]+"%"))}</span>
                          </Col>
                        </Row>
                      
                    );
                  })}
                  </Container>
                  {(Discounts.length>0) ? <Row className="mt-10 mx-3">
                      <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                  </Row> :""}</>:""}

                  {(ActiveFilters==="5") ?
                  <>

                  { <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"85%",overflow:"scroll",overflowX:"hidden"}}>

                  {Subcategories.length>0 &&
                  (Subcategories.map((element) => {
                    let a=element.id;
                    return (
                      
                      <Row style={{padding:'10px'}}>
                        <Col  md={2} lg={2} xs={2} sm={2} className="text-start" >
                        <input  type="checkbox" checked={handleCheck(ApplyedCategories,a)} className="checkbox" onChange={(event) =>{
                           
                           setPageNum(0)
                           if(event.target.checked)
                            {
                              // console.log("checked",a)
                              setApplyedCategories([...ApplyedCategories, a]);
                            }else{
                              // console.log("unchecked",a) 
                              let abb=ApplyedCategories.splice(ApplyedCategories.indexOf(a), 1); 
                              setApplyedCategories([...ApplyedCategories]); 
                            }   
                              // console.log(ApplyedQuantites,"=>ApplyedQuantites")
                          
                         
                          }} />
                        </Col>
                        <Col md={10} lg={10} xs={10} sm={10} className="text-start">
                        <span>{element.name}</span>
                        </Col>
                      </Row>
                    
                    );
                  }))
                  }
                  </Container>}

                  {(Quantites.length>0) ? <Row className="mt-10 mx-3">
                      <hr className="col-lg-12 col-md-12 col-sm-12 col-12" style={{color:"#D9D9D9"}}></hr>    
                  </Row> :""}
                  </>:""}
                      </Col>
                    </Row>
                  </Container>
                 
              </Offcanvas> 

              <Offcanvas
            show={cartshow1}
            onHide={handlecartClose1}
            placement='end'
            style={{
            width: "30rem"
        }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Cart</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <CartList></CartList>
            </Offcanvas.Body>
              </Offcanvas>
              
            <Row>
              <Col>
              <Container>
                <Button onClick={handlecaartShow} style={{border:"solid 0.5px #cccccc"}} className="DropDownProductListBtn d-block d-md-none d-lg-none"><FilterIcon/>&nbsp; Filters</Button>
              </Container>
              
              </Col>
              <Col>
                <Container className="text-end pb-3 d-block d-md-none d-lg-none">  
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic"  className="DropDownProductListBtn">
                      {(ApplyedSortShowName==="" ? "Sort By":ApplyedSortShowName)} &nbsp; <DownArrow/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdownmenu1">
                    <Container style={{padding:'0px'}}>
                      <Dropdown.Item onClick={()=>{
                        setPageNum(0);
                        if(ApplyedSort==="featured")
                          {
                            setApplyedSort(""); 
                            setApplyedSortShowName(""); 
                          }else{
                            setApplyedSort("featured");
                            setApplyedSortShowName("Recommended"); 
                          }
                          
                      }}
                      style={{
                        backgroundColor: ApplyedSort === "featured" ? "transparent" : "transparent",
                        color: ApplyedSort === "featured" ? "black" : "#909090",
                        fontWeight: ApplyedSort === "featured" ? "600" : "400",
                      }}
                      >
                          <Row>
                            <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                              <input checked={(ApplyedSort==="featured")? true:false}  type="checkbox" className="checkbox checkbox1" />
                              <span style={{fontSize:"smaller"}}>Recommended</span>
                            </Col>
                          </Row>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={()=>{
                        setPageNum(0);
                        if(ApplyedSort==="release_date_h_t_l")
                          {
                            setApplyedSort("");
                            setApplyedSortShowName("");  
                          }else{
                            setApplyedSort("release_date_h_t_l");
                            setApplyedSortShowName("Newest First");
                          }

                      }}
                      style={{
                        backgroundColor: ApplyedSort === "release_date_h_t_l" ? "transparent" : "transparent",
                        color: ApplyedSort === "release_date_h_t_l" ? "black" : "#909090",
                        fontWeight: ApplyedSort === "release_date_h_t_l" ? "600" : "400",
                      }}>
                          <Row>
                            <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                            <input checked={(ApplyedSort==="release_date_h_t_l")? true:false}  type="checkbox" className="checkbox checkbox1" />
                            <span style={{fontSize:"smaller"}}>Newest First</span>
                            </Col>
                          </Row>
                      </Dropdown.Item>
                      

                      {/* <Dropdown.Item onClick={()=>{
                        setPageNum(0);
                        if(ApplyedSort==="release_date_h_t_l")
                          {
                            setApplyedSort("");
                            setApplyedSortShowName("");  
                          }else{
                            setApplyedSort("release_date_h_t_l");
                            setApplyedSortShowName("Newest First");
                          }
                      }}>
                          <Row>
                            <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                            <input checked={(ApplyedSort==="release_date_h_t_l")? true:false}  type="checkbox" className="checkbox checkbox1" />
                            <span>Ascending A-Z</span>
                            </Col>
                          </Row>
                      </Dropdown.Item> */}

                      <Dropdown.Item onClick={()=>{
                        setPageNum(0);
                        if(ApplyedSort==="rating")
                          {
                            setApplyedSort("");
                            setApplyedSortShowName("");  
                          }else{
                            setApplyedSort("rating");
                            setApplyedSortShowName("Popularity");
                          }
                      }}
                      style={{
                        backgroundColor: ApplyedSort === "rating" ? "transparent" : "transparent",
                        color: ApplyedSort === "rating" ? "black" : "#909090",
                        fontWeight: ApplyedSort === "rating" ? "600" : "400",
                      }}>
                          <Row>
                            <Col  xs={12} sm={12} md={12} lg={12} className="text-start" >
                              <input checked={(ApplyedSort==="rating")? true:false}  type="checkbox" className="checkbox checkbox1" />
                              <span style={{fontSize:"smaller"}}>Popularity</span>
                            </Col>
                          </Row>
                      </Dropdown.Item>

                      <Dropdown.Item onClick={()=>{
                        setPageNum(0);
                        if(ApplyedSort==="price_low_to_high")
                          {
                            setApplyedSort("");
                            setApplyedSortShowName("");  
                          }else{
                            setApplyedSort("price_low_to_high");
                            setApplyedSortShowName("Price - Low to High");
                          }
                      }}
                      style={{
                        backgroundColor: ApplyedSort === "price_low_to_high" ? "transparent" : "transparent",
                        color: ApplyedSort === "price_low_to_high" ? "black" : "#909090",
                        fontWeight: ApplyedSort === "price_low_to_high" ? "600" : "400",
                      }}>
                          <Row>
                            <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                              <input checked={(ApplyedSort==="price_low_to_high")? true:false}  type="checkbox" className="checkbox checkbox1" />
                              <span style={{fontSize:"smaller"}}>Price - Low to High</span>
                            </Col>
                          </Row>
                      </Dropdown.Item>

                      <Dropdown.Item onClick={()=>{
                        setPageNum(0);
                        if(ApplyedSort==="price_high_to_low")
                          {
                            setApplyedSort("");
                            setApplyedSortShowName("");  
                          }else{
                            setApplyedSort("price_high_to_low");
                            setApplyedSortShowName("Price - High to Low");
                          }
                      }}
                       style={{
                        backgroundColor: ApplyedSort === "price_high_to_low" ? "transparent" : "transparent",
                        color: ApplyedSort === "price_high_to_low" ? "black" : "#909090",
                        fontWeight: ApplyedSort === "price_high_to_low" ? "600" : "400",
                      }}>
                          <Row>
                            <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                              <input checked={(ApplyedSort==="price_high_to_low")? true:false}  type="checkbox" className="checkbox checkbox1" />
                              <span style={{fontSize:"smaller"}}>Price - High to Low</span>
                            </Col>
                          </Row>
                      </Dropdown.Item>
                     
                          <Dropdown.Item onClick={() => {
                          setPageNum(0);
                          setApplyedSort(""); 
                                setApplyedSortShowName("Sort By"); 
                    }}>
                    <Row>
                    <Col xs={12} style={{ textAlign: "right" }}>
                   <span style={{ color: "#008EB0",fontSize:"smaller" }}>Clear All</span>
                 </Col>
                    </Row>
                   </Dropdown.Item>
                      
                      </Container>
                      
                    </Dropdown.Menu>
                  </Dropdown>
                </Container>
              </Col>
            </Row>

           
      {/* <h1 className="d-none d-md-block  d-lg-block d-sm-block">Top Grossing Products</h1>
      
      <Image src={require("../assets/images/Frame 13.png")} alt="" fallback={<Shimmer  height={250} className="fall-back-shimmer"/>}
      /> */}
    
      
          {/* Sorting view  */}
            <Container>
              <Row>
                <Col xs={12} sm={12} md={8} lg={8} className="d-none d-md-block  d-lg-block"> 
                <Breadcrumb >
  <Breadcrumb.Item onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}>Home</Breadcrumb.Item>
  <Breadcrumb.Item href="#">
    {/* Display the brand name based on the selected type */}
    {type === "brand" && Brands?.[0]?.name 
      ? Brands[0].name 
      : type === "category"
      ? subcategoryname
      : type === "collection" 
      ? collection_name 
      : "Select Brand"}
  </Breadcrumb.Item>
</Breadcrumb>

                </Col>

                <Col xs={12} sm={12} md={4} lg={4}>
                  <Container className="text-end pb-3 d-none d-md-block  d-lg-block">  
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic"  className="DropDownProductListBtn">
                        {(ApplyedSortShowName==="" ? "Sort By":ApplyedSortShowName)} &nbsp; <DownArrow/>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                      <Dropdown.Item
  onClick={() => {
    setPageNum(0);
    if (ApplyedSort === "featured") {
      setApplyedSort("");
      setApplyedSortShowName("");
    } else {
      setApplyedSort("featured");
      setApplyedSortShowName("Recommended");
    }
  }}
  // Apply the selected style conditionally
  style={{
    backgroundColor: ApplyedSort === "featured" ? "transparent" : "transparent",
    color: ApplyedSort === "featured" ? "black" : "#909090",
    fontWeight: ApplyedSort === "featured" ? "600" : "400",
  }}
>
  <Row>
    <Col xs={12} sm={12} md={12} lg={12} className="text-start">
      <input
        checked={ApplyedSort === "featured"}
        type="checkbox"
        className="checkbox checkbox1"
      />
      <span>Recommended</span>
    </Col>
  </Row>
</Dropdown.Item>

                        <Dropdown.Item onClick={()=>{
                          if(ApplyedSort==="release_date_h_t_l")
                            {
                              setApplyedSort("");
                              setApplyedSortShowName("");  
                            }else{
                              setApplyedSort("release_date_h_t_l");
                              setApplyedSortShowName("Newest First");
                            }
                        }} style={{
                          backgroundColor: ApplyedSort === "release_date_h_t_l" ? "transparent" : "transparent",
                          color: ApplyedSort === "release_date_h_t_l" ? "black" : "#909090",
                          fontWeight: ApplyedSort === "release_date_h_t_l" ? "600" : "400",
                        }}>
                            <Row>
                              <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                                <input checked={(ApplyedSort==="release_date_h_t_l")? true:false}  type="checkbox" className="checkbox checkbox1" />
                                <span>Newest First</span>
                              </Col>
                            </Row>
                        </Dropdown.Item>

                        {/* <Dropdown.Item onClick={()=>{
                          if(ApplyedSort==="release_date_h_t_l")
                            {
                              setApplyedSort("");
                              setApplyedSortShowName("");  
                            }else{
                              setApplyedSort("release_date_h_t_l");
                              setApplyedSortShowName("Newest First");
                            }
                        }}
                        style={{
                          backgroundColor: ApplyedSort === "release_date_h_t_l" ? "transparent" : "transparent",
                          color: ApplyedSort === "release_date_h_t_l" ? "black" : "#909090",
                          fontWeight: ApplyedSort === "release_date_h_t_l" ? "600" : "400",}}>
                          
                            <Row>
                              <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                                <input checked={(ApplyedSort==="release_date_h_t_l")? true:false}  type="checkbox" className="checkbox checkbox1" />
                                <span>Ascending A-Z</span>
                              </Col>
                            </Row>
                        </Dropdown.Item> */}

                        <Dropdown.Item onClick={()=>{
                          if(ApplyedSort==="rating")
                            {
                              setApplyedSort("");
                              setApplyedSortShowName("");  
                            }else{
                              setApplyedSort("rating");
                              setApplyedSortShowName("Popularity");
                            }
                        }}
                        style={{
                          backgroundColor: ApplyedSort === "rating" ? "transparent" : "transparent",
                          color: ApplyedSort === "rating" ? "black" : "#909090",
                          fontWeight: ApplyedSort === "rating" ? "600" : "400",
                        }}>
                            <Row>
                              <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                                <input checked={(ApplyedSort==="rating")? true:false}  type="checkbox" className="checkbox checkbox1" />
                                <span>Popularity</span>
                              </Col>
                            </Row>
                        </Dropdown.Item>

                        <Dropdown.Item onClick={()=>{
                          if(ApplyedSort==="price_low_to_high")
                            {
                              setApplyedSort("");
                              setApplyedSortShowName("");  
                            }else{
                              setApplyedSort("price_low_to_high");
                              setApplyedSortShowName("Price - Low to High");
                            }
                        }} style={{
                          backgroundColor: ApplyedSort === "price_low_to_high" ? "transparent" : "transparent",
                          color: ApplyedSort === "price_low_to_high" ? "black" : "#909090",
                          fontWeight: ApplyedSort === "price_low_to_high" ? "600" : "400",
                        }}>
                          
                            <Row>
                              <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                                <input checked={(ApplyedSort==="price_low_to_high")? true:false}  type="checkbox" className="checkbox checkbox1" />
                                <span>Price - Low to High</span>
                              </Col>
                            </Row>
                        </Dropdown.Item>

                        <Dropdown.Item onClick={()=>{
                          if(ApplyedSort==="price_high_to_low")
                            {
                              setApplyedSort("");
                              setApplyedSortShowName("");  
                            }else{
                              setApplyedSort("price_high_to_low");
                              setApplyedSortShowName("Price - High to Low");
                            }
                        }} style={{
                          backgroundColor: ApplyedSort === "price_high_to_low" ? "transparent" : "transparent",
                          color: ApplyedSort === "price_high_to_low" ? "black" : "#909090",
                          fontWeight: ApplyedSort === "price_high_to_low" ? "600" : "400",
                        }}>
                            <Row>
                              <Col  xs={12} sm={12} md={12} lg={12}  className="text-start" >
                                <input checked={(ApplyedSort==="price_high_to_low")? true:false}  type="checkbox" className="checkbox checkbox1" />
                                <span>Price - High to Low</span>
                              </Col>
                            </Row>
                        </Dropdown.Item>
                       
                     <Dropdown.Item onClick={() => {
                     setPageNum(0);
                     setApplyedSort(""); 
                           setApplyedSortShowName("Sort By"); 
               }}>
               <Row>
               <Col xs={12} style={{ textAlign: "right" }}>
              <span style={{ color: "#008EB0" }}>Clear All</span>
            </Col>
               </Row>
              </Dropdown.Item>
                 

                      
        
                      </Dropdown.Menu>
                    </Dropdown>
                  </Container>
                </Col>
              </Row>
            </Container>

          
          {/* Sorting view  */}
          <Row>
            <Col  lg={3} md={4}  sm={4} className='d-md-block d-lg-block d-sm-none d-none'>
              {Subcategories.length>0?<Card className='text-center shadow-sm p-3 mb-3 bg-white' style={{border:"1px solid #D9D9D9", borderRadius:"25px"}}>

                {(Subcategories.length>0) ? <h5 className="text-start text_start_vertical" style={{alignItems:"center",display:"flex",fontWeight:"500"}}>Categories</h5> :""} 
                <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"120px",overflow:"scroll",overflowX:"hidden"}}>
                {Subcategories.length>0 && (Subcategories.map((element) => {
                  return (
                    
                    <Row className="checkbox_Row">
                      <Col  md={1} lg={1}  className="text-start" >
                      <input  type="checkbox" onChange={(event) =>{
                          let a=element.id; 
                          setPageNum(0);
                          if(event.target.checked)
                            {
                              console.log("checked",a)
                              setApplyedCategories([...ApplyedCategories, a]);
                            }else{
                              // console.log("unchecked",a) 
                              let abb=ApplyedCategories.splice(ApplyedCategories.indexOf(a), 1); 
                              setApplyedCategories([...ApplyedCategories]); 
                            }   
                              // console.log(ApplyedQuantites,"=>ApplyedQuantites")
                          
                        
                          }} className="checkbox" />
                      </Col>
                      <Col md={10} lg={10} className="text-start" style={{fontWeight:"500"}}>
                      <span className="checkbox_label">{element.name}</span>
                      </Col>
                    </Row>
                  
                  );
                }))}
                </Container>
              </Card>:<></>}

              <Card className='text-center shadow-sm p-3 mb-3 bg-white' style={{border:"1px solid #D9D9D9", borderRadius:"25px"}}>
                {(Prices.length>0) ? <h5 className="text-start text_start_vertical" style={{alignItems:"center",display:"flex",fontWeight:"500"}}>Price</h5> :""}
                <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"120px",overflow:"scroll",overflowX:"hidden"}}>
                  {Prices.map((element) => {
                    return (
                    
                      <Row className="checkbox_Row">
                        <Col  md={1} lg={1}  className="text-start" >
                        <input  type="checkbox" onChange={(event) =>{
                          let a=element.price_item.split("_"); 
                          setPageNum(0);
                          if(event.target.checked)
                            {
                              // console.log("checked",a[1])
                              setApplyedPrices([...ApplyedPrices, a[1]]);
                            }else{
                              // console.log("unchecked",a[1]) 
                              let abb=ApplyedPrices.splice(ApplyedPrices.indexOf(a[1]), 1);
                              // console.log(abb,"a,,a,a,")
                              // setApplyedPrices([]); 
                              setApplyedPrices([...ApplyedPrices]); 
                            }   
                              // console.log(ApplyedPrices,"=>ApplyedPrices")
                        
                            
                          
                        
                          }} className="checkbox" />
                        </Col>
                        <Col md={10} lg={10} className="text-start" style={{fontWeight:"400"}}>
                        <span className="checkbox_label" style={{fontWeight:"400"}}>{element.price+` (`+element.count+`)`}</span>
                        </Col>
                      </Row>
                  
                    );
                  })} 
                </Container>
              </Card> 

              <Card className='text-center shadow-sm p-3 mb-3 bg-white' style={{border:"1px solid #D9D9D9", borderRadius:"25px"}}>
                {(Quantites.length>0) ? <h5 className="text-start text_start_vertical" style={{alignItems:"center",display:"flex",fontWeight:"500"}}>Net Weight</h5> :""} 
                <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"120px",overflow:"scroll",overflowX:"hidden"}}>
                {Quantites.map((element) => {
                  return (
                    
                    <Row className="checkbox_Row">
                      <Col  md={1} lg={1}  className="text-start" >
                      <input  type="checkbox" onChange={(event) =>{
                          let a=element.quantity; 
                          setPageNum(0);
                          if(event.target.checked)
                            {
                              // console.log("checked",a)
                              setApplyedQuantites([...ApplyedQuantites, a]);
                            }else{
                              // console.log("unchecked",a) 
                              let abb=ApplyedQuantites.splice(ApplyedQuantites.indexOf(a), 1); 
                              setApplyedQuantites([...ApplyedQuantites]); 
                            }   
                              // console.log(ApplyedQuantites,"=>ApplyedQuantites")
                          
                        
                          }} className="checkbox" />
                      </Col>
                      <Col md={10} lg={10} className="text-start" >
                      <span className="checkbox_label" style={{fontWeight:"400"}}>{element.quantity+` `+element.weight_name+`(`+element.product_count+`)`}</span>
                      </Col>
                    </Row>
                  
                  );
                })}
                </Container>
              </Card>  
              
              <Card className='text-center shadow-sm p-3 mb-3 bg-white' style={{border:"1px solid #D9D9D9", borderRadius:"25px"}}>
                {(Brands.length>0) ?<h5 className="text-start text_start_vertical" style={{alignItems:"center",display:"flex",fontWeight:"500"}}>Brand</h5> :""}
                <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"120px",overflow:"scroll",overflowX:"hidden"}}>
                  { Brands.map((element) => {
                    return (
                      
                      <Row className="checkbox_Row">
                        <Col  md={1} lg={1}  className="text-start" >
                        <input  type="checkbox" onChange={(event) =>{
                          let a=element.id; 
                          setPageNum(0)
                          if(event.target.checked)
                            {
                              // console.log("checked",a)
                              setApplyedBrands([...ApplyedBrands, a]);
                            }else{
                              // console.log("unchecked",a) 
                              let abb=ApplyedBrands.splice(ApplyedBrands.indexOf(a), 1); 
                              setApplyedBrands([...ApplyedBrands]); 
                            }   
                              // console.log(ApplyedBrands,"=>Applyedbrands")
                          
                        
                          }} className="checkbox" />
                        </Col>
                        <Col md={10} lg={10} className="text-start" style={{fontWeight:"400"}}>
                        <span>{element.name+` (`+element.product_count+`)`}</span>
                        </Col>
                      </Row>
                  
                    );
                  })}
                </Container>
              </Card>  

               
              <Card className='text-center shadow-sm p-3 mb-3 bg-white' style={{border:"1px solid #D9D9D9", borderRadius:"25px"}}>
                {(Flavours.length>0) ? <h5 className="text-start text_start_vertical" style={{alignItems:"center",display:"flex",fontWeight:"500"}}>Flavour</h5> :""}
                <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"120px",overflow:"scroll",overflowX:"hidden"}}>
                  {Flavours.map((element) => {
                    return (
                    
                      <Row className="checkbox_Row">
                        <Col  md={1} lg={1}  className="text-start" >
                        <input  type="checkbox" className="checkbox" onChange={(event) =>{
                          let a=element.flavour; 
                          setPageNum(0)
                          if(event.target.checked)
                            {
                              // console.log("checked",a)
                              setApplyedFlavours([...ApplyedFlavours, a]);
                            }else{
                              // console.log("unchecked",a) 
                              let abb=ApplyedFlavours.splice(ApplyedFlavours.indexOf(a), 1);
                              // console.log(abb,"a,,a,a,")
                              // setApplyedFlavours([]); 
                              setApplyedFlavours([...ApplyedFlavours]); 
                            }   
                              // console.log(ApplyedFlavours,"=>ApplyedFlavours") 
                        
                          }} />
                        </Col>
                        <Col md={10} lg={10} className="text-start" style={{fontWeight:"500"}}>
                        <span className="checkbox_label">{element.flavour+` (`+element.product_count+`)`}</span>
                        </Col>
                      </Row>
                    
                    );
                  })}
                </Container>
              </Card>  
              <Card className='text-center shadow-sm p-3 mb-3 bg-white' style={{border:"1px solid #D9D9D9", borderRadius:"25px"}}>
                {(Discounts.length>0) ? <h5 className="text-start text_start_vertical" style={{alignItems:"center",display:"flex",fontWeight:"400"}}>Discount</h5> :""}
                <Container className="aaaaaaaaa" style={{padding:"0px 8px",height:"120px",overflow:"scroll",overflowX:"hidden"}}>
                {Discounts.map((element) => {
                  return (
                  
                      <Row className="checkbox_Row">
                        <Col  md={1} lg={1}  className="text-start" >
                        <input  type="checkbox" className="checkbox" onChange={(event) =>{
                        let a=element.split(" to ")[1]; 
                        setPageNum(0)
                        if(event.target.checked)
                          {
                            // console.log("checked",a)
                            setApplyedDiscounts([...ApplyedDiscounts, a]);
                          }else{
                            // console.log("unchecked",a) 
                            let abb=ApplyedDiscounts.splice(ApplyedDiscounts.indexOf(a), 1);
                            // console.log(abb,"a,,a,a,")  

                            setApplyedDiscounts([...ApplyedDiscounts]); 
                          }   
                            // console.log(ApplyedDiscounts,"=>ApplyedDiscounts") 
                      
                        }} />
                        </Col>
                        <Col md={10} lg={10} className="text-start" style={{fontWeight:"400"}}>
                        <span className="checkbox_label">{(element.split(" to ")[0]==="0") ? element.split(" to ")[1]+"% or below" :((element.split(" to ")[0]+"% to "+element.split(" to ")[1]+"%"))}</span>
                        </Col>
                      </Row>
                    
                  );
                })}
                </Container>
              </Card>
            </Col>

            
            <Col lg={9} md={8} sm={12} xs={12}>
            
              <Row ref={myRef} style={{padding:'0px'}}> 
            
                <InfiniteScroll  style={{overflow:"visible"}}
                dataLength={Products.length}
                next={fetchMoreData}
                hasMore={hasMoreData}
                initialScrollY={true}
                loader={<Container className="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div> 
                </Container>}
              >
                
                  <Container className='row' style={{padding:'0px',margin:"0px"}}>
                  
                    {Products.map((element) => {
                      return (
                      <Col xs={6} sm={6} md={6} lg={4} xl={3} style={{ padding: "3px" }}> 
                      <ProductItemCard
                      
                      add_cart={AddToCartCall}
                      url_title={element.url_title}
                      view_similar={viewsimilar}
                      image={element.image}
                      rate={element.average_rating}
                      dis={element.discount_percentage}
                      name={element.name}
                      tag={element.item_tag}
                      elite_price={element.elite_price}
                      saleprice={element.price}
                      mrp={element.mrp}
                      cart_qnt={element.cart_quantity}
                      id={element.product_id}
                      notified={element.is_notified}
                      max_qnty={element.max_cart_quantity}
                      nuin={element.nuin}
                      stock={element.stock}
                      notify_me = {NotifyMeCall}
                      is_subscribed = {is_subscribed}
                      clicked = {addClicked === element.product_id}
                      notifyClicked = {notifyClicked === element.product_id}
                      index = {element.product_id}
                      />

                        {/* <div className="border-0 bg-white rounded" >
                          <div className="productcard-item">
                      <div className="productimage-conatiner" style={{backgroundColor:"#F7F7F7",borderStartEndRadius:"35px",borderStartStartRadius:"35px"}}>
              
                    {(Number(element.discount_percentage)===0) ? <div class="discount" style={{backgroundColor:"transparent",color:"transparent",padding:"5px 20px",borderTopRightRadius:"42px",borderBottomLeftRadius:"42px",float:"inline-end",}}> <p style={{height:"6px"}}></p></div>:
                    <div class="discount" style={{backgroundColor:"#E3FAFF",color:"black",padding:"5px 20px",borderTopRightRadius:"42px",borderBottomLeftRadius:"42px",float:"inline-end"}}><DiscountIcon/> <span style={{fontSize:"13.5px"}}>{Math.round(element.discount_percentage)}% off</span></div>}

                    <div > 
                      <a reloadDocument  
                      >
                        <img className="img-fluid " src={element.image} alt="Image 1" style={{ borderRadius: "20px" }} />
                      
                      </a>
                    </div>

                    <div>
                      {element.average_rating === "0.0" ? ("") :
                        
                        <div className="productrating" style={{width: "60px",backgroundColor:"#ff9900",padding: "0 8px 2px",borderRadius: "35px",color:"white",position:"absolute",marginTop:"-30px",marginLeft:"10px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 22">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          &nbsp;
                          {element.average_rating}  
                        </div>
                      
                      } 
                    </div>

                  </div>


                  <div className="bottom-container" style={{border:"solid 0.8px #DBDEE9",borderBottomRightRadius:"35px",borderBottomLeftRadius:"35px"}}>
                  <Container>
                    <div   >  
                    <NavLink
                        reloadDocument
                        to={`/product_details/` + element.product_id + `/` + element.name}
                        className="item_name"
                      >
                        {element.name}
                      </NavLink>
                     </div>

                    {(element.item_tag === "") ? <div className="Noitem-tag inter-regular">
                      <span>
                        &nbsp;</span>
                    </div> :
                    <div className="item-tag inter-regular" style={{backgroundColor:"#F5F5F5", borderRadius:"42px",color:"#AEB1BD",fontSize:"10px",overflowWrap:"anywhere",overflow:"hidden",fontWeight:"500",padding:"0px 10px",marginBottom:"10px",display:"inline-block"}}>
                      <span>{element.item_tag}
                        </span>
                    </div>
                    } 
                    <div style={{ margin: "0px" }}>

                      <div style={{backgroundColor: "black",borderRadius: "16px",paddingLeft:"5px",paddingBottom:"2px",gap: "10px",display:"inline-block",paddingRight:"5px"  }}>
                      <EliteIcon></EliteIcon>
                        <span style={{fontSize:"12.5px",background: "linear-gradient(to right, #FBE4BA, #9E7829)",webkitBackgroundClip: "text", webkitTextFillColor:"transparent" }}>&nbsp; 
                          {element.elite_price}
                        
                          </span>

                      </div>


                      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        
                        <span className="product-card-price" style={{fontSize:"18px"}}>
                          {element.price}
                          
                        </span>

                        {(Number(element.discount_percentage)>0) ? <span className="product-card-mrp" >
                          
                          <s style={{color: "#959595",fontSize: "13px"}}> {element.mrp}</s>
                        </span>:"" }
                        



                      </div>
                    </div> 
                      <Row className={`mt-10 mb-2 `}>
                      
                      <Col lg={12} md={12} sm={12} xs={12}> 
                        {element.cart_quantity > 0 ?
                            <Container className="cart-inc-btn-radius" style={{width:"100%",height:"30px"}}>
                            <Row>
                                <Col sm={4} md={4} xs={4} lg={4}>
                                <Button className="light-greenes-blue cart-inc-btn " variant="transparent" onClick={() =>  AddToCartCall(element.product_id,(Number(element.cart_quantity)-1))}>-</Button>
                                </Col>
                                <Col sm={4} md={4} xs={4} lg={4}>
                                <Button className="cart-inc-btn" variant="transparent">{element.cart_quantity}</Button>
                                </Col>
                                <Col sm={4} md={4} xs={4} lg={4}>
                                <Button className="light-greenes-blue cart-inc-btn" variant="transparent" onClick={() =>  AddToCartCall(element.product_id,(Number(element.cart_quantity)+1))}>+</Button>
                                </Col>
                            </Row>
                        </Container>
                            
                            :
                          <div className="parent-container " style={{width:"100%",height:"30px"}}>
                            <div className="cart-btn" style={{backgroundColor:"#219EBC",textAlign:" -webkit-center",color:" white",borderRadius: "35px",marginLeft: "5px",padding: "2px",marginRight: "5px"}}  onClick={() =>  AddToCartCall(element.product_id,(Number(element.cart_quantity)+1))}>
                                 
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6.85041 8.1745C6.85041 8.57031 6.52955 8.89117 6.13373 8.89117C5.73791 8.89117 5.41706 8.57031 5.41706 8.1745V6.74115H4.70038C3.97848 6.74115 3.37174 7.26733 3.26911 7.96874L1.84966 18.7144L1.84854 18.7225C1.72518 19.5809 2.39552 20.358 3.28033 20.358H13.043C13.4388 20.358 13.7596 20.6788 13.7596 21.0746C13.7596 21.4705 13.4388 21.7913 13.043 21.7913H3.28033C1.53411 21.7913 0.1831 20.2532 0.429152 18.5229L1.84871 7.7766L1.84983 7.7685C2.05321 6.35332 3.2708 5.3078 4.70038 5.3078H5.41706V4.59112C5.41706 2.21627 7.34226 0.291077 9.71711 0.291077C12.0919 0.291077 14.0172 2.21627 14.0172 4.59112V5.3078H14.7338C16.1624 5.3078 17.3793 6.35181 17.5839 7.76549L18.3091 12.5462C18.3684 12.9376 18.0993 13.3029 17.708 13.3623C17.3167 13.4216 16.9513 13.1525 16.8919 12.7612L16.1664 7.97791L16.1656 7.97239C16.0645 7.26916 15.457 6.74115 14.7338 6.74115H14.0172V8.1745C14.0172 8.57031 13.6963 8.89117 13.3005 8.89117C12.9047 8.89117 12.5838 8.57031 12.5838 8.1745V6.74115H6.85041V8.1745ZM6.85041 5.3078H12.5838V4.59112C12.5838 3.00789 11.3003 1.72443 9.71711 1.72443C8.13386 1.72443 6.85041 3.00789 6.85041 4.59112V5.3078Z"
                                  fill="white" />
                                <path
                                  d="M16.8834 14.6245C17.2792 14.6245 17.6001 14.9454 17.6001 15.3412V17.4912H19.7501C20.1459 17.4912 20.4668 17.8121 20.4668 18.2079C20.4668 18.6037 20.1459 18.9246 19.7501 18.9246H17.6001V21.0746C17.6001 21.4704 17.2792 21.7913 16.8834 21.7913C16.4876 21.7913 16.1667 21.4704 16.1667 21.0746V18.9246H14.0167C13.6209 18.9246 13.3 18.6037 13.3 18.2079C13.3 17.8121 13.6209 17.4912 14.0167 17.4912H16.1667V15.3412C16.1667 14.9454 16.4876 14.6245 16.8834 14.6245Z"
                                  fill="white" />
                              </svg> 
                              <span> Add</span>
                            </div>
                          </div>
                        }

                      </Col>
                      
                      </Row>
                      </Container>

                        </div>
                          </div>


                        </div>  */}
                    </Col> );
                    })}
                  </Container>
               
              </InfiniteScroll>
              {hasMoreData===false&&(

                
                <>
                <h1 className=""  style={{color:'#BCBCBC',fontWeight:"800",fontStyle:"italic"}}>Oops!</h1>
                <div className=""  style={{color:'#BCBCBC',fontWeight:"400"}}>Looks like you’ve not find your products</div>
                <div className="d-flex align-items-center" onClick={()=>{setSuggestProdsShow(true)}}>
                <span style={{color:"#219EBC"}} >
                      Suggest a Product   <svg width="7" height="auto"  viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8561 14.6182C10.8437 14.6079 10.8272 14.6037 10.8148 14.5934L2.85282 7.38696C2.40487 6.98442 1.67823 6.98442 1.23028 7.38696C1.22615 7.39109 1.22409 7.39522 1.22202 7.39935C1.11356 7.4889 1.02595 7.60107 0.965339 7.72801C0.904725 7.85494 0.87256 7.99358 0.871093 8.13423L0.871094 22.5492C0.873408 22.6925 0.907146 22.8335 0.969925 22.9622C1.0327 23.091 1.12299 23.2044 1.23441 23.2944L1.23028 23.2986C1.45567 23.4934 1.74364 23.6006 2.04155 23.6006C2.33946 23.6006 2.62743 23.4934 2.85282 23.2986L10.8561 16.0756C10.9615 15.9857 11.0462 15.874 11.1042 15.7482C11.1622 15.6223 11.1923 15.4854 11.1923 15.3469C11.1923 15.2083 11.1622 15.0714 11.1042 14.9456C11.0462 14.8198 10.9615 14.7081 10.8561 14.6182Z" fill="#008EB0"/>
                      </svg> &nbsp;
                    </span>
                </div>

                
                </>



              )}

              <Offcanvas show={suggestprodsshow} onHide={handleSuggestClose} placement='end' >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Suggest a Product</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ padding: '0px 8px', height: '85%', overflowY: 'scroll' }}>
                
                <Col lg={12} md={12} sm={12} xs={12}>
                
              <Form onSubmit={handleSubmit}>
                  <Row>
                    
                      <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label style={{fontFamily:"Inter",fontWeight:'400',color:'#BCBCBC'}}>Didn't find what you're looking for? Please suggest the product</Form.Label>
                        <Form.Control
                          required
                          name="name"
                          as="textarea"
                          rows={5}
                          type="text"
                          className="form-control custom-placeholder"
                          placeholder="Type Product & Brand Name"
                          style={{ backgroundColor: "#F4F5FA", fontFamily: 'Inter',border:'none'}}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          
                        />                        
                      </Form.Group>
                    
                    
                      
                        <Button variant="primary" type="" style={{ width:"90%", backgroundColor: "#FFB703", color: "black", borderRadius: "25px", margin: "14px",fontFamily:"Inter"}} align="end" onClick={handleSubmit}>
                          Suggest
                        </Button>
                      
                    
                  </Row>
                </Form>
              </Col>


                </Offcanvas.Body>
              </Offcanvas>


              </Row>
              {/* {(!ProductsCompletd) ? <Container className="text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div> 
              </Container> :""} */}
              
            </Col>
          </Row>
          <BottomBar/>
          <ToastContainer />
    </Container>
    
  )
}else{


  return (<Container className="mt-150" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
    <Col xs={3} sm={3} md={3} lg={3}>
      
    <ShimmerTitle mode="light" line={1} gap={8} style={{height:'20px'}}/>

    </Col>
    

    <Row>
    <Col md={3} lg={3} sm={3} className="d-none d-md-block">
  <ShimmerDiv className="productdivshimer" mode="light" height={100} width={100} style={{ paddingBottom: "20px" }} />
  <ShimmerDiv className="productdivshimer" mode="light" height={100} width={100} />
  <ShimmerDiv className="productdivshimer" mode="light" height={100} width={100} />
  <ShimmerDiv className="productdivshimer" mode="light" height={100} width={100} />
</Col>

      <Col xs={12} sm={12} md={9} lg={9}>
    
    
    <Row>
    {shimmeritems.map((_, index) => (
    <Col xs={6} sm={6} md={4} lg={3}>
    <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
    </Col>
    ))}
    </Row>
     
     
      </Col>
    </Row>
</Container>);

}
}
