
import "../SubCss/SlidersCss.css";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
// import Overlay from 'react-bootstrap/Overlay';
import { useState,useEffect } from 'react';
import { HomePage ,AddToCart, OrderAgainProductsApi, TopGrossingProductsApi, NotifyMeApi, GetHeaderDetailsApi,allbrandsDataApi} from '../Service/ApiCalling';
import { Helmet } from 'react-helmet-async';
import ProductCard from "../MainComponents/SubComponents/ProductCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BrandCard from "../MainComponents/SubComponents/BrandCard";
import SubscribeForm from "../MainComponents/SubscribeForm";
import DownloadTheApp from "../MainComponents/DownloadTheApp";
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import '../fonts/Inter-Regular.ttf';
import '../fonts/Michroma.ttf';
import '../fonts/nicomoji-regular.ttf';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ReactSearchBox from "react-search-box";
import { DiscountIcon, EliteIcon, HeaderCartIcon } from "../MiniComponents/SvgIcons";
import ProductItemCard from "../MainComponents/SubComponents/MiniComponents/ProductItemCard";
import { useCart } from "../MainComponents/SubComponents/MiniComponents/CartContext";
import React from "react";
import Lottie from "lottie-react";
import singlearrow from '../assets/lottie/single-arrow.json';
import tripplearrow from '../assets/lottie/tripple-arrow-forward.json';
import hearticon from '../assets/lottie/heart-is-beating.json';
import {ExploreIcon} from "../MiniComponents/SvgIcons";
import { ShimmerDiv,ShimmerContentBlock, ShimmerTitle,ShimmerButton, ShimmerCategoryItems } from "shimmer-effects-react";
import "../SubCss/home.css"
import MetaTags from '../MainComponents/Metatags'
import { ToastContainer, toast } from 'react-toastify';
import BottomBar from "./../MainComponents/SubComponents/Bottom";
import ReactGA from 'react-ga4';
import { bottombarshow } from "../Service/ApiNames";
function Home() {
  const [TrendingProducts, setTrendingProducts] = useState([]);
  const [orderagainProducts, setorderagainProducts] = useState([]);
  const [brandPro, setbrandPro] = useState([]);
  const [brandid, setbrandid] = useState([]);
//   const [loading, setloading] = useState(true);


const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [is_subscribed,setIsSubscribed] = useState('');
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [error, setError] = useState(null);
  const [addClicked,setAddClicked] = useState(null);
  const [notifyClicked,setNotifyClicked] = useState(null);
  const { setCartCount } = useCart();
  const [sliders, setsliders] = useState([]);
  const[productofday,setproductofday] = useState([]);
  const [productloading, setloading] = useState(true);
  const shimmeritems = Array.from({ length: 6 });
  const shimmeritems1 = Array.from({ length: 2 });
  const ess_shimmeritems = Array.from({ length: 4 })
 
  const main_shimmeritems = Array.from({ length: 2 });
  const [activeIndex, setActiveIndex] = useState(0);

  const fetchInfo=async ()=> {  
    let HomeApiData = await HomePage();
    if (HomeApiData.error==="0") {
      setsliders(HomeApiData.first_slider);
      setproductofday(HomeApiData.todays_collection_products)
      // setTrendingProducts(HomeApiData.trending_products)
      console.log("here1");
      TopGrosingProducts();
      OrderAgainProductsApiCall();
      fetchAllBrandsData();
        setloading(false); 
      
    }else{
      // setloading(false);
    }
  }
  const handleSlideChange = (_, { currentSlide }) => {
    setActiveIndex(currentSlide);
  };

  const gotToNewPagesss = (hash_id,type,url) => {
        // navigate("/membership_details");
        // href={"/Products/Brand/"+element.id}
        if(type.toLowerCase() === "product collection"){
          window.location.href = `/${url}/${type.toLowerCase()}/${hash_id}`;
      } else if(type.toLowerCase() === "brand"){
          window.location.href = `/${url}/${type.toLowerCase()}/${hash_id}`;
      }
      console.log(type.toLowerCase(), ":type");
      
        
        
      }
 
      const fetchAllBrandsData = async () => {
        let allbrandsDataApiData = await allbrandsDataApi(); 
        if (allbrandsDataApiData.error === "0") {
            setbrandPro(allbrandsDataApiData.name); 
            setbrandid(allbrandsDataApiData.id) 
            setloading(false);  
        } else {
            console.error("Error fetching brands data:", allbrandsDataApiData.error);
            // setloading(false); 
        }
    };

  const TopGrosingProducts = async ()=>{
    let TopGrossingProductsApiData = await TopGrossingProductsApi();
    if (TopGrossingProductsApiData.error==="0") {
      
      setTrendingProducts(TopGrossingProductsApiData.products)
     
      setloading(false); 
    }
  }

  const OrderAgainProductsApiCall = async ()=>{
    let OrderAgainProductsApiData = await OrderAgainProductsApi();
    if (OrderAgainProductsApiData.error==="0") {
      setorderagainProducts(OrderAgainProductsApiData.products)
      setloading(false); 
    }
  }
 

  const AddToCartCall = async (p_id, qnt, clicked, index, max_qnt) => { 
    // AddToCart 
    console.log(qnt);
    
    if (clicked === true) {
        setAddClicked(index);
    }
           
    try {
        let addcartDatatemp = await AddToCart(p_id, qnt);
        
        if (addcartDatatemp.error === "0") {
          // analytics.app(AddToCart)
          ReactGA.event({
            category: 'add to cart',
            action: 'Add TO cart',
            label: 'Purchase',
            value:'',
          });
          toast.success(addcartDatatemp.message, {
            autoClose: 3000,
          });
            const updatedTrendingProducts = TrendingProducts.map((item) => {
                if (item.product_id === p_id) {
                    return {
                        ...item, 
                        cart_quantity: addcartDatatemp.cart_quantity
                    };
                } 
                return item;

            });
            
            // Update trending products state
            setTrendingProducts(updatedTrendingProducts);
            
            // Update orderagain products state
            const updatedOrderagainProducts = orderagainProducts.map((item) => {
                if (item.item_id === p_id) {
                    return {
                        ...item, 
                        cart_quantity: addcartDatatemp.cart_quantity
                    };
                } 
                return item;
            });
            
            // Update the state for orderagainProducts
            setorderagainProducts(updatedOrderagainProducts);
            
            // Reset clicked state after a delay
            setTimeout(() => {
                setAddClicked(null);
            }, 1300);
            
            // Fetch and update header details
            const headerData = await GetHeaderDetailsApi();
            if (headerData.error === "0") {
                setCartCount(headerData.cart_count || 0);
                console.log(headerData.cart_count, "khfaosdufhuafs");
               
            }
         
        }
    } catch (error) {
        console.error('Error adding to cart:', error);
    }
};

  const NotifyMeCall = async (p_id, type,clicked,index) => {
    if(clicked===true){
      setNotifyClicked(index);
    }
    try {
      let Data = await NotifyMeApi(p_id, type);
      if (Data.error === "0") {
        if (type === "stop") {
          Data.is_notified = "0";
          console.log("is_stopped");
        } else {
          Data.is_notified = "1";
          console.log("is_notified");
          toast.success(Data.message, {
            autoClose: 3000,
          });
        }
        const newList = TrendingProducts.map((item) => {
          if (item.product_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            setTimeout(function(){
              setNotifyClicked(null);
            },1100);
            return updatedItem;
          }
          return item;
        });
        // setorderagainProducts(newList);
        setTrendingProducts(newList);



        const newList1 = orderagainProducts.map((item) => {
          if (item.item_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            setTimeout(function(){
              setNotifyClicked(null);
            },1100);
            return updatedItem;
          }
          return item;
        });
        setorderagainProducts(newList1);
        // setTrendingProducts(newList);
      } else {
        console.log(Data.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  
useEffect(() => {
  
  console.log('Component mounted, requesting location...');
    
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log('Location retrieved:', position);
        setLocation({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
        setError(null);
        console.log('Latitude:', location.lat);
        console.log('Longitude:', location.lon);
        localStorage.setItem('Latitude',location.lat);
        localStorage.setItem('Longitude',location.lon);
        
        console.log('Location retrieved:', location);  
        
      },
      (err) => {
        console.error('Error retrieving location:', err.message);
        setError(err.message);
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
    setError('Geolocation is not supported by this browser.');
  }

console.log(location.lat,location.lon,"Locaton Current");



  setIsSubscribed(localStorage.getItem("is_subscribed"));
  
  console.log("is_subscribed",is_subscribed);
  fetchInfo(); 
  console.log("here");
}, []);

const productsresponsive = {
  superLargeDesktop: {
   
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  minidesktop: {
    breakpoint: { max: 1495, min: 1200 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1200, min: 900 },
  items: 4,
},
minitablet: {
  breakpoint: { max: 900, min: 464 },
items: 3,
},
mobile: {
  breakpoint: { max: 464, min: 0 },
  items: 3, 
}}

const productsresponsive1 = {
  superLargeDesktop: {
   
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  minidesktop: {
    breakpoint: { max: 1495, min: 1200 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1200, min: 900 },
  items: 4,
},
minitablet: {
  breakpoint: { max: 900, min: 464 },
items: 3,
},
mobile: {
  breakpoint: { max: 464, min: 0 },
  items: 3, 
}
}; 
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };  
  const stayfitcarousel = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 1, // Moves 4 items at a time
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // Moves 4 items at a time
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // Moves 2 items at a time
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3, // Moves 1 item at a time
    },}  

  const PamperHealthCarousel = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 900 },
      items: 3,
    },
    minitablet: {
      breakpoint: { max: 900, min: 768 },
    items: 3,
  },
    mobile: {
      breakpoint: { max: 767, min: 481 },
    items: 2,
  },
    minmobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2,
    },
  };  
  
  
  const Productresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }; 

//   const firebaseConfig = {
// apiKey: "AIzaSyDyPuSoh9YxGXpQCrtUscQOX9R6KHD6Zac",
// authDomain: "nustby-mobile-app.firebaseapp.com",
// projectId: "nustby-mobile-app",
// storageBucket: "nustby-mobile-app.appspot.com",
// messagingSenderId: "655313227049",
// appId: "1:655313227049:web:d9294f22b32410b66f3b38",
// measurementId: "G-5TBP909THY"
// };

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
  
  if(!productloading){
  return (
    <>
     {/* <Container>
      <Row>
        <Col xs={12} md={6}>
          Column 1
        </Col>        
      </Row>
      <Row>
      <Col xs={12} md={6}>
          Column 2
        </Col>
      </Row>
    </Container> */}
    
   <Helmet>
   <meta name="google-site-verification" content="xgkTLIx1gHPoEatzkv2hDJXBT3ioRG_AvrgdleE9Zic" />
   </Helmet>
<MetaTags

        title="Nutsby All in One Nutrition Store | Building a Healthy You"
        description="At Nutsby, we're dedicated to offering health & wellness products at the best prices. Our mission is to support your journey towards a healthier, happier you with a diverse selection of reliable products and unbeatable service. "
        keywords="Health Supplements,
        Fitness Supplements,
        Nuts & Dried Fruits,
        Protein Supplements,
        Sports Nutrition,
        protein powder,
        high quality dried fruits,
        protein supplements for weight loss,
        protein supplements for muscle gain,
        protein supplement store,
        Ayurvedic Products"
        canonical="https://nutsby.com"
        url="https://nutsby.com"
        image="https://nutsby.com/assets/nutsby.png" 
        alt="Nutsby Fitness Products"
        ogTitle="Nutsby All in One Nutrition Store | Building a Healthy You"
        ogUrl="https://nutsby.com"
        ogDescription="Join Nutsby in our journey to make a healthy and fit lifestyle the universal norm. Explore our wide range of fitness products."
        ogImage="https://nutsby.com/assets/nutsby.png" 

        indexing="index, follow"
        schema={{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Nutsby Fitcare",
          "url": "https://nutsby.com",
          "logo": "https://nutsby.com/assets/nutsby.png",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-1234567890",
            "contactType": "Customer Service",
          },
        }}
      />
{


    <div style={{ }}>  
   
      <div onClick={()=>{}} >
        <div className="mainCarousel1 d-none d-md-block d-lg-block d-sm-block container " style={{paddingBottom: "20px" ,marginTop:'1rem',paddingTop:'0px'}} > 
        <Row>
  <Col lg={12} md={12} sm={12} col={12}>
    <Carousel responsive={responsive} autoPlay={true} infinite={true} arrows={true} draggable={true}>
      {sliders && sliders.length > 0 ? (
        sliders.map((slider, index) => (
          <div
            key={index}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
          >
            <img
              src={slider.web_image}
              alt={slider.alt_text}
              draggable="false"
              style={{ width: '99%',objectFit: "fill"}} 
              onClick={() => {
                gotToNewPagesss(slider.hash_id, slider.ref_type,slider.url_title);
              }}
            />
          </div>
        ))
      ) : (
        <div>No slides available</div>
      )}
    </Carousel>
  </Col>
</Row>

        </div>
        <div className="mainCarousel1 d-block d-md-none d-lg-none d-sm-none container" style={{paddingBottom: "0px" ,paddingTop:'0px',marginTop:'2rem'}} >
            <Row>
              <Col lg={12} md={12} sm={12} col={12}>
                <Carousel responsive={responsive} arrows={true} draggable={true} autoPlay={true} infinite={true}>
                {sliders && sliders.length > 0 ? (
                  sliders.map((slider, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                      <img
                        src={slider.image}
                        draggable="false"
                        alt={slider.alt_text}
                        style={{ width: '97%', height: '90%' }}
                        onClick={() => {
                          gotToNewPagesss(slider.hash_id, slider.ref_type,slider.url_title);
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div>No slides available</div> 
                )}

                </Carousel>  
              </Col>                      
            </Row> 
        </div>  
      </div>
  {/* </section> */}  
 
  {/* <!-- second section --> */}
  <section className="fitnessgoals container">
    <div className="px-3 mt-1">
        <Row>
          <Col lg={3} md={3} sm={3} xs={6} style={{padding:"3px"}}>
            <div className="card1">
              <NavLink to={'/maingoal/muscle-building'}>
                <div className="image-container mb-2">
                  <img src={require("../assets/images/PNG/MG1.png")} alt="muscle building supplements for men"/>
              </div>
              </NavLink>
            </div>
          </Col>

          <Col lg={3} md={3} sm={3} xs={6} style={{padding:"3px"}}>
            <div className="card1">
              <NavLink  to={'/maingoal/athelete-journey'}>
                <div className="image-container mb-2">
                  <img src={require("../assets/images/PNG/MG2.png")} alt="healthy-nutrition-for-athletes"/>
                </div>
              </NavLink>  
            </div>
          </Col>
          <Col lg={3} md={3} sm={3} xs={6} style={{padding:"3px"}}>
            <div className="card1">  
              <NavLink to={'/maingoal/happy-healthy'}>
                <div className="image-container mb-2">
                  <img src={require("../assets/images/PNG/MG3.png")} alt="wellness-products-for-a-happy-and-healthy-lifestyle"/>
                </div>
              </NavLink>
            </div>
          </Col>
          <Col lg={3} md={3} sm={3} xs={6} style={{padding:"3px"}}>
            <div className="card1">   
              <NavLink to={'/maingoal/fitness-forever'}>
                <div className="image-container mb-2">
                  <img src={require("../assets/images/PNG/MG4.png")} alt="Top rated supplements for fitness"/>
                </div>
              </NavLink>
            </div>
          </Col>
        </Row>
    </div>
  
  </section>  
  {/* <!--End second section --> */}

  {/* <!-- third section --> */}
  {orderagainProducts.length > 0 && ( // Render the entire section only if there are products
  <section className="PrevoiusOrders container">
    <div className="mx-3">
      <Row>
        <Col md={12}>
          <div className="newarrivalproducts mx-2 d-flex justify-content-between align-items-center">
            <div className="d-block d-sm-block d-lg-block d-xl-block">
              <h2 style={{ fontFamily: 'Inter, sans-serif', textAlign: "start" }}>
                Previous Ordered Items
              </h2>
            </div>
            <div className="d-block d-sm-block d-lg-block d-xl-block">
              <h5 style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ flex: 1 }}>
                  {/* <Link to="/Products/Brand/11" style={{ fontFamily: "Inter", color: "#219EBC", marginBottom: "0", paddingRight: "6px" }}>
                    Explore All
                  </Link> */}
                </span>
                {/* <span>
                  <ExploreIcon style={{ width: "12px", height: "5px", paddingLeft: "6px" }} />
                </span> */}
              </h5>
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ paddingBottom: "20px" }}>
        <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={false} infinite={true} className="stayfit1">
            {orderagainProducts.map((element) => (
              <Col style={{ padding: "5px" }}>
                <ProductItemCard
                  add_cart={AddToCartCall}
                  url_title={element.url_title}
                  image={element.image}
                  alt={element.alt_text}
                  rate={element.average_rating}
                  dis={element.discount_percentage}
                  name={element.name}
                  tag={element.item_tag}
                  elite_price={element.elite_price}
                  saleprice={element.price}
                  mrp={element.mrp}
                  cart_qnt={element.cart_quantity}
                  id={element.item_id}
                  notified={element.is_notified}
                  max_qnty={element.max_cart_quantity}
                  nuin={element.nuin}
                  stock={element.stock}
                  notify_me={NotifyMeCall}
                  is_subscribed={is_subscribed}
                  clicked={addClicked === element.item_id}
                  notifyClicked={notifyClicked === element.item_id}
                  index={element.item_id}
                />
              </Col>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  </section>
)}

  {/* <!-- End of Third section --> */}


  
  <section className="stayfit container d-none d-md-block d-lg-block d-sm-block">
  <div>
  <Row>
    <Col lg={12} md={12} sm={12}>
      <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
      <img style={{width:"20%", mixBlendMode:""}} src="/assets/stayfit.gif" alt="Description of GIF" />

      </div>


      <Row className="container1" style={{paddingRight:'0px'}}>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/hair-care-supplements'}>
            
            <div style={{ marginBottom: "8px" }}>
              <img src={require("../assets/images/Haircare.png")} alt="black haired men promoting hair care supplements" draggable="false" style={{width:'100%'}}/>
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/skin-care-supplements'}>
            <div style={{ marginBottom: "8px" }}>
              <img src={require("../assets/images/Skincare.png")} draggable="false" alt=" smooth skin women promoting skin care supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/eye-care-supplements'}>
            <div style={{ marginBottom: "8px" }}>
              <img src={require("../assets/images/Eyecare.png")} draggable="false" alt="men promoting eye care supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/sleep-support-supplements'}>
            <div style={{ marginBottom: "8px" }}>
              <img src={require("../assets/images/SleepSupport.png")} draggable="false" alt="A red-haired woman sleeping on a pillow, promoting sleep supplements" />
            </div>
          </NavLink>
        </Col>
      </Row>

      <Row className="container1">
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/stress-relief-supplements'}>
            <div style={{ marginBottom: "3px" }}>
              <img src={require("../assets/images/StressRelief.png")} draggable="false" alt="A man in a maroon t-shirt holding his head with his fingers, promoting stress relief supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/digestion-supplements'}>
            <div style={{ marginBottom: "3px" }}>
              <img src={require("../assets/images/Digestion.png")}draggable="false" alt="A woman with brown hair holding her stomach, promoting digestion supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/joint-health-supplements'}>
            <div style={{ marginBottom: "3px" }}>
              <img src={require("../assets/images/JiontHealth.png")} draggable="false" alt="A man holding his knees, promoting supplements" />
            </div>
          </NavLink>
        </Col>
        <Col lg={3} md={3} sm={3} style={{paddingRight:'0px'}}>
          <NavLink to={'/liver-health-supplements'}>
            <div style={{marginBottom: "3px" }}>
              <img src={require("../assets/images/liver.png")} draggable="false" alt="A human liver with supplements" />
            </div>
          </NavLink>
        </Col>
      </Row>
    </Col>
  </Row>




</div>

</section>
<section className="d-block d-md-none d-lg-none d-sm-none container">
<div className="stayfit">
<Row>
<Col lg={12} md={12} sm={12} xs={12}>
<div style={{display:"flex", justifyContent:"center", padding:"10px"}}>
<img src="/assets/stayfit.gif" alt="Description of GIF" draggable="false" style={{width:"auto",webkitUserDrag:"none"}} className="stayfitimg" />
</div>
<div className="container1">
<Carousel responsive={stayfitcarousel} arrows={false} draggable={true} autoPlay={true} infinite={true} className="stayfit1">
<NavLink to={'/hair-care-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/Haircare.png")} alt="black haired men promoting hair care supplements" draggable="false" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

<NavLink to={'/skin-care-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/Skincare.png")} alt="smooth skin women promoting skin care supplements" draggable="false" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

<NavLink to={'/eye-care-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/Eyecare.png")} alt="men promoting eye care supplements" draggable="false" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>
<NavLink to={'/sleep-support-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/SleepSupport.png")} draggable="false" alt="A red-haired woman sleeping on a pillow, promoting sleep supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>
</Carousel>

</div>
<div className="container1">
<Carousel responsive={stayfitcarousel} arrows={false} draggable={true} autoPlay={true} infinite={true} className="stayfit1">

<NavLink to={'/stress-relief-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/StressRelief.png")} draggable="false" alt="A man in a maroon t-shirt holding his head with his fingers, promoting stress relief supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

<NavLink to={'/digestion-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/Digestion.png")} draggable="false" alt="A woman with brown hair holding her stomach, promoting digestion supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

<NavLink to={'/joint-health-supplements'}>
<div style={{padding:"5px"}}>
<img src={require("../assets/images/JiontHealth.png")} draggable="false" alt="A man holding his knees, promoting supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>
<NavLink to={'/liver-health-supplements'}>
<div style={{padding:"5px"}}>

<img src={require("../assets/images/liver.png")} draggable="false" alt="A human liver with supplements" style={{webkitUserDrag:"none"}}/>
</div>
</NavLink>

</Carousel>
</div>
</Col>
</Row>


</div>
</section>

 {/* <!-- Third section --> */}
 <section className="mt-20 container d-none d-md-block d-lg-block d-sm-block ">
    <div className="">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
        <Link to ="/peanut-butter">
        <img src={require("../assets/images/PNG/peanutbanner.png")} alt="" style={{webkitUserDrag:"none"}}/>
        </Link>
        </Col>
      </Row>  
    </div>
  </section>
  {/* <!--End Third section --> */}
  <section className="mt-20 container d-block d-md-none d-lg-none d-sm-none">
    <div className="">
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
        <Link to ="/peanut-butter">
        <img src={require("../assets/images/PeanutButter.png")} alt="biggest peanut butter sale" style={{webkitUserDrag:"none"}}/>
        </Link>
        </Col>
      </Row>  
    </div>
  </section>

  
  
<section className="container" style={{padding:"20px 19px 0px 19px"}}>
 
    <Row>
      <Col lg={6} md={6} sm={12} xs={12} style={{padding:"6px"}}>
        <NavLink to={'/weight-loss-supplements'}>
          <img src={require("../assets/images/PNG/weightloss.png")} alt="Top-best-weight-loss-supplements" style={{webkitUserDrag:"none"}}/>
        </NavLink>
      </Col>
      <Col lg={6} md={6} sm={12} xs={12} style={{padding:"6px"}}>
        <NavLink to={'/weight-gain-supplements'}>
          <img src={require("../assets/images/PNG/Weightgain.png")} alt="Top-best-weight-gain-supplements" style={{webkitUserDrag:"none"}}/>
        </NavLink>
      </Col>
     
    </Row>  
 
</section>

<section className="container" style={{ borderRadius: "30px" }}>
  <div
    className="product_of_the_day mt-20 mb-20"
    style={{
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    {/* Background Image */}
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${require("../assets/images/productofday.png")})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: -1,
        borderRadius: '30px',
      }}
    />
    
    <h3 className="productofday"
      style={{
        position: 'absolute',
        top: '8%', 
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 10,
        fontFamily: 'Inter',
        fontWeight: '500',
        textAlign: 'center'
      }}
    >
      Product of the Day
    </h3>

    <Row style={{ width: '100%', position: 'relative', zIndex: 10 }}>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: '90px',
          paddingTop: '40px',
          margin: '30px 30px 0px 0px',
          marginBottom: '10px',
        }}
      >
  
          {/* Carousel Container */}
          <div 
            className="carousel-container container1"
            style={{
              position: 'relative',
              padding: '15px 0px 22px',
              
            }}
          >
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}  
              arrows={true}
              responsive={productsresponsive1}
              draggable={true}
              infinite={true}  
              className="stayfit1"
              
            >
              {productofday.map((element) => (
                <Col style={{ padding: '3px' }} key={element.product_id}>
                  <ProductItemCard style={{height:"300px"}}
                    add_cart={AddToCartCall}
                    image={element.image}
                    url_title={element.url_title}
                    rate={element.average_rating}
                    dis={element.discount_percentage}
                    name={element.name}
                    tag={element.item_tag}
                    elite_price={element.elite_price}
                    saleprice={element.price}
                    mrp={element.mrp}
                    cart_qnt={element.cart_quantity}
                    id={element.product_id}
                    notified={element.is_notified}
                    max_qnty={element.max_cart_quantity}
                    nuin={element.nuin}
                    stock={element.stock}
                    notify_me={NotifyMeCall}
                    is_subscribed={is_subscribed}
                    clicked={addClicked === element.product_id}
                    notifyClicked={notifyClicked === element.product_id}
                    index={element.product_id}
                   
                  />
                </Col>
              ))}

              {/* <Col className="view-more-card" style={{ padding: '5px' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    borderRadius: '29px',
                    backgroundColor: 'white',
                    textAlign: 'center',
                    cursor: 'pointer',
                    width: '100%',
                    height: '300px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Link to="/Products/Brand/11">
                    <img
                      src={require("../assets/images/view.png")}
                      alt="view more"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Link>
                </div>
              </Col> */}
            </Carousel>
          </div>
    
      </Col>
    </Row>
  </div>
</section>

<section className="container">
    <div className="gradient-background  container" style={{borderRadius:"30px"}}>
      <Row>      
        <Col md={12}>
          <div className="pamperhealth">
              <h2 style={{fontFamily: "Inter", fontStyle:"italic", color: "#fff",textAlign:"center", fontWeight:"700"}}>Pamper Health</h2>
              <h3 style={{fontFamily: "Inter", fontStyle:"italic", color: "#fff",textAlign:"center", 
                padding:"0px", fontWeight:"400"}}>at any age</h3>
          </div>
        </Col>
      </Row>  
      <div className="container1">
        <Carousel responsive={PamperHealthCarousel} arrows={false} draggable={true} autoPlay={false} infinite={true} className="stayfit1">
          {/* <Carousel.Item>  */}
            <div style={{display:"flex"}}>
            <NavLink to={'/kids-8-7'}>
              <div>
                <img src={require("../assets/images/Age8-17.png")} alt="Best Vitamins and Supplements for Children and Teens" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow}/>
              </div>
            </div> 
          {/* </Carousel.Item> */}
          {/* <Carousel.Item>  */}
            <div style={{display:"flex"}}>
            <NavLink to={'/youngsters-18-27'}>
              <div>
                <img src={require("../assets/images/Age18-27.png")} alt="Top Protein and Supplements for Young Adults" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow}/>
              </div>
            </div> 
          {/* </Carousel.Item> */}
          {/* <Carousel.Item>  */}
         
          <div style={{display:"flex"}}>
          <NavLink to={'/youth-28-37'}>
              <div>
                <img src={require("../assets/images/Age28-37.png")} alt="Best Health Supplements for Adults" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow} />
              </div>
            </div> 
          {/* </Carousel.Item> */}
          
          {/* <Carousel.Item>  */}
          <div style={{display:"flex"}}>
          <NavLink to={'/mid-age-38-50'}>
              <div>
                <img src={require("../assets/images/Age38-50.png")} alt="Top Health Supplements for Adults Under Ages 38-50" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow} />
              </div>
            </div> 
          {/* </Carousel.Item> */}
          {/* <Carousel.Item>  */}
          <div style={{display:"flex"}}>
          <NavLink to={'/senior-citizens-above50'}>
              <div>
                <img src={require("../assets/images/Age50+.png")} alt="Best Protein supplements for senior citizens" draggable="false"/>
              </div>
              </NavLink>
              <div className="LottieClass" style={{width:"120px", marginLeft:"5px"}}>
                <Lottie animationData={singlearrow} />
              </div>
            </div> 
          {/* </Carousel.Item> */}
        </Carousel>  
      </div>
    </div>     
</section>


<section className="RecentlySearch mt-40 container" >
    <div>
      <Row>      
        <Col md={12}>
          <div className="Recentserachedprods mx-2 d-flex justify-content-between align-items-center"> 
            <div className="d-block d-sm-block d-lg-block d-xl-block">
              <h2 style={{fontFamily: "Inter", fontWeight: "600", textAlign: ""}}>Recently Searched Products</h2>
            </div>
            <div className="d-block d-sm-block d-lg-block d-xl-block">
            <h5 style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* <span style={{ flex: 1 }}>
       <Link to="/Products/Brand/11" style={{ fontFamily: "Inter", color: "#219EBC", marginBottom: "0" ,paddingRight: "6px"}}>
        Explore All
       </Link>
      </span>
     <span>
      <ExploreIcon style={{ width: "12px", height: "5px" }} />
    </span> */}
   </h5>

            </div>
          </div>
          
        </Col>
      </Row> 
      <div style={{paddingBottom: "20px"}}>
        <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={true} infinite={true} className="stayfit1">                 
            { TrendingProducts.map((element) =>
              <Col style={{padding:"3px"}}> 
                <ProductItemCard
                    add_cart={AddToCartCall}
                    image={element.image}
                    url_title={element.url_title}
                    rate={element.average_rating}
                    dis={element.discount_percentage}
                    name={element.name}
                    tag={element.item_tag}
                    elite_price={element.elite_price}
                    saleprice={element.price}
                    mrp={element.mrp}
                    cart_qnt={element.cart_quantity}
                    id={element.product_id}
                    notified={element.is_notified}
                    max_qnty={element.max_cart_quantity}
                    nuin={element.nuin}
                    stock={element.stock}
                    notify_me = {NotifyMeCall}
                    is_subscribed = {is_subscribed}
                    clicked = {addClicked === element.product_id}
                    notifyClicked = {notifyClicked === element.product_id}
                    index = {element.product_id}
                />
              </Col> 
            )} 
                               
          </Carousel> 
        </div>
      </div>
    </div>              
</section>







 
  {/* <!-- Fifth section --> */}
  <section className="mt-40 mb-60 bottom_sliders1 container">
  <Row>
    <Col lg={6} md={6} sm={12} xs={12} className="mt-20">
      <NavLink to={'/fresh-and-healthy'}>
        <img
          src={require("../assets/images/PNG/HS1.png")}
          alt="fresh and healthy juiceh/ in the jar"
          style={{ maxWidth: "100%" }}
          draggable="false"
        />
      </NavLink>
    </Col>
    <Col lg={6} md={6} sm={12} xs={12} className="mt-20">
      <NavLink to={'/healthy-snacks'}>
        <img
          src={require("../assets/images/PNG/HS2.png")}
          alt="healthy biscuits with tea and butter"
          style={{ maxWidth: "100%" }}
          draggable="false"
        />
      </NavLink>
    </Col>
  </Row>
</section>



    <section className="mb-60 mt-50 nutrieats1_slider container" >
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} >
          <NavLink to={'/Nutrieats'}>
          <img src={require("../assets/images/PNG/NUTRI.png")} alt="high protein nuts and dried fruits up to 20% off"  style={{borderRadius:"18px"}} />
          </NavLink>
        </Col>
      </Row>  
    </section>
 

  {/* <!-- 8th section  --> */}

  <SubscribeForm/>
  {/* <!-- end of 8th section  --> */}

   {/* <!-- 9th section --> */}
   <DownloadTheApp/> 
  {/* <!-- end of 9th section --> */}
  

  <section className="mt-20 mb-120"  >
    <div className="container" style={{fontFamily: "Inter" , fontWeight:"300"}}>
      <div >
      <h1 style={{lineHeight:'20px',fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'
}
}>
  <strong>Nutsby: Your Premier Destination for Health and Fitness</strong>
</h1>

     
<p style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
            Elevate your health and fitness journey with Nutsby, your ultimate destination for premium products. 
            We offer a diverse range of high-quality items, including fitness supplements, sports nutrition, and 
            weight management solutions. Don’t miss our Nutieats line, featuring meticulously picked dry fruits 
            sourced directly from the best farms. Our platform not only provides top-notch products but also offers 
            valuable insights on wellness and nutrition from our team of experts. Enjoy a seamless shopping 
            experience with personalized recommendations and convenient delivery straight to your door. Whether 
            you're looking to enhance overall health or achieve specific fitness goals, Nutsby has you covered. 
            Explore our selection today and take the next step towards your wellness journey with Nutsby!
          </p>
         <p className="mt-20" style={{lineHeight:'20px',fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}> <strong>Health and Wellness Made Easy at Nutsby*</strong></p>
          <p style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}} className="mt-20">
            Looking for a reliable online store for all your health and wellness needs? Nutsby is your destination! 
            We offer an extensive range of products across several categories, each designed to support your 
            lifestyle. Check out what we have in store:
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>1.&nbsp;Health Foods &amp; Beverages&nbsp;</strong>-Explore a variety of healthy snacks, organic teas, and 
              nutritious options including biscuits, cookies, nuts, seeds, protein, health drinks, chocolates, 
              flours, oils, muesli, and cereals. Our Nutieats line features meticulously picked dry fruits, sourced 
              directly from the finest farms to ensure top quality and freshness. From quinoa and oats to 
              ready-to-cook meals and wellness coffee, discover everything you need to support a healthier lifestyle.
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>2.&nbsp;Hair, Skin &amp; Nails&nbsp;</strong>-Enhance your beauty regimen with our supplements for hair, skin, 
              and nails. Choose from biotin, collagen, glutathione, vitamin E, and other essential nutrients that 
              help strengthen your hair, improve skin elasticity, and support healthy nails. Our products are 
              formulated to help you shine from within.
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>3.&nbsp;Weight Management&nbsp;</strong>- Achieve your weight goals with our range of weight loss and 
              maintenance supplements. From Apple Cider Vinegar and Garcinia Cambogia to Green Coffee Beans, 
              Sugar Substitutes, and Slimming Shakes, we offer high-quality options to assist you in reaching 
              your fitness objectives.
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong> 4.&nbsp;Sports Nutrition&nbsp;</strong>-  Boost your performance with our sports nutrition products. We offer 
              Whey Protein, Vegan & Plant-Based Protein, L-Carnitine, BCAA, Creatine, Pre & Post-Workout supplements,
              Protein Bars, Sports Drinks, and Mass Gainers. Our premium selection helps you maximize your workouts 
              and fitness goals.

          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>5.&nbsp;Women's Health&nbsp;</strong>-Support your unique health needs with our range of women's supplements, 
              including options for period pain relief, PCOD/PCOS, and specialized protein. Our high-quality products
              are designed to assist in your wellness journey.
          </p>
          <p className="mt-20" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          <strong>6.&nbsp;Kids Nutrition&nbsp;-&nbsp;</strong>Provide your children with nutritious options including cereals, 
              granola, protein, quick meals, shakes, juices, snacks, and essential vitamins like Calcium and D3. 
              Our products support healthy growth and development for your little ones.
          </p>
      </div>
      <div className="mt-20">
          <p className="mt-10" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
          Welcome to Nutsby, your ultimate destination for all things health and wellness! Whether you're looking for fitness foods, supplements, or hair and skin care, we've curated a range of products to fit every lifestyle. From weight management to sports nutrition, women's health, and kids' nutrition, we cater to your unique needs with quality and care. Dive into our Ayurvedic and herbal supplements for a natural boost, and take advantage of our exciting seasonal discounts. At Nutsby, living healthy has never been more fun or accessible!
             
          </p>
          <p style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}> <strong style={{paddingTop: "50px",fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}> HISTORY OF NUTSBY</strong></p>
          <p className="" style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
           Nutsby, your sanctuary for nutrition and nourishment on the path to a healthier, happier you. In today's fast-paced world, where uncertainties abound, health is paramount. That's where Nutsby steps in. We offer genuine products at fair prices, paving the path to wellness for all. Guided by transparency, we strive toward our vision of a thriving world of health. Join us on this passionate journey to a healthier tomorrow

          </p>
          <p style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}><strong>We offer a range of health and wellness products to help you achieve your best self. Here are just a few of our top products:</strong></p>
          <ul style={{fontSize:"14px",fontFamily:'Inter, sans-serif',color:'#353535'}}>
              <li style={{listStyle: "inside"}}><strong>Multivitamin Mania:</strong>&nbsp;For all your nutrient needs in one convenient capsule</li>
              <li style={{listStyle: "inside"}}><strong>Omega 3 &amp; Fish Oil:&nbsp;</strong>Keep your heart healthy with our high-quality supplements</li>
              <li style={{listStyle: "inside"}}><strong>Collagen:&nbsp;</strong>Promote healthy skin and joints with our&nbsp;premium collagen powder</li>
              <li style={{listStyle: "inside"}}><strong>Biotin:&nbsp;</strong>Let your hair do the talking with our hair care essential supplement</li>
              <li style={{listStyle: "inside"}}><strong>Apple Cider Vinegar:&nbsp;</strong>Kickstart your weight loss journey with our natural remedy</li>
              <li style={{listStyle: "inside"}}><strong>Whey Protein:&nbsp;</strong>Build muscle and stay strong with our premium whey protein powder</li>
              <li style={{listStyle: "inside"}}><strong>Peanut Butter:&nbsp;</strong>Enjoy a healthy and delicious snack with our all-natural peanut butter</li>
          </ul>
      </div>
      <div className="mt-50 mb-80">
          <p className="mt-40 " style={{color:"#353535",borderTop: "1px solid",paddingTop: "50px",fontSize:"14px"}}>TOP CATEGORIES ON NUTSBY</p>
          <p className="style123"  style={{paddingTop: "0px",fontSize:"14px"}}>
              <a href="/peanut-butter">Peanut Butter |</a>
              <a href="/maingoal/muscle-building">Muscle Building&nbsp;|&nbsp;</a>
              <a href="/maingoal/athelete-journey">Athlete journey</a>
              <a href="/maingoal/happy-healthy">Happy & Healthy</a>
              <a href="/maingoal/fitness-forever">Fitness Forever&nbsp;|&nbsp;</a>
              <a href="/hair-care-supplements">Skin Care</a>
              <a href="/skin-care-supplements">Hair Care</a>
              <a href="/eye-care-supplements">Eye Care&nbsp;</a>
              <a href="/sleep-support-supplements">Sleep Support&nbsp;|</a>
              <a href="/liver-health-supplements">Liver Care&nbsp;|</a>
              <a href="/stress-relief-supplements">Stress Relief&nbsp;|&nbsp;</a>
              <a href="/digestion-supplements">Digestion&nbsp;|</a>
              <a href="/joint-health-supplements">&nbsp;Joint Healthy&nbsp;|</a>
              <a href="/weight-loss-supplements">Weight loss&nbsp;|</a>
              <a href="/weight-gain-supplements">Weight Gain&nbsp;|</a>
              <a href="/fresh&healthy">Fresh & Healthy&nbsp;|</a>
              <a href="/healthy-snacks">Healthy Snacks&nbsp;|</a>
              <a href="/Nutrieats">Nutrieats</a>
          </p>
        
      </div>

    </div>
   
  </section>
  
  <BottomBar/>
   </div>
   }
    </>
  );
}else{
  return (
    <section className="">
    <Container className="mt-80 d-none d-md-block d-lg-block " style={{paddingBottom: "20px",height:"",alignContent:"center",marginTop:"10em"}}>
    <Row className="mt-10 ">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20 ">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto" />
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20">
        {shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2}  style={{
   display:"block"
  }} />
        </Col>
        ))}
        </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" rounded={1}  height={290} width="auto" />
  </Col>
    </Row>
    <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1}  height={190} width="auto" />      
        </Col>
        ))}
        </Row>

        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={190} width="auto" />
  </Col>
    </Row>
    <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={190} width="auto" />
  </Col>
    </Row>
    
    
  <Row className="mt-20">
        {shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2}  style={{
  display:"block"
  }}/>
        </Col>
        ))}
        </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1}  height={190} width="auto" />      
        </Col>
        ))}
        </Row>
        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={190} width="auto" />
  </Col>
    </Row>
      
      </Col>
    </Row>
</Container>
<Container className="mt-80 d-block d-md-none d-lg-none d-sm-block mainCarousel1" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
    <Row className="mt-0">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={120} width="auto" />
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={90} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20">
        {shimmeritems1.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2}  style={{
  display:"block"
  }} />
        </Col>
        ))}
        </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={90} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={90} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" rounded={1}  height={120} width="auto" />
  </Col>
    </Row>
    <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1}  height={90} width="auto" />      
        </Col>
        ))}
        </Row>

        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={120} width="auto" />
  </Col>
    </Row>
    <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={120} width="auto" />
  </Col>
    </Row>
    
    
  <Row className="mt-20">
        {shimmeritems1.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2}  style={{
   display:"block"
  }}/>
        </Col>
        ))}
        </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1}  height={120} width="auto" />      
        </Col>
        ))}
        </Row>
        <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light"  height={120} width="auto" />
  </Col>
    </Row>
      
      </Col>
    </Row>
</Container>
</section>
  )
}
}

export default Home;
