 
import { Container, Row, Col, Button } from 'react-bootstrap';
import { NavLink,Link, useParams } from 'react-router-dom';
// import Overlay from 'react-bootstrap/Overlay';
import React,{ useState,useEffect } from 'react';
import { GoalHomeMuscleBuildingApi,AddToCart,FitnessForeverDataApi,AthleteDataApi,HappyHealthyDataApi, NotifyMeApi, GetHeaderDetailsApi } from '../Service/ApiCalling';
import MetaTags from '../MainComponents/Metatags'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; 
import SubscribeForm from "../MainComponents/SubscribeForm";
import DownloadTheApp from "../MainComponents/DownloadTheApp";
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import '../fonts/Inter-Regular.ttf'; 
import { DiscountIcon, EliteIcon } from "../MiniComponents/SvgIcons";
import ProductItemCard from '../MainComponents/SubComponents/MiniComponents/ProductItemCard';
import { useCart } from '../MainComponents/SubComponents/MiniComponents/CartContext';
import "../SubCss/goals.css";
import Slider from 'react-slick';
import transform from '../assets/images/PNG/frame.png';
import '../fonts/nicomoji-regular.ttf';
import '../fonts/Inter-Regular.ttf';
import '../fonts/Michroma.ttf';
import { ShimmerDiv,ShimmerContentBlock, ShimmerTitle,ShimmerButton } from "shimmer-effects-react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import BottomBar from "./../MainComponents/SubComponents/Bottom";
// import focus from '../assets/images/frame.png'
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons';
export default function GoalsHome() {
  const shimmeritems = Array.from({ length: 6 });
  const shimmeritems1 = Array.from({ length: 2 });
  const ess_shimmeritems = Array.from({ length: 4 })
  const ess_shimmeritems1 = Array.from({ length: 3 })
  const main_shimmeritems = Array.from({ length: 2 });
    const [cards, setcards] = useState([]);
    const [essential,setessential] =useState([]);
    const [must_buy,setmustbuy] =useState([]);
    const [card1, setcard1] = useState("");
    const [card2, setcard2] = useState("");
    const [card3, setcard3] = useState("");
    const [card4, setcard4] = useState("");
    const [sliders, setsliders] = useState([]);
    const [top_grossing, settop_grossing] = useState([]);
    const [is_subscribed,setIsSubscribed] = useState('');
    const [main_goal,setmaingoal] =useState([])
    const [sub_goal,setsubgoal] =useState([])
    const { setCartCount } = useCart();
    const [addClicked,setAddClicked] = useState(null);
    const [notifyClicked, setNotifyClicked] = useState(null);
    const [productsLoading,setLoading] = useState(true);
  
    const params = useParams().name

    const fetchInfo=async ()=> {  
        if(params==="muscle-building"){
                setcard1("GoalHomeCard_1_1.png");
                setcard2("GoalHomeCard_1_2.png");
                setcard3("GoalHomeCard_1_3.png");
                setcard4("GoalHomeCard_1_4.png");
            let GoalHomeMuscleBuildingApiData = await GoalHomeMuscleBuildingApi();
            if (GoalHomeMuscleBuildingApiData.error==="0") {  
              setcards(GoalHomeMuscleBuildingApiData.cards)    
              setessential(GoalHomeMuscleBuildingApiData.cards_new)
                setsliders(GoalHomeMuscleBuildingApiData.sliders);
                settop_grossing(GoalHomeMuscleBuildingApiData.top_grossing);
                setmaingoal(GoalHomeMuscleBuildingApiData.sub_main_goals);
                setsubgoal(GoalHomeMuscleBuildingApiData.sub_sub_goals);
                setmustbuy(GoalHomeMuscleBuildingApiData.must_buy_supplements_cards);
                setLoading(false);
            }else{
              setLoading(false);
            }
        }

        if(params==="athelete-journey"){
                setcard1("GoalHomeCard_2_1.png");
                setcard2("GoalHomeCard_2_2.png");
                setcard3("GoalHomeCard_2_3.png");
                setcard4("GoalHomeCard_2_4.png");
            let AthleteDataApiData = await AthleteDataApi();
            if (AthleteDataApiData.error==="0") {
                
                setcards(AthleteDataApiData.cards);
                setessential(AthleteDataApiData.cards_new);
                setsliders(AthleteDataApiData.sliders);
                settop_grossing(AthleteDataApiData.top_grossing);
                setmaingoal(AthleteDataApiData.sub_main_goals);
                setsubgoal(AthleteDataApiData.sub_sub_goals);
                setmustbuy(AthleteDataApiData.must_buy_supplements_cards);
                setLoading(false);
            }else{
              setLoading(false);
            }
        }
        
        if(params==="happy-healthy"){
            setcard1("GoalHomeCard_3_1.png");
            setcard2("GoalHomeCard_3_2.png");
            setcard3("GoalHomeCard_3_3.png");
            setcard4("GoalHomeCard_3_4.png");
        let HappyHealthyDataApiData = await HappyHealthyDataApi();
        if (HappyHealthyDataApiData.error==="0") {
            
            setcards(HappyHealthyDataApiData.cards);
            setessential(HappyHealthyDataApiData.cards_new);
            setsliders(HappyHealthyDataApiData.sliders);
            settop_grossing(HappyHealthyDataApiData.top_grossing);
            setmaingoal(HappyHealthyDataApiData.sub_main_goals);
            setsubgoal(HappyHealthyDataApiData.sub_sub_goals);
            setmustbuy(HappyHealthyDataApiData.must_buy_supplements_cards);
            setLoading(false);
        }else{
          setLoading(false);
        }
    }

    if(params==="fitness-forever"){
      setcard1("GoalHomeCard_4_1.png");
      setcard2("GoalHomeCard_4_2.png");
      setcard3("GoalHomeCard_4_3.png");
      setcard4("GoalHomeCard_4_4.png");
      let FitnessForeverDataApiData = await FitnessForeverDataApi();
      if (FitnessForeverDataApiData.error==="0") {
          
          setcards(FitnessForeverDataApiData.cards);
          setessential(FitnessForeverDataApiData.cards_new)
          setsliders(FitnessForeverDataApiData.sliders);
          settop_grossing(FitnessForeverDataApiData.top_grossing);
          setmaingoal(FitnessForeverDataApiData.sub_main_goals);
          setsubgoal(FitnessForeverDataApiData.sub_sub_goals);
          setmustbuy(FitnessForeverDataApiData.must_buy_supplements_cards);
          setLoading(false);
      }else{
        setLoading(false);
      }
    }
    
    }

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
       
        {
          breakpoint: 1024, 
          settings: {
            slidesToShow: 3, 
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2, 
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2, 
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 300, 
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
// const AddToCartCall=async (p_id,qnt,clicked,index,max_qty)=> { 
//     // AddToCart 
//     // console.log(qnt)
//     console.log(max_qty);
//     if(clicked === true){
      
//       setAddClicked(index);
//     }
//     // if(qnt>0)
//     //     {
//     if(Number(max_qty)>Number(qnt)){
//             let addcartDatatemp = await AddToCart(p_id,qnt);
//             if (addcartDatatemp.error === "0") {
//                 // fetchInfo();  
//                     const newList = top_grossing.map((item) => {
//                         if (item.product_id === p_id) {
//                           const updatedItem = {
//                             ...item, 
//                             cart_quantity: addcartDatatemp.cart_quantity,
  
//                           };
//                           setTimeout(function() {
              
//                             setAddClicked(null);
                            
//                           }, 1300);
//                           return updatedItem;
//                         } 
//                         return item;
//                       });
                  
//                       settop_grossing(newList); 
//                     // setcartCount(addcartDatatemp.items_count);
  
//                     // const newupdatedItem = {
//                     //     ...cartCalculationData,
//                     //     items_total: addcartDatatemp.cart_price,
//                     //     total_price: addcartDatatemp.grand_total,
//                     //     total_price: addcartDatatemp.grand_total_raw,
  
//                     //   };
//                     //   setcartCalculationData(newupdatedItem);
//                 // (e) =>setdetailsData((prevState) => ({ ...prevState, [e.target.name]: 1 }));
//                 // this.setState( detailsData.name="AKash");
          
//               const headerData = await GetHeaderDetailsApi();
//               if (headerData.error === "0") {
//                 if(headerData.cart_count!=null){
//                   setCartCount(headerData.cart_count);
//                 }else{
//                   setCartCount(0);
//                 }
                
//                 console.log(headerData.cart_count,"khfaosdufhuafs") 
//               }
//             }
//           }else{

//           }
//         // }
   
//   };

const AddToCartCall=async (p_id,qnt,clicked,index,max_qty)=> { 
  if(clicked === true){
      
    setAddClicked(index);
  }
  // AddToCart 
  // console.log(qnt)
  // if(qnt>0)
  //     {
   
          let addcartDatatemp = await AddToCart(p_id,qnt);
          if (addcartDatatemp.error === "0") {
            toast.success(addcartDatatemp.message, {
              autoClose: 3000,
            });
              // fetchInfo();  
                  const newList = top_grossing.map((item) => {
                      if (item.product_id === p_id) {
                        const updatedItem = {
                          ...item,
                          product_total: addcartDatatemp.product_total,
                          product_total_mrp: addcartDatatemp.product_total_mrp,
                          cart_quantity: addcartDatatemp.cart_quantity,

                        };
                        setTimeout(function() {
            
                          setAddClicked(null);
                          
                        }, 1300);
                
                        return updatedItem;
                      } 
                      return item;
                    });
                
                    settop_grossing(newList); 
                  // setcartCount(addcartDatatemp.items_count);

                  // const newupdatedItem = {
                  //     ...cartCalculationData,
                  //     items_total: addcartDatatemp.cart_price,
                  //     total_price: addcartDatatemp.grand_total,
                  //     total_price: addcartDatatemp.grand_total_raw,

                  //   };
                  //   setcartCalculationData(newupdatedItem);
              // (e) =>setdetailsData((prevState) => ({ ...prevState, [e.target.name]: 1 }));
              // this.setState( detailsData.name="AKash");
            // console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC")
            const headerData = await GetHeaderDetailsApi();
              if (headerData.error === "0") {
                if(headerData.cart_count!=null){
                  setCartCount(headerData.cart_count);
                }else{
                  setCartCount(0);
                }
                
                // console.log(headerData.cart_count,"khfaosdufhuafs") 
              }
          }
      // }
  
};


  const NotifyMeCall = async (p_id, type,clicked,index) => {
    if(clicked===true){
      setNotifyClicked(index);
    }
    try {
      let Data = await NotifyMeApi(p_id, type);
      if (Data.error === "0") {
        if (type === "stop") {
          Data.is_notified = "0";
          console.log("is_stopped");
        } else {
          Data.is_notified = "1";
          console.log("is_notified");
          toast.success(Data.message, {
            autoClose: 3000,
          });
        }
        const newList = top_grossing.map((item) => {
          if (item.product_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            setTimeout(function() {
              setNotifyClicked(null);
              
            }, 1100);
            return updatedItem;
          }
          return item;
        });
        settop_grossing(newList);
      } else {
        console.log(Data.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

useEffect(() => {
  window.scrollTo(0, 0);
  setIsSubscribed(localStorage.getItem("is_subscribed"));
  
  console.log("is_subscribed",is_subscribed);
    fetchInfo(); 

  }, []);


const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  
  const productsresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    minidesktop: {
      breakpoint: { max: 1495, min: 1200 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1200, min: 900 },
    items: 4,
  },
  minitablet1: {
    breakpoint: { max: 900, min: 769 },
  items:4,
},
minitablet: {
  breakpoint: { max: 768, min: 464 },
items: 3,
},
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3, 
  },
}; 

  const productsresponsivesss = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2, 
    },
  };
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => (
      <div
        style={{
          position: 'relative',
          bottom: '-5px', // Adjust this to move the dots closer to the slider
          display: 'flex',
          justifyContent: 'center',
          paddingLeft:'0px'
        }}
      >
        <ul style={{ margin: '0px' }}>{dots}</ul>
      </div>
    ),
  };
 

  if(!productsLoading){
    return (
      <>      
      <MetaTags
      
      title="Nutsby All in One Nutrition Store | Building a Healthy You"
      description="At Nutsby, we're dedicated to offering health & wellness products at the best prices. Our mission is to support your journey towards a healthier, happier you with a diverse selection of reliable products and unbeatable service. "
      keywords="Health Supplements,
      Fitness Supplements,
      Nuts & Dried Fruits,
      Protein Supplements,
      Sports Nutrition,
      protein powder,
      high quality dried fruits,
      protein supplements for weight loss,
      protein supplements for muscle gain,
      protein supplement store,
      Ayurvedic Products"
      canonical="https://www.nutsby.com"
      url="https://www.nutsby.com"
      image="https://www.nutsby.com/images/logo.jpg" 
      alt="Nutsby Fitness Products"
      ogTitle="Nutsby All in One Nutrition Store | Building a Healthy You"
      ogUrl="https://www.nutsby.com"
      ogDescription="Join Nutsby in our journey to make a healthy and fit lifestyle the universal norm. Explore our wide range of fitness products."
      ogImage="https://www.nutsby.com/images/nutsby-logo.jpg" 
      indexing="index, follow"
      schema={{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Nutsby Fitcare LLP",
        "url": "https://www.nutsby.com",
        "logo": "https://www.nutsby.com/logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91-1234567890",
          "contactType": "Customer Service",
        },
      }}
    />
  {
    
      <div> 
           <section >
            <div onClick={()=>{}} >
              <div className="mainCarousel d-none d-md-block d-lg-block container" style={{paddingBottom: "0px",paddingTop: "6rem",borderRadius:'30px'}} > 
              <Row >
                    <Col lg={12} md={12} sm={12} col={12}  >
                      
                          {(params==="muscle-building") ? <img src={require("../assets/images/Frame 1499.png")} alt="muscle building supplements for men"   draggable="false" style={{ borderRadius:'20px'}}/>:((params==="athelete-journey") ? <img src={require("../assets/images/Athlete.png")} alt="healthy-nutrition-for-athletes"    draggable="false" style={{ borderRadius:'20px'}} />:((params==="happy-healthy") ? <img src={require("../assets/images/Happy_healthy.png")}   draggable="false" alt="wellness-products-for-a-happy-and-healthy-lifestyle" style={{ borderRadius:'20px'}} />:((params==="fitness-forever") ? <img src={require("../assets/images/fitness_forever.png")}   draggable="false" alt="Top rated supplements for fitness"  style={{ borderRadius:'20px'}}/>:"")))} 
                            
                      </Col>                    
                  </Row> 
              </div>
              <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container" style={{paddingBottom: "0px",paddingTop: "10em"}} > 
                  <Row>
                  <Col lg={12} md={12} sm={12} col={12}>
                  {(params==="muscle-building") ? <img src={require("../assets/images/PNG/mb2.png")} alt="muscle building supplements for men"   draggable="false" />:((params==="athelete-journey") ? <img src={require("../assets/images/PNG/ATHELETE.png")} alt="healthy-nutrition-for-athletes"  draggable="false" style={{ borderRadius:'20px'}} />:((params==="happy-healthy") ? <img src={require("../assets/images/PNG/happy.png")} alt="wellness-products-for-a-happy-and-healthy-lifestyle"  draggable="false" style={{ borderRadius:'20px'}}/>:((params==="fitness-forever") ? <img src={require("../assets/images/PNG/FF.png")}   draggable="false" alt="Top rated supplements for fitness" style={{ borderRadius:'20px'}}/>:"")))} 
                        
                      </Col>                      
                  </Row> 
              </div>  
             
             <Row className='d-block d-md-none d-lg-none d-sm-none'>
              <Col xs={12} sm={12} md={8} lg={8} className='breadcrumb' style={{marginBottom:"0px"}}>
                 
                  <Breadcrumb>
                    <Breadcrumb.Item
                    role='button'
                    
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}
                    > Home </Breadcrumb.Item>
                    {(params==="muscle-building") ?  <Breadcrumb.Item href="#">
                      Muscle Building
                    </Breadcrumb.Item>:((params==="athelete-journey") ?  <Breadcrumb.Item href="#">
                      Athlete journey
                    </Breadcrumb.Item>:((params==="happy-healthy") ?  <Breadcrumb.Item href="#">
                      Happy & Healthy
                    </Breadcrumb.Item>:((params==="fitness-forever") ?  <Breadcrumb.Item href="#">
                     Fitness Forever
                    </Breadcrumb.Item>:"")))} 
                   
                  </Breadcrumb>
                </Col>
                </Row> 

                <div className='d-none d-md-block d-lg-block d-sm-block container'>
              <Col  className='breadcrumb' style={{marginBottom:"0px"}}>
                  <Breadcrumb>
                  <Breadcrumb.Item
                    role='button'
                    
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}
                    > Home </Breadcrumb.Item>
                    {(params==="muscle-building") ?  <Breadcrumb.Item href="#">
                      Muscle Building
                    </Breadcrumb.Item>:((params==="athelete-journey") ?  <Breadcrumb.Item href="#">
                      Athlete journey
                    </Breadcrumb.Item>:((params==="happy-healthy") ?  <Breadcrumb.Item href="#">
                      Happy & Healthy
                    </Breadcrumb.Item>:((params==="fitness-forever") ?  <Breadcrumb.Item href="#">
                     Fitness Forever
                    </Breadcrumb.Item>:"")))} 
                   
                  </Breadcrumb>
                </Col>
                </div> 
                </div>
                
           

          </section>  
  
    {/*<!--second section --->*/}
    <section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",marginBottom:"10px" }}>
    {/* Heading */}
    <h4 classname="" style={{ textAlign: "center", margin: " 10PX 0 20px 0" }}>Essential Supplements</h4>
  
    {/* Content */}
    <div className="container" style={{ cursor: "pointer", padding: '0px 10px' }}>
      <Row noGutters>
        {essential.length > 0 && essential.map((item, index) => (
          <Col lg={3} md={3} sm={6} xs={6}  key={index} style={{ padding: '5px', textAlign: 'center' }}>
            <NavLink className="cardcategory" to={`/${item.url_title}/collection/${item.hash_id}`}>
              <div className="image-container" style={{ overflow: "hidden", margin: 0 }}>
                <img src={item.web_image} alt={item.name}   draggable="false" style={{ width: "100%", height: "auto", objectFit: "cover" }} />
              </div>
              {/* Name below the image */}
              <h5 className="musttry" style={{ marginTop: '5px', color: '#333' }}>
                {item.name}
              </h5>
            </NavLink>
          </Col>
        ))}
      </Row>
    </div>
  </section>
  
   {/*<!--third section --->*/}
   <div>
    {/* Transform Image */}
   
  
    {/* Section for Image Containers */}
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', position: 'relative' }}>
      <div className="container" style={{ display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center'}}>
        
        {(params === "muscle-building" || params === "athelet-journey") && main_goal.length >= 2 && (
          <>
            {/* Background Image and Flex Container */}
          
  
            <section className="imgcontainer1" style={{}}>
              {/* Flex container for side-by-side images */}
              <Row> 
              <Col lg={6} md={6} sm={12} xs={12}>
              <NavLink className="cardcategory" to={`/${main_goal[0].url_title}/collection/${main_goal[0].hash_id}`} style={{}}>
                <div className="image-container mb-1" style={{ width: '100%' }}>
                  <img src={main_goal[0].web_image}    draggable="false" alt="Sub Main Goal 1" style={{ width: '100%', height: 'auto',marginBottom:'3px'   }} />
                </div>
              </NavLink></Col>
              <Col lg={6} md={6} sm={12} xs={12}>
              <NavLink className="cardcategory" to={`/${main_goal[1].url_title}/collection/${main_goal[1].hash_id}`} style={{ }}>
                <div className="image-container mb-1" style={{ width: '100%' }}>
                  <img src={main_goal[1].web_image}   draggable="false" alt="Sub Main Goal 2" style={{ width: '100%', height: 'auto',marginBottom:'3px'   }} />
                </div>
              </NavLink>
              </Col>
              </Row>
            </section>
          </>
        )}
  
        {(params === "happy-healthy" || params === "fitness-forever") && main_goal.length > 0 && (
          <>
          <div className="d-none d-md-block d-lg-block" style={{ display: 'flex', justifyContent: 'center', zIndex: '2', marginTop: '10px' }}>
            <NavLink className="cardcategory" to={`/${main_goal[0].url_title}/collection/${main_goal[0].hash_id}`} style={{ flex: '1' }}>
              <div className="image-container mb-1">
                <img src={main_goal[0].web_image}   draggable="false" alt="Sub Main Goal" style={{ width: '100%', height: 'auto' }} />
              </div>
            </NavLink>
          </div>

<div className="d-block d-md-none d-lg-none d-sm-block" style={{ display: 'flex', justifyContent: 'center', zIndex: '2', marginTop: '5px', }}>
<NavLink className="cardcategory" to={`/${main_goal[0].url_title}/collection/${main_goal[0].hash_id}`} style={{ flex: '1', }}>
  <div className="image-container mb-1">
    <img src={main_goal[0].image}   draggable="false" alt="Sub Main Goal" style={{ width: '100%', height: 'auto' }} />
  </div>
</NavLink>
</div>
</>
        )}
  
      </div>
    </div>
  </div>
  
         
  
  {/* <!-- fourth section --> */}
  <section
  className="container d-none d-md-block d-lg-block"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    padding: '5px',
    marginBottom: '20px',
  }}
>
  <div style={{ paddingBottom: '20px', width: '100%', height: '100%' }}>
    <Row style={{ margin: 0 }}>
      <Col md={12} lg={12} sm={12} xs={12} style={{ paddingLeft: 0 }}>
      <Slider {...settings1}>
  {sliders && sliders.length > 0 ? (
    sliders.map((slider, index) => (
      <NavLink
        className="cardcategory"
        to={`/${slider.url_title}/${slider.ref_type.toLowerCase()}/${slider.hash_id}`}
        key={index}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            src={slider.web_image}
            alt={`Slider ${index + 1}`}
            style={{ width: '99%', height: '100%' }}
          />
        </div>
      </NavLink>
    ))
  ) : (
    <div>No slides available</div>
  )}
</Slider>
      </Col>
    </Row>
  </div>
</section>

<section
  className="container d-block d-md-none d-lg-none d-sm-block"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '0px',
    padding: '5px',
    
  }}
>
  <div style={{ paddingBottom: '20px', width: '100%', height: '100%' }}>
    <Row style={{ margin: 0 }}>
      <Col md={12} lg={12} sm={12} xs={12} style={{ paddingLeft: '10px' }}>
      <Slider {...settings1}>
  {sliders && sliders.length > 0 ? (
    sliders.map((slider, index) => (
      <NavLink
        className="cardcategory"
        to={`/${slider.url_title}/${slider.ref_type.toLowerCase()}/${slider.hash_id}`}
        key={index}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            src={slider.image}
            alt={`Slider ${index + 1}`}
            style={{ width: '99%', height: '100%' }}
          />
        </div>
      </NavLink>
    ))
  ) : (
    <div>No slides available</div>
  )}
</Slider>
      </Col>
    </Row>
  </div>
</section>

  
  {/* <!-- fifth section --> */}
  <section className="container" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Col style={{ cursor: "pointer", padding: '0px 10px' }}>
        <Row noGutters>
          {sub_goal.length > 0 && sub_goal.map((item, index) => (
            <Col key={index} xs={6} sm={6} md={3} lg={3}  style={{ padding: '2px' }}>
              <NavLink className="cardcategory" to={`/${item.url_title}/collection/${item.hash_id}`}>
                <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
                  <img src={item.web_image} alt={item.name}   draggable="false" style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                  
                 
                </div>
              </NavLink>
            </Col>
          ))}
        </Row>
      </Col>
    </section>
  
  
    {/* <!-- Fourth section --> */}
    <section className="container" style={{marginTop:'10px'}} >
         
          <Row>      
              <Col md={12}>
                  <div className="newarrivalproducts" > 
                  <h4 classname='h4headings' style={{ textAlign: "start", margin: "20px 10px 20PX 10px" }}>Top Grossing Products</h4>
                  </div>
              </Col>
          </Row>  
  
          <div style={{  paddingBottom: "20px"}}>
          <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={true} infinite={true} className="stayfit1">                 
  
                  { top_grossing.map((element) =>
                                  <Col style={{padding:"5px"}}> 
                                   <ProductItemCard
                                       add_cart={AddToCartCall}
                                       url_title={element.url_title}
                                      image={element.image}
                                      rate={element.average_rating}
                                      dis={element.discount_percentage}
                                      name={element.name}
                                      tag={element.item_tag}
                                      elite_price={element.elite_price}
                                      saleprice={element.price}
                                      mrp={element.mrp}
                                      cart_qnt={element.cart_quantity}
                                      id={element.product_id}
                                      notified={element.is_notified}
                                      max_qnty={element.max_cart_quantity}
                                      nuin={element.nuin}
                                      stock={element.stock}
                                      notify_me = {NotifyMeCall}
                                      is_subscribed = {is_subscribed}
                                      clicked = {addClicked === element.product_id}
  notifyClicked = {notifyClicked === element.product_id}
  index = {element.product_id}
                                      /> 
                                 
                                  </Col> 
    )}
                
                                    
              </Carousel> </div>
          </div> 
        
    </section>
    <section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" ,marginBottom:"30px"}}>
        {/* Heading */}
        <h4 classname='headings' style={{ textAlign: "center", margin: "10px 0" }}>Must Buy Supplements</h4>
  
        {/* Carousel */}
        <div className="container" style={{ cursor: "pointer", padding: '3px 0px' }}>
          <Slider {...settings}>
            {must_buy.length > 0 && must_buy.map((item, index) => (
              <div key={index} style={{ padding: '15px' }}>
                <NavLink className="cardcategory" to={`/${item.url_title}/collection/${item.hash_id}`}>
                  <div className="image-container" style={{ overflow: "hidden" }}>
                    <img src={item.web_image} alt={item.name}    draggable="false" style={{ width: "100%", height: "100%", padding:'10px 0px 10px 8px' }} />
                  </div>
                  <h6 className='musttry' style={{ marginTop: '5px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                    {item.name}
                  </h6>
                </NavLink>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <ToastContainer />
      <BottomBar/>
     </div>
     }
      </>
    );
  }else{
    return (
      <section>
      <Container className="mt-100 d-none d-md-block d-lg-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
      <Row className="mt-10">
        <Col xs={12} sm={12} md={12} lg={12}>
      
        <Row className="mt-20">
        <Col xs={12} sm={12} md={12} lg={12}>
      <ShimmerDiv mode="light" height={190} width="auto"  style={{borderRadius:"10px"}}/>
    </Col>
      </Row>
  
      
      <Row className="mt-20 display-flex justify-content-center">
          {ess_shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
          </Col>
          ))}
          </Row>  
          <Row className="mt-20 display-flex justify-content-center">
          {main_shimmeritems.map((_, index) => (
          <Col xs={12} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
          </Col>
          ))}
          </Row>  
          
        
      
        <Row className="mt-20" >
        <Col xs={12} sm={12} md={12} lg={12}>
      <ShimmerDiv mode="light" height={190} width="auto" style={{borderRadius:"10px"}} />
    </Col>
      </Row>
          <Row className="mt-20 display-flex justify-content-center">
          {ess_shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
          </Col>
          ))}
          </Row>  
        
          <Row className="mt-20">
          {shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
          <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
          </Col>
          ))}
          </Row>
        
      <Row className="mt-20 display-flex justify-content-center">
          {ess_shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
          </Col>
          ))}
          </Row>  
          <Row className="mt-20 display-flex justify-content-center">
          {ess_shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
          </Col>
          ))}
          </Row>  
        </Col>
      </Row>
  </Container>
  <Container className="mt-80 d-block d-md-none d-lg-none d-sm-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
  <Row className="mt-10">
        <Col xs={12} sm={12} md={12} lg={12}>
      
        <Row className="mt-20">
        <Col xs={12} sm={12} md={12} lg={12}>
      <ShimmerDiv mode="light" height={180} width="auto"  style={{borderRadius:"10px"}}/>
    </Col>
      </Row>
  
      
      <Row className="mt-20 display-flex justify-content-center">
          {ess_shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
          </Col>
          ))}
          </Row>  
          <Row className="mt-20 display-flex justify-content-center">
          {main_shimmeritems.map((_, index) => (
          <Col xs={12} sm={12} md={6} lg={6} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
          </Col>
          ))}
          </Row>  
          
        
      
        <Row className="mt-20" >
        <Col xs={12} sm={12} md={12} lg={12}>
      <ShimmerDiv mode="light" height={180} width="auto" style={{borderRadius:"10px"}} />
    </Col>
      </Row>
          <Row className="mt-20 display-flex justify-content-center">
          {ess_shimmeritems.map((_, index) => (
          <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
          </Col>
          ))}
          </Row>  
        
          <Row className="mt-20">
          {shimmeritems1.map((_, index) => (
          <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
          <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
          </Col>
          ))}
          </Row>
        
      <Row className="mt-20 display-flex justify-content-center">
          {ess_shimmeritems1.map((_, index) => (
          <Col xs={4} sm={4} md={4} lg={4} style={{marginBottom:"10px"}}>
          <ShimmerDiv mode="light" rounded={1} height={120} width="auto" />      
          </Col>
          ))}
          </Row>  
         
        </Col>
      </Row>
  </Container>
  </section>
  );
  }

  
}
 
