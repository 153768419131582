// NotFound.jsx
import React, { useEffect, useState,useRef } from "react";
import { Link } from 'react-router-dom';
import { ShimmerDiv,ShimmerContentBlock, ShimmerTitle,ShimmerButton, ShimmerCategoryItems } from "shimmer-effects-react";
const NotFound = () => {
  
 
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Oops! Page Not Found</h2>
      <p>The page you are looking for doesn’t exist.</p>
      <Link to="/">Go Back Home</Link>
    </div>
  );
};

export default NotFound;
